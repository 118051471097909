import React from 'react';
import { Avatar } from "@nextui-org/react";

const AvatarComponent = ({alt, height, width, radius, size, name, color, src, data}) => {


    return (
        <Avatar
            alt={alt}
            height={height}
            radius={radius}
            size={size}
            name={name}
            color={color}
            width={width}
            className={data}
            src={src} />
    )
}

export default AvatarComponent;