import React from 'react'

const Beaker = () => {
    return (
        <div>
            <svg width="91" height="100" viewBox="0 0 91 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M90.4688 71.4377H76.1875V66.6565H80.9375V61.9065H9.53125V66.6565H14.2969V71.4377H0V100H90.4688V71.4377ZM19.0312 66.6565H71.4063V71.4377H19.0312V66.6565ZM85.7188 95.2502H4.75V76.1877H85.7188V95.2502Z" fill="#ffffff" />
                <path d="M85.7189 4.75H80.9376V61.9062H85.7189V4.75Z" fill="#ffffff" />
                <path d="M76.1875 80.9376H52.375V85.7189H76.1875V80.9376Z" fill="#ffffff" />
                <path d="M57.125 33.3439H52.375V38.0939H57.125V33.3439Z" fill="#ffffff" />
                <path d="M57.125 23.8125H52.375V28.5625H57.125V23.8125Z" fill="#ffffff" />
                <path d="M52.375 38.0935H38.0938V42.8435H52.375V38.0935Z" fill="#ffffff" />
                <path d="M38.0937 33.3439H33.3124V38.0939H38.0937V33.3439Z" fill="#ffffff" />
                <path d="M38.0937 23.8125H33.3124V28.5625H38.0937V23.8125Z" fill="#ffffff" />
                <path d="M14.2969 57.1564H76.2032V9.53137H14.2969V57.1564ZM19.0313 14.2814H71.4063V52.3751H19.0313V14.2814Z" fill="#ffffff" />
                <path d="M23.8125 80.9376H14.2812V90.4689H23.8125V80.9376Z" fill="#ffffff" />
                <path d="M80.9374 0H9.53119V4.75H80.9374V0Z" fill="#ffffff" />
                <path d="M9.53131 4.75H4.75006V61.9062H9.53131V4.75Z" fill="#ffffff" />
            </svg>



        </div>
    )
}

export default Beaker