import React from 'react';
import { Card, CardBody, Button } from "@nextui-org/react";

const Dangerzone = ({ onDeleteOpen, collection }) => {
    return (
        <div className='pt-4'>
            <Card className="shadow-none border border-danger rounded-2xl p-4">
                <CardBody>
                    <p className='text-lg font-normal'>Danger zone</p>
                    <p className='text-lg font-normal pt-8  '>Delete your {collection}</p>
                    <p className='text-sm pt-4'>This will permanently remove your {collection} you will lose access to all your data inside your {collection}.</p>
                    <div className='pt-4 flex flex-row justify-end'>
                        <Button className='text-base' onClick={onDeleteOpen} color='danger'>Delete {collection}</Button>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default Dangerzone