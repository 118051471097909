import React from 'react'

const Atom = () => {
  return (
    <div>
    <svg width="81" height="100" viewBox="0 0 81 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M80.9688 57.125H76.1875V80.9375H80.9688V57.125Z" fill="#000001"/>
<path d="M76.1874 80.9375H71.4374V90.4688H76.1874V80.9375Z" fill="#000001"/>
<path d="M76.1874 47.5938H71.4374V57.125H76.1874V47.5938Z" fill="#000001"/>
<path d="M71.4376 90.4688H66.6876V95.2187H71.4376V90.4688Z" fill="#000001"/>
<path d="M71.4376 42.8437H66.6876V47.5937H71.4376V42.8437Z" fill="#000001"/>
<path d="M66.6875 57.125H61.9062V61.9062H66.6875V57.125Z" fill="#000001"/>
<path d="M66.6875 38.0937H61.9062V42.8437H66.6875V38.0937Z" fill="#000001"/>
<path d="M66.6875 95.2188H14.2812V100H66.6875V95.2188Z" fill="#000001"/>
<path d="M61.9062 33.3125H52.375V38.0937H61.9062V33.3125Z" fill="#000001"/>
<path d="M52.375 47.5938H47.625V52.375H52.375V47.5938Z" fill="#000001"/>
<path d="M42.875 61.9062H38.0938V66.6563H42.875V61.9062Z" fill="#000001"/>
<path d="M33.3438 47.5938H28.5938V52.375H33.3438V47.5938Z" fill="#000001"/>
<path d="M33.3438 4.75H47.625V33.3125H52.375V4.75H57.1562V0H23.8125V4.75H28.5937V33.3125H33.3438V4.75Z" fill="#000001"/>
<path d="M28.5937 33.3125H19.0624V38.0937H28.5937V33.3125Z" fill="#000001"/>
<path d="M23.8124 57.125H19.0624V61.9062H23.8124V57.125Z" fill="#000001"/>
<path d="M14.2812 85.6875H19.0624V90.4688H61.9062V85.6875H66.6874V80.9375H71.4374V71.4063H66.6874V66.6563H57.1562V71.4063H52.3749V76.1875H38.0937V71.4063H28.5937V66.6563H14.2812V71.4063H9.53119V80.9375H14.2812V85.6875Z" fill="#000001"/>
<path d="M19.0625 38.0937H14.2812V42.8437H19.0625V38.0937Z" fill="#000001"/>
<path d="M14.2812 90.4688H9.53119V95.2187H14.2812V90.4688Z" fill="#000001"/>
<path d="M14.2812 42.8437H9.53119V47.5937H14.2812V42.8437Z" fill="#000001"/>
<path d="M9.53137 80.9375H4.78137V90.4688H9.53137V80.9375Z" fill="#000001"/>
<path d="M9.53137 47.5938H4.78137V57.125H9.53137V47.5938Z" fill="#000001"/>
<path d="M4.78125 57.125H0V80.9375H4.78125V57.125Z" fill="#000001"/>
</svg>


    </div>
  )
}

export default Atom