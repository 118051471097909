import React, { useRef, useEffect, useState } from 'react';
import { Chip } from "@nextui-org/react";
import { getAllData } from "../controllers/strapiController";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { colorPicker } from '../controllers/basicControllers';
import { toast } from "react-hot-toast";

const Tags = ({ darkMode, tagsData, setTagsData, tagInput, setTagInput, tags, validatetags, setTags, setValidatetags, setFormdata, formData, background }) => {
    // STATE INITIATION
    const ref = useRef();
    const TextRef = useRef();
    const [selectedTag, setselectedTag] = useState(null);
    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
    const userID = userDetails?.user?.id;

    // ACTIVE SCROLL DYNAMICALLY
    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: "smooth" });
    }, [tagInput])

    // TAGS API CALL
    useEffect(() => {
        getAllData("tags")
            .then((data) => { setTagsData(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    // TAGS FILTER DROPDOWN
    const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userID)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
    // FILTER FOR INPUT TEXT RELATED TO TAGS
    const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

    // FINAL DISPLAY TAGS
    const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []
    const FilterInput = FilteredTags && FilteredTags.some((tag) => tag.toLowerCase() === tagInput.toLowerCase())

    const handleTagRemove = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
        setFormdata(() => ({ ...formData, Tags: tags.filter(tag => tag !== tagToRemove) }))
    };

    // TAGS INPUT ONCHANGE HANDLER
    const handleChange = (e) => {
        const userInput = e.target.value;
        const regex = /^[a-zA-Z_-]*$/;
        if (regex.test(userInput)) {
            setTagInput(userInput)
        }
    }

    // ENTER TO CREATE TAG
    const Text = tagInput.trim().toLowerCase();
    const handleTagInputKeyPress = (e) => {
        if (tagInput.trim() !== '') {
            if (!tags.includes(Text)) {
                setTags((previous) => [...previous, Text]);
                setValidatetags("");
                setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
                setTagInput('');
            } else {
                setValidatetags("Entered tag already exists.");
            }
        }
    };

    // ONCLICK ON OPTION TO CREATE TAG
    const HandleOnClickInput = (tag) => {
        setselectedTag(tag);
        if (!tags.includes(tag)) {
            setTags((previous) => [...previous, tag]);
            setValidatetags(null);
            setTagInput('');
            setFormdata((previous) => ({ ...previous, Tags: [...tags, tag] }));
        } else {
            setValidatetags("Entered tag already exists.");
        }
    };

    // "ENTER" KEY TO CREATE TAG
    const handleKeyPress = (e) => {
        if (tagInput.trim() !== '' && e.key === "Enter") {
            if (!tags.includes(Text)) {
                setTags((previous) => [...previous, Text]);
                setValidatetags("")
                setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
                setTagInput('');
            } else {
                setValidatetags("Entered tag already exists.")
            }
        }
    }

    // TAGS CHIP UI RENDERING
    const renderTags = () => {
        return tags && tags.map((tag, index) => (
            <Chip
                key={index}
                className=""
                size='sm'
                color={colorPicker(6)}
                variant="flat"
                onClose={() => handleTagRemove(tag)}>
                {tag}
            </Chip>
        ));
    };
    // RETURN UI
    return (
        <div className="relative pt-4">
            <lable className="text-sm">Tags</lable>
            <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl  flex flex-col border-divider p-2`}>
                <input
                    className={`${background} border-0 rounded text-sm`}
                    key={"outside"}
                    ref={TextRef}
                    style={{ outline: 'none' }}
                    type="text"
                    variant="bordered"
                    labelPlacement={"outside"}
                    placeholder="Press enter to add multiple tags"
                    value={tagInput && tagInput.trim()}
                    onChange={handleChange}
                    onKeyPress={(event) => { if (event.key === 'Enter') { handleKeyPress(event) } }}
                />
                <div className="pt-4 flex flex-wrap gap-2">
                    {renderTags()}
                </div>
                <p className="pt-2 text-xs text-red-400">{tags && (tags.includes(Text) || tags.includes(selectedTag)) ? validatetags : ""}</p>
            </div>
            {tagInput !== '' && tagInput !== ' ' ?
                <div ref={ref} className='transition ease-in-out delay-200 z-50 w-full pt-2 ' >
                    <div className={`rounded-xl border w-full border-divider divide-y divide-divider overflow-y-auto  ${darkMode.value === true ? `${background}` : "bg-white"}`} style={{ maxHeight: "325px" }} >
                        {FilteredTags && FilteredTags.length > 0 ?
                            <>
                                {FilteredTags && FilteredTags.map((data,index) => {
                                    const TagsCheck = tags && tags.some((tag) => tag === data);
                                    return <div key={index} onClick={() => { HandleOnClickInput(data) }} className="p-2 text-sm flex flex-row gap-2 items-center justify-between">
                                        <div className="flex flex-row gap-2 items-center">
                                            <lord-icon
                                                src="https://cdn.lordicon.com/prjooket.json"
                                                colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                trigger="hover"
                                                style={{ width: "16px", height: "16px" }}>
                                            </lord-icon>
                                            {data} </div>
                                        {TagsCheck ? <div>
                                            <FontAwesomeIcon icon={faCheck} />
                                        </div> : ""}
                                    </div>
                                })}
                                {FilterInput === false ?
                                    <p className="p-2 text-sm" onClick={handleTagInputKeyPress}>+ Add <b>"{tagInput}"</b> to tag list. </p> : ""}
                            </> :
                            <p className="p-2 text-sm" onClick={handleTagInputKeyPress}>+ Add <b>"{tagInput}"</b> to tag list. </p>
                        }
                    </div>
                </div> : ""}
        </div>
    )
}

export default Tags;