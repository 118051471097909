import CryptoJS from "crypto-js";

// DOCUMENTS FILTER FUNCTION TO REMOVE DUPLICATE DOCUMENTS 
export const filterUniqueEntries = (array) => {
  const uniqueEntries = [];
  const uniqueIds = new Set();

  array?.forEach(item => {
    if (!uniqueIds.has(item && item.payload.file_document_id)) {
      uniqueIds.add(item && item.payload.file_document_id);
      uniqueEntries.push(item);
    }
  });
  return uniqueEntries;
}

export const iconRender = (darkMode, url, id) => {

  return <lord-icon
      src={url}
      trigger="hover"
      target={`#${id}`}
      state="morph-home-2"
      colors={`primary:${id==="Copy"?"#ffffff":darkMode.value === true ? "#ffffff" : "#000000"}`}
      style={{ width: "16px", height: "16px" }}>
  </lord-icon>
}

// STRING FOR NAME CONCATINATION
export const stringCreater = (firstName, email) => {
  if (firstName) {
    return firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
  } else {
    return email?.split('@')[0]
  }
}

// FUNCTION TO DISPLAY NAME
export const displayName = (firstName, lastName, email) => {
  if (firstName || lastName) {
    const lastDisplayName = lastName ? lastName : ''
    return firstName?.charAt(0).toUpperCase() + firstName?.slice(1) + " " + lastDisplayName
  }

  if (!firstName && !lastName) {
    return email?.split('@')[0]
  }
}

// TITLE CREATE
export const titleCreater = (title) => {
  return title?.charAt(0).toUpperCase() + title?.slice(1);
}

// AVATAR WORD CUT NAME CREATER
export const avatarName = (name) => {
  const slitedData = name && name.split(" ");
  const dataCount = slitedData && slitedData.length > 1 ? slitedData && (slitedData[0].slice(0, 1) + slitedData[1].slice(0, 1)) : slitedData?.[0]?.charAt(0).toUpperCase()
  const Capitalize = dataCount && dataCount.toUpperCase();
  return Capitalize;
}

// COLOR RENDERING hANDLER
export const colorPicker = (id) => {
  const Colors = ['secondary', 'success', 'warning', 'danger', 'primary']
  const numColors = Colors.length;
  const colorIndex = id % numColors;
  return Colors[colorIndex];
}

// STRING LIMIT FUNCTION
export const StringLimit = (string, maxLength) => {
  if (string?.length > maxLength) {
    return string.substring(0, maxLength - 3) + "..."
  } else {
    return string
  }

}

export const dateFormat = (date) => {
  const newDate=new Date(date);
  const options={day:"2-digit", month:"short", year:"numeric"}
  const format=newDate.toLocaleDateString("en-US",options)
  return format
}


// NAME CREATER FOR ALL THE TITLES COMING INSIDE AVATAR
export const NameCreater = (firstName, email) => {
  if (firstName !== null) {
    return firstName && firstName?.charAt(0).toUpperCase();
  } else {
    return email && email?.charAt(0).toUpperCase()
  }
}

// DECRIPTED DATA
export const decriptedText = (text) => {

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100) + 1;
  };

  generateRandomNumber();

  const secretKey = '';
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };


  const handleDecrypt = () => {
    try {
      // const url = window.location.href;
      const id = text
      const hash = id;
      const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`; // Replace with your actual secret key
      const base64Decoded = atob(hash)
      const decrypted = CryptoJS.AES.decrypt(base64Decoded, secretKey).toString(CryptoJS.enc.Utf8);
      const decriptText = decrypted.toString();
      return decriptText
    } catch {
      return null
    }
  };

  const data = handleDecrypt();
  return data
}

// Function to convert all object keys to lowercase
export const convertKeysToLowercase = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
  }, {});
}


// TIME CALCULATIONS USING TIMESTAMP TIME
export const getTimeDifference = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp * 1000);
  const diffInSeconds = Math.floor((now - date) / 1000);

  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else if (weeks > 0) {
    return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
  } else if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
};

// TIME CALCULATER USING INDIAN TIME 
export const timeDifference = (previous) => {
  const current = new Date();
  const previousDate = new Date(previous);

  const elapsed = current - previousDate;

  const seconds = Math.floor(elapsed / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days + " days ago";
  } else if (hours > 0) {
    return hours + " hours ago";
  } else if (minutes > 0) {
    return minutes + " mins ago";
  } else {
    return "just now";
  }
}

// Encrypted the data
export const encryptedText = (text) => {
  const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
  const inputString = text;
  const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
  const AESHash = encrypted.toString();
  const url = btoa(AESHash);
  return url;
};

export const decryptedText = (encryptedText) => {
  const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
  const inputString = atob(encryptedText).toString();

  const text = CryptoJS.AES.decrypt(inputString, secretKey).toString(
    CryptoJS.enc.Utf8
  );

  return text;
};


export const generateOtp = () => {
  const otp = Math.floor(100000 + Math.random() * 900000);

  return otp.toString();
};