import React, { useState } from 'react';
import { Progress } from "@nextui-org/react";
import SelectdataProduct from './SelectdataProduct';
import CreateProduct from './CreateProduct';
import DBSelector from './Database/DB_Selector';
import Context from './Database/Context';
import CollectionDB from './Database/CollectionDB';

const CreateDatabase = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [step, setStep] = useState(1);
    const [selectProduct, setSelectProduct] = useState(null);
    const [formData, setFormData] = useState({ type: '', payload: '', Tags: '',dataProduct:"", inputFields:'' });

    console.log("formData",formData)
    // WIZARD NEXT FUNCTION
    const handleNext = () => {
        setStep(prevStep => prevStep + 1);
    };

    // WIZARD PREVIOUS FUNCTION
    const handlePrevious = () => {
        setStep(prevStep => prevStep - 1);
    };

    // SWITCH PAGES
    const renderPage = () => {
        switch (step) {
            case 1:
                return <><div className='pb-8'>
                    <h1 className='text-3xl font-medium pb-4'>Add a new data product</h1>
                    <p>Add a new data product to your datamesh and bring all your enterprise data products inside your space. Your data can be unstructured documents, structured data from databases, or from an exteral source </p>
                </div>
                    <SelectdataProduct darkMode={darkMode} onNext={handleNext} setFormData={setFormData} formData={formData} setSelectProduct={setSelectProduct} multiSelect={false} />
                </>
            case 2:
                return <>
                    <CreateProduct darkMode={darkMode} onNext={handleNext} onPrevious={handlePrevious} setFormData={setFormData} formData={formData} selectProduct={selectProduct} />
                </>
            case 3:
                return <>
                        <DBSelector onNext={handleNext} darkMode={darkMode} onPrevious={handlePrevious} formData={formData} setFormData={setFormData} selectProduct={selectProduct} />
                </>
            case 4:
                return <><div className='pb-8'>
                    <h1 className='text-3xl font-medium pb-4'>Add additional context</h1>
                    <p>Enhance querying in your data mesh by adding context like metadata, schema relationships, and domain-specific labels.</p>
                </div>
                    <Context onNext={handleNext} darkMode={darkMode} onPrevious={handlePrevious} formData={formData} setFormData={setFormData} selectProduct={selectProduct} />
                </>
            default:
                return null;
        }
    }

    return (
        <div>
            <div className='flex flex-row justify-center pt-8 pb-8'>
                <Progress
                    size="sm"
                    radius="sm"
                    classNames={{ base: "max-w-lg" }}
                    color='secondary'
                    value={formData.type === 'tools' ? step === 1 ? 0 : 50 * (step - 1) : step === 1 ? 0 : 33.33 * (step - 1)}
                />
            </div>
            <div className='pt-8'>
                {renderPage()}
            </div>
        </div>
    )
}

export default CreateDatabase;