import React from 'react'
import { helix } from 'ldrs'

const Loading = ({ darkMode }) => {

  helix.register()

  return (
    <div className={`flex items-center justify-center h-full backdrop-opacity-10 backdrop-invert ${darkMode.value === true ? "bg-black" : "bg-white"} `}>
      <l-helix
        size="45"
        speed="2.5"
        color={darkMode.value === true ? "white" : "black"}
      ></l-helix>
    </div>
  )
}

export default Loading