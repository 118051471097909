import React, { useEffect, useState, useRef } from 'react';
import { Button, Chip, Card, CardBody, useDisclosure } from '@nextui-org/react';
import { UpdateData, getAllData, DeleteSingleAttribute } from '../../../controllers/strapiController';
import DeleteModal from "../../../components/DeleteModal";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Updatesettings from './Update_settings';
import { colorPicker } from '../../../controllers/basicControllers';


const DomainSettings = ({ SelectDomain, setLoader, setReload, darkMode }) => {

  const [tagInput, setTagInput] = useState('');
  const [validationCondition, setValidationCondition] = useState(false);
  const [Validatetags, setValidatetags] = useState('');
  const [InitialData, setInitialData] = useState(null);
  const [tagsData, setTagsData] = useState();
  const [selectedTag, setselectedTag] = useState(null);
  const CollectionName = "domains";
  const ref = useRef();
  const TextRef = useRef();
  const navigate = useNavigate();
  const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
  const UserID = UserDetails && UserDetails.user && UserDetails.user.id;
  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(null);
  const [formData, setFormdata] = useState({ Name: '', Description: '', Tags: [], isPublic: false });
  const [formError, setFormError] = useState({ NameError: '', DescriptionError: '' });
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();

  // TAGS TO CHIP RENDERING
  const renderTags = () => {
    return tags && tags.map((tag, index) => (
      <Chip
        key={index}
        className="mr-2 mb-2"
        size='md'
        color={colorPicker(index)}
        variant="flat"
        onClose={() => handleTagRemove(tag)}>
        {tag}
      </Chip>
    ));
  };

  // TAGS INPUT ONCHANGE HANDLER
  const handleChange = (e) => {
    const userInput = e.target.value;

    const regex = /^[a-zA-Z_ -]*$/;
    if (regex.test(userInput)) {
      setTagInput(userInput);
    }
  }

  // FUNCTION TO HANDLE SCROLLBAR
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [tagInput])

  // GETTING TAGS LIST ACCORDING TO USER
  useEffect(() => {
    getAllData("tags")
      .then((data) => {
        setTagsData(data.data)
      })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [])

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
    setFormdata(() => ({ ...formData, Tags: tags.filter(tag => tag !== tagToRemove) }))
  };

  // VISIBALITY OF TAGS WHEN INPUT FIELD IS NOT NULL
  const Text = tagInput.trim().toLowerCase();
  const handleTagInputKeyPress = (e) => {
    if (tagInput.trim() !== '') {
      if (!tags.includes(Text)) {
        setTags((previous) => [...previous, Text]);
        setValidatetags("")
        setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
        setTagInput('');
      } else {
        setValidatetags("Entered tag already exists.")
      }
    }
  };

  // ONCLICK TO SELECT TAGS
  const HandleOnClickInput = (tag) => {
    setselectedTag(tag)
    if (!tags.includes(tag)) {
      setTags((previous) => [...previous, tag]);
      setValidatetags(null)
      setTagInput('');
      setFormdata((previous) => ({ ...previous, Tags: [...tags, tag] }));
    } else {
      setValidatetags("Entered tag already exists.")
    }
  };

  // HANDLE ENTER TO FILL TAGS
  const handleKeyPress = (e) => {
    if (tagInput.trim() !== '' && e.key === "Enter") {
      if (!tags.includes(Text)) {
        setTags((previous) => [...previous, Text]);
        setValidatetags("")
        setFormdata((previous) => ({ ...previous, Tags: [...tags, Text] }));
        setTagInput('');
      } else {
        setValidatetags("Entered tag already exists.")
      }
    }
  }

  //INITIALIZING THE VALUES
  useEffect(() => {
    if (SelectDomain) {
      const TagsData = SelectDomain.attributes.Tags && SelectDomain.attributes.Tags.tags;
      setInitialData(TagsData);
      TagsData && setTags(SelectDomain.attributes.Tags.tags)
      setFormdata({
        Name: SelectDomain.attributes.Name,
        Description: SelectDomain.attributes.Description,
        Tags: TagsData,
        isPublic: SelectDomain.attributes.Public
      })
    }
  }, [SelectDomain, reloadData])


  // VALIDATION FOR SUBMIT HANDLER
  const Validation = () => {
    let IsValid = true;

    if (!formData.Name) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
    } else {
      setFormError((previous) => ({ ...previous, NameError: null }))
    }

    if (!formData.Description) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
    } else {
      setFormError((previous) => ({ ...previous, DescriptionError: null }))
    }
    return IsValid;
  }


  // Check if the arrays are of the same length
  const lengthMatch = formData.Tags && InitialData && InitialData.length === formData.Tags.length;

  // Check if each element matches
  const elementsMatch = InitialData && InitialData.length > 0 && formData.Tags && formData.Tags.length > 0 ? InitialData.every((element, index) => element === formData.Tags[index]) : null;

  const TagsCompare = lengthMatch && elementsMatch;

  const Disabled = SelectDomain && SelectDomain.attributes &&
    formData.Name === SelectDomain.attributes.Name && formData.Description === SelectDomain.attributes.Description && (TagsCompare === true || TagsCompare === null) && formData.isPublic === SelectDomain.attributes.Public ? true : false;

  // TAGS FILTER DROPDOWN
  const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === UserID)
  // TAGS PRESENTS
  const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
  // FILTER FOR INPUT TEXT RELATED TO TAGS
  const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

  // FINAL DISPLAY TAGS
  const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []
  const FilterInput = FilteredTags && FilteredTags.some((tag) => tag.toLowerCase() === tagInput.toLowerCase());

  const TagsRender = formData.Tags && formData.Tags.filter((data) => TagsLength && !TagsLength.includes(data));
  const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
  const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

  // UPDATE COLLECTION
  const SubmitHandler = async (onClose) => {
    const payload = {
      Name: formData.Name,
      Description: formData.Description,
      Tags: { tags: formData.Tags },
      Public: formData.isPublic
    }

    const TagsPayload = {
      Tags: { tags: NewRenderation }
    }

    const Validate = Validation();
    if (Validate) {
      setValidationCondition(false);
      const response = await UpdateData(CollectionName, SelectDomain.id, payload)
      if (response) {
        const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
        setReload(updateResponse)
        toast.success("Your collection has been updated.");
        setTagInput('')
      }
    } else {
      setLoader(false);
      setValidationCondition(true);
    }
  }

  // DELETE HANDLER TO DELETE COLLECTION
  const deleteHandler = async () => {
    const response = SelectDomain && await DeleteSingleAttribute(CollectionName, SelectDomain.id)
    if (response) {
      navigate(-1)
    }
  }
  return (
    <div className=''>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          style: {
            boxShadow: "none",
            border: "1px solid #bdb5b5"
          },
          success: { duration: 3000 }
        }} />
      <h1 className="pt-8 pb-8 text-xl font-medium">Settings</h1>
      <div className='flex flex-col gap-8'>
        <Updatesettings
          darkMode={darkMode}
          formData={formData}
          setFormdata={setFormdata}
          validationCondition={validationCondition}
          formError={formError}
          TextRef={TextRef}
          tagInput={tagInput}
          handleChange={handleChange}
          renderTags={renderTags}
          tags={tags}
          ref={ref}
          FilteredTags={FilteredTags}
          FilterInput={FilterInput}
          handleTagInputKeyPress={handleTagInputKeyPress}
          HandleOnClickInput={HandleOnClickInput}
          handleKeyPress={handleKeyPress}
          selectedTag={selectedTag}
          Validatetags={Validatetags} />
        <div className='flex justify-end gap-4'>
          <Button color="default" variant="flat" onClick={() => setReloadData(!reloadData)}>Reset</Button>
          <Button isDisabled={Disabled} color="secondary" onClick={() => { SubmitHandler() }}>Update </Button>
        </div>
        <div className='pt-4'>
          <Card className="shadow-none border border-danger rounded-2xl p-4">
            <CardBody>
              <p className='text-lg font-normal'>Danger zone</p>
              <p className='text-lg font-normal pt-8'>Delete Domain</p>
              <p className='text-sm pt-4'>This will permanently remove your collection you will lose access to all your data inside your collection.</p>
              <div className='pt-4 flex flex-row justify-end'>
                <Button onClick={onDeleteOpen} color='danger'>Delete Domain</Button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={""}
        setDeleteData={""}
        Collectionname={SelectDomain && SelectDomain.attributes.Name}
        setLoader={setLoader}
        DeleteHandler={deleteHandler}
      />
    </div>
  )
}

export default DomainSettings