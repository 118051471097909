// src/actions.js
export const SET_DATA = 'SET_DATA';
export const SET_SPACE='SET_SPACE';
export const SET_DOCUMENTS='SET_DOCUMENTS';
export const SET_NAVIGATE='SET_NAVIGATE';

export const setData = (data) => ({
    type: SET_DATA,
    payload: data,
});

export const spaceInfo=(data)=>({
    type: SET_SPACE,
    payload: data,
})

export const documents=(data)=>({
    type: SET_DOCUMENTS,
    payload: data,
})

export const reDirect=(data)=>({
    type: SET_NAVIGATE,
    payload: data,
})