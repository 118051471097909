import React from 'react';
import { Image, Button } from '@nextui-org/react';
import hayalogo from "../Images/haya-logo.png";
import { toast } from "react-hot-toast";
import { InviteUser } from "../controllers/strapiController";
import { decriptedText } from '../controllers/basicControllers';
import NotFound from "../components/NotFound";

const LinkExpired = ({ darkMode, email }) => {
    // DECRIPTION FROM URL
    const emaildata = decriptedText(email);

    // HANDLER TO SEND URL TO USER
    const SubmitHandler = async () => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const newTimestamp = currentTimestamp + 5 * 60;
        const url = `${process.env.REACT_APP_IP_ADDRESS}/auth/reset-password?email=${email}&limit=${newTimestamp}`

        const payload = {
            "to": emaildata,
            "subject": "Invitation from Haya Knowledge.",
            "html": `<html><body><a href="${url}">${url}</a></body></html>`
        };
        toast.success("Password change link sent to your email please ckeck it.");
        await InviteUser(payload);
    }

    // NOT FOUND PAGE RENDER
    if (emaildata === null || emaildata === "") {
        return <NotFound />
    }

    // RETURN UI
    return (
        <div className="z-50 bg-background absolute top-0 left-0 right-0 h-screen  flex h-screen items-center justify-center">
            <main className='mx-auto w-full flex flex-col items-center justify-center'>
                <div>
                    <Image width={40} alt="NextUI" src={hayalogo} />
                    <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
                        LINK EXPIRED
                    </h1>
                    <p className='mt-8' >reset request expired. please generate a new reset password link</p>
                    <div className='pt-8 w-full'>
                        <Button onClick={SubmitHandler} className={`w-full gap-1 lt-500 ${darkMode.value === true ? 'text-black hover:text-foreground bg-white hover:bg-transporent hover:text-transporent' : 'text-white bg-black hover:bg-gray-700 hover:text-white'}`}>
                            Send reset password link
                            <lord-icon
                                src="https://cdn.lordicon.com/vduvxizq.json"
                                trigger="hover"
                                target="#CreateAccount"
                                colors={`primary:${darkMode.value === true ? "#000000" : "#ffffff"}`}
                                style={{ width: "18px", height: "20px" }}
                            />
                        </Button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default LinkExpired;