import React from 'react';
import { Input, Button } from "@nextui-org/react";
import SourceDocuments from "../SourceDocuments";
import SearchIcon from '../../../Images/Search';
import { useNavigate } from "react-router-dom";

const Documents = ({ darkMode, UserOptions, color }) => {

  const navigate = useNavigate();
  return (
    <div>
      <div className='pt-4 flex justify-between items-center'>
        <div className='w-1/2 my-4'>
          <Input
            placeholder='Search for documents'
            startContent={<SearchIcon darkMode={darkMode} className={`text-large text- pointer-events-none flex-shrink-0 `} />}
          />
        </div>
        {UserOptions && <Button color={color} onClick={() => navigate(`upload`)}> Upload a document</Button>}
      </div>
      <SourceDocuments darkMode={darkMode} />
    </div>
  )
}

export default Documents;