import React, { useEffect, useState } from 'react';
import { Button, Card, CardHeader, CardBody, Image, Input, CardFooter, Link } from "@nextui-org/react";
import SearchIcon from '../../../Images/Search';
import { useParams, useNavigate } from "react-router-dom";
import { CommonCollectionFilterApi, UpdateData } from "../../../controllers/strapiController";
import { StringLimit } from "../../../controllers/basicControllers";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import pluralize from "pluralize";

const DBList = ({ darkMode }) => {

    const [dataProducts, setDataproducts] = useState([]);
    const [defaultProduct, setDefaultProduct] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [formData, setFormData] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const data = useSelector(state => state);
    const indexData = data && JSON.stringify(data, null, 2);
    const convertedData = JSON.parse(indexData);
    const spaceId = convertedData?.space?.[0]?.id

    const handleSearch = (e) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredProducts = dataProducts?.filter((data) =>
        data.attributes.Name.toLowerCase().includes(searchQuery)
    );

    const type=params?._type&&pluralize.plural(params?._type)
    useEffect(() => {
        const url=params?._type?`filters[Type][$eq]=${type}&filters[spaces][Name][$eq]=${params?._spaceid}&[populate][spaces][on]`
        :params?._spaceid!=="haya"?
        `filters[spaces][Name][$eq]=${params?._spaceid}&[populate][spaces][on]`
        :''
        CommonCollectionFilterApi("data-products", "")
            .then((data) => setDataproducts(data.data))
            .catch((error) => console.error("Oops!, something went wrong, please try after some time."));

        CommonCollectionFilterApi("data-products", url)
            .then((data) => setDefaultProduct(data.data))
            .catch((error) => console.error("Oops!, something went wrong, please try after some time."));
    }, []);

    useEffect(() => {
        if (defaultProduct) {
            defaultProduct?.map(data => (
                setFormData((prev) => ([...prev, data.id]))
            ))
        }
    }, [defaultProduct])



    const handleChange = async (data) => {
        if (formData?.includes(data.id)) {
            setFormData((prev) => (prev?.filter(id => id !== data.id)));
            const payload = {
                data_products: {
                    disconnect: [{ id: data.id, position: { end: true } }],
                    connect: []
                }
            }

            const response = await UpdateData("spaces", spaceId, payload);
            if (response) {
                console.log("response", response)
            }
        } else {
            setFormData((prev) => ([...prev, data.id]));
            const payload = {
                data_products: {
                    disconnect: [],
                    connect: [{ id: data.id, position: { end: true } }]
                }
            }

            const response = await UpdateData("spaces", spaceId, payload);
            if (response) {
                console.log("response", response)
            }
        }
    }


    return (
        <div>
            <div className='flex items-center gap-4 font-semibold'>
                <Button
                    onClick={() => navigate(-1)}
                    isIconOnly
                    color="secondary"
                    radius='full'
                    aria-label="Like"
                    startContent={<FontAwesomeIcon icon={faArrowLeft} />}>
                </Button>
                Back
            </div>
            <div className='py-8'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta neque quibusdam, ducimus sequi optio quo nobis repudiandae aliquam nostrum doloribus ea modi animi non distinctio suscipit iusto commodi veritatis rem!
            </div>
            <Input
                placeholder='Search for a data product'
                className='pb-8'
                startContent={<SearchIcon darkMode={darkMode} className={`text-large text- pointer-events-none flex-shrink-0 `} />}
                value={searchQuery}
                onChange={handleSearch}
            />
            <div className='max-w-[1200px] mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8'>
                {filteredProducts?.length > 0 ? filteredProducts?.map((data) => (
                    <div key={data.id} onClick={() => handleChange(data)}>
                        <Card shadow='none' className={`border border-divider px-4 py-2 w-full h-[200px] cursor-pointer ${formData?.includes(data.id) ? "border-secondary" : "border-divider"}`}>
                            <CardHeader className='text-lg gap-2 flex items-center'>
                                <Image src={data.attributes.Image} width={20} />
                                <span>{data.attributes.Name}</span>
                            </CardHeader>
                            <CardBody>
                                <div className='text-default-500'>
                                    {StringLimit(data.attributes.Description, 70)}
                                </div>
                            </CardBody>
                            <CardFooter>
                                <div className='flex flex-row justify-end w-full'>
                                    <Button size="sm" color='default' variant='light'>
                                        <Link
                                            onClick={() => { navigate(`/${params?._spaceid}/data-products/${data?.id}`) }}
                                            isExternal
                                            showAnchorIcon
                                            color="foreground"
                                            size="sm"
                                        >
                                            Learn More
                                        </Link>
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                )) : (
                    <div className="text-center w-full col-span-3">No products found</div>
                )}
            </div>

        </div>
    )
}

export default DBList