import React from 'react'

const ExtensionsTeams = () => {
  return (
    <div>
      <div className=''>

      </div>
    </div>
  )
}

export default ExtensionsTeams