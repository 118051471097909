import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { UpdateData, uploadFile, CommonCollectionFilterApi, DeleteSingleAttribute, userLoginDetails } from "../../controllers/strapiController";
import { NameCreater, titleCreater } from "../../controllers/basicControllers";
import { Image, Avatar, Button, useDisclosure, Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import TeamsCollection from './TeamsCollection';
import TeamMembers from './TeamMembers';
import EditTeams from "./EditTeams";
import { useSelector } from "react-redux";
import DeleteModal from "../../components/DeleteModal";
import NotFound from "../../components/NotFound";
import ServerIssue from "../../components/ServerIssue";
import { teamSkeleton } from '../../components/Skeleton';
import { colorPicker } from '../../controllers/basicControllers';
import { toast } from "react-hot-toast";

const SingleTeam = ({ darkMode }) => {
    const [teamsNewData, setTeamsData] = useState([]);
    const [textValue, setTextValue] = useState('');
    const params = useParams();
    const [loader, setLoader] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [Forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [connectionRefused, setConnectonRefused] = useState(false);
    const [reload, setreload] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [Collections, setCollections] = useState([]);
    const [spaceData, setSpaceData] = useState();
    const [selectedTeam, setSelectedTeam] = useState();
    const [deleteData, setDeleteData] = useState();
    const fileInputRef = useRef();
    const UserDetails = userLoginDetails();
    const navigate = useNavigate();
    const userId = UserDetails?.userId;
    const { isOpen: isPeopleOpen, onOpen: onPeopleOpen, onOpenChange: onPeopleOpenChange } = useDisclosure();
    const { isOpen: isEditOpen, onOpen: onEditOpen, onOpenChange: onEditOpenChange } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
    const reduxData = useSelector(state => state);
    const indexData = reduxData && JSON.stringify(reduxData, null, 2);
    const convertedData = indexData && JSON.parse(indexData);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
        const files = event.target.files;
        if (files && files[0]) {
            const file = files[0];
            let formData = new FormData();
            formData.append("files", file)
            const response = await uploadFile(formData);
            if (response) {
                const payload = {
                    Cover: response[0]
                }
                const response2 = await UpdateData("teams", params._id, payload);
                if (response2) {
                    setreload(response)
                }
            }
        }
    }

    useEffect(() => {
        const url3 = `filters[$or][0][admin][id][$eq]=${userId}&filters[$or][1][members][id][$eq]=${userId}&filters[Name][$in][1]=${params._spaceid}&[populate][admin][on]`
        CommonCollectionFilterApi("spaces", url3)
            .then((data) => {
                if (data.error || data.data === null) {
                    setTimeout(() => { setNotFound(true) }, 300)
                } else {
                    setSpaceData(data.data);
                    setNotFound(false);
                }
            })

        var url = `filters[id][$eq]=${params._id}&filters[$or][0][author][id][$eq]=${userId}&filters[$or][1][members][id][$eq]=${userId}&[populate][Logo][on]&[populate][Cover][on]&[populate][collections][on]&[populate][collections]populate[collections][populate][inviteds][populate][Picture][fields][0]=name&populate[collections][populate][inviteds][populate][Picture][populate][fields][3]=url&[populate][collections]populate[collections][populate][viewed][populate][Picture][fields][0]=name&populate[collections][populate][viewed][populate][Picture][populate][fields][3]=url&[populate][collections]populate[collections][populate][requests][populate][Picture][fields][0]=name&populate[collections][populate][requests][populate][Picture][populate][fields][3]=url&[populate][members]populate[members][populate][Picture][fields][0]=name&populate[members][populate][Picture][populate][fields][3]=url`
        CommonCollectionFilterApi("teams", url)
            .then((data) => {
                setTimeout(() => { setLoader(false); }, 200)
                if (data.error) {
                    if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
                    if (data.error.status === 404) { setNotFound(true) }
                    if (data.error.status === 403) { setForbidden(true) }
                } else if (data.data.length > 0) {
                    setTeamsData(data.data)
                } else {
                    setNotFound(true)
                }
            })
            .catch((error) => { setConnectonRefused(true) })

        var url2 = `filters[author][id][$eq]=${userId}&filters[Type][$eq]=collections&filters[Public]=false&[populate][author][on]&[populate][teams][on]`;
        CommonCollectionFilterApi("collections", url2)
            .then((data) => { setCollections(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [reload])

    const filterAdmin = spaceData?.[0]?.attributes?.type !== "Enterprise";
    const teamsData = teamsNewData && teamsNewData[0]
    const TeamMembersData = teamsData?.attributes?.members?.data || null;
    const [displayedItems, setDisplayedItems] = useState();

    useEffect(() => {
        if (showAll) {
            handleShowMore();
        } else {
            setDisplayedItems(teamsData?.attributes?.members?.data?.slice(0, 5));
        }
    }, [teamsData]);

    const handleShowMore = () => {
        setDisplayedItems(teamsData?.attributes?.members?.data);
        setShowAll(true);
    };

    const handleShowLess = () => {
        teamsData?.attributes?.members?.data && setDisplayedItems(teamsData.attributes.members.data.slice(0, 5));
        setShowAll(false);
    };

    const DeleteHandler = async (onClose) => {
        setLoader(true)
        const response = await DeleteSingleAttribute("teams", teamsData.id)
        if (response) {
            navigate(`/${params._spaceid}/teams`);
        }
    }

    const name = teamsData?.attributes?.Name;
    const slitedData = name && name.split(" ");
    const dataCount = slitedData && slitedData.length > 1 ? slitedData && (slitedData[0].slice(0, 1) + slitedData[1].slice(0, 1)) : slitedData && slitedData[0]
    const Capitalize = dataCount && dataCount.toUpperCase();

    if (loader) {
        return teamSkeleton();
    }

    if (notFound) {
        return <NotFound />
    }

    if ( !teamsData) {
        return <NotFound />
    }

    if (serverIssue) {
        return <ServerIssue />
    }

    if (connectionRefused) {
        return <ServerIssue />
    }

    if (Forbidden) {
        return <Forbidden />
    }

    if (teamsData && teamsData) {
        return (
            <div className="pb-8">
                <div className="relative">
                    <div className="relative w-full h-40 ">
                        <Image radius="none" className="h-40" width="100%"
                            src={teamsData?.attributes?.Cover?.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${teamsData?.attributes?.Cover?.data?.attributes?.url}` : `${teamsData?.attributes?.Cover?.data?.attributes?.url}`}` : `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/uploads/cover_Images_f6754fb8f2.webp` : '/uploads/cover_Images_f6754fb8f2.webp'}`} />
                    </div>
                    <div className="z-10 absolute -bottom-7 left-24">
                        <Avatar isBordered radius="sm" size="lg" name={Capitalize}
                            src={teamsData?.attributes?.Logo !== null && teamsData?.attributes?.Logo?.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${teamsData?.attributes?.Logo?.data?.attributes?.url}` : `${teamsData?.attributes?.Logo?.data?.attributes?.url}`}` : ""} />
                    </div>
                    <div className="z-10 absolute bottom-0 right-4 cursor-pointer">
                        <FontAwesomeIcon icon={faPenToSquare} onClick={handleButtonClick} />
                        <input ref={fileInputRef} type='file' onChange={(event) => handleFileChange(event)} className='hidden' />
                    </div>
                </div>
                <div className="max-w-6xl mx-auto w-full pt-14">
                    <div className="flex flex-row justify-between pb-4">
                        <div>
                            <p className='text-3xl'>
                                {titleCreater(teamsData?.attributes?.Name)}</p>
                            <div className="flex mt-4 flex-row gap-2 items-center">
                                <p className='text-sm'>Product</p>
                                <div id="teamMembers" className="flex flex-row items-center gap-1 cursor-pointer">
                                    <lord-icon
                                        src="https://cdn.lordicon.com/kthelypq.json"
                                        trigger="hover"
                                        target="#teamMembers"
                                        state="morph-home-2"
                                        colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                        style={{ width: "16px", height: "16px" }}>
                                    </lord-icon>
                                    <p className='text-sm'>{TeamMembersData && TeamMembersData.length}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Tabs
                            aria-label="Options"
                            key='underlined'
                            variant='underlined'
                            className='-ml-4'
                            defaultSelectedKey={convertedData.ActiveTab}>
                            <Tab key="About" title="About">
                                <div className="pb-8 ">
                                    <p className="text-lg font-medium pt-8">About</p>
                                    <p>{teamsData?.attributes?.Description}</p>
                                </div>
                            </Tab>
                            <Tab key="Members" title="Members">
                                <div className="flex flex-row justify-between items-center">
                                    <p className="text-lg font-medium pt-8 pb-8">People</p>
                                    <Button color="secondary" onClick={() => { onPeopleOpen();setTextValue('') }}>Edit Peoples</Button>
                                </div>
                                <div className="grid grid-cols-2 gap-x-2 gap-y-8">
                                    {displayedItems && displayedItems.length > 0 ? <>
                                        {displayedItems.map((data) => {
                                            return <div key={data?.id} className="flex flex-row items-center gap-4">
                                                <Avatar isBordered radius="sm" size="lg" color={colorPicker(data.id)}
                                                    src={data?.attributes?.Picture?.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.attributes?.Picture?.data?.attributes?.url}` : `${data?.attributes?.Picture?.data?.attributes?.url}`}` : ""} name={NameCreater(data.attributes.firstname, data.attributes.email)} />
                                                <div>
                                                    <p>{data?.attributes?.firstname !== null ? `${data?.attributes?.firstname} ${data?.attributes?.lastname !== null && data?.attributes?.lastname}` : data?.attributes?.email}</p>
                                                    <div className="flex flex-row text-slate-400">
                                                        <p>{data.attributes.Position}</p>
                                                        {data.attributes.Position && data.attributes.Location ? <span className="leading-none text-lg font-bold pl-4 pr-1">.</span> : ""}
                                                        <p>{data.attributes.Location}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        {!showAll ? (<div>
                                            {TeamMembersData && TeamMembersData.length > 5 ?
                                                <Button
                                                    variant="bordered"
                                                    size='sm' onClick={handleShowMore}>Show More</Button>
                                                : ""}
                                        </div>
                                        ) : (<div>
                                            <Button
                                                variant="bordered"
                                                size='sm' onClick={handleShowLess}>Show less</Button>
                                        </div>
                                        )}
                                    </> : <div className="flex flex-row items-center  pt- text-slate-400">
                                        No members invited yet.
                                    </div>}
                                </div>
                            </Tab>
                            <Tab key="Collections" title="Collections">
                                <TeamsCollection
                                    Collections={Collections}
                                    darkMode={darkMode}
                                    teamsData={teamsData}
                                    setreload={setreload}
                                    setLoader={setLoader}
                                    setReload={setreload}
                                    TeamsID={params._id} />
                            </Tab>
                            <Tab key="Settings" title="Settings" onPress={() => setSelectedTeam(teamsData)}>
                                <EditTeams
                                    isOpen={isEditOpen}
                                    onOpenChange={onEditOpenChange}
                                    selectedTeam={teamsData}
                                    setLoader={setLoader}
                                    setResponse={setreload}
                                />
                                <div className='pt-8'>
                                    <Card className="shadow-none border border-danger rounded-2xl p-4">
                                        <CardBody>
                                            <p className='text-lg font-normal'>Danger zone</p>
                                            <p className='text-lg font-normal pt-8  '>Delete your Team</p>
                                            <p className='text-sm pt-4'>This will permanently remove your team you will lose access to all your data inside your team.</p>
                                            <div className='pt-4 flex flex-row justify-end'>
                                                <Button size='sm' className='text-base' onClick={onDeleteOpen} color='danger'>Delete team</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <TeamMembers
                    darkMode={darkMode}
                    isPeopleOpen={isPeopleOpen}
                    onPeopleOpenChange={onPeopleOpenChange}
                    teamsData={teamsData}
                    setreload={setreload}
                    setTextValue={setTextValue}
                    textValue={textValue}
                />
                <DeleteModal
                    isOpen={isDeleteOpen}
                    onOpenChange={onDeleteOpenChange}
                    deleteData={deleteData}
                    setDeleteData={setDeleteData}
                    Collectionname={"teams"}
                    setLoader={setLoader}
                    DeleteHandler={DeleteHandler}
                />
            </div>
        )
    }
}

export default SingleTeam;