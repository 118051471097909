import { Button, Accordion, AccordionItem, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Input, Checkbox } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { view_schema } from '../../../controllers/vectordbController'
import Loading from '../../../components/Loader/Loading';
import { convertKeysToLowercase } from '../../../controllers/basicControllers';
import { userLoginDetails } from '../../../controllers/strapiController';
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import DBSubmit from './DBSubmit';
import {useNavigate} from "react-router-dom";


// Used for setting the context of the data product
const Context = ({ darkMode, formData, setFormData, onPrevious, onNext, selectProduct }) => {
    //  STATE INITIALIZATION
    const [loading, setLoading] = useState(true);
    const [schema, setSchema] = useState(null);
    const [inputValue, setInputValue] = useState({});
    const [tableContext, setTableContext] = useState({});
    const [selectedKeys, setSelectedKeys] = useState([]);
    const databaseSource = selectProduct?.attributes.Name;
    const [finalJson, setFinalJson] = useState([]);
    const [validator, setValidator] = useState(false)
    const userId = userLoginDetails()?.userId;
    const spaceData = useSelector(state => state);
    const indexData = spaceData && JSON.stringify(spaceData, null, 2);
    const convertedData = JSON.parse(indexData);
    const navigate=useNavigate();

    const ConnectionPayload = {
        source: databaseSource,
        ...formData.DBJson
    };
    const ConnectionPayloadLowerCase = convertKeysToLowercase(ConnectionPayload);


    // SCHEMA RENDERING
    useEffect(() => {
        setLoading(true);
        if (databaseSource && formData.inputFields) {
            view_schema(ConnectionPayloadLowerCase)
                .then((data) => { setSchema(data); setLoading(false) })
        }
    }, [databaseSource, formData.inputFields]);

    // HANDLE FOR EACH ROW CONTEXT
    const handleInputChange = (tableName, column, value) => {

        setInputValue((prev) => ({
            ...prev,
            [tableName]: {
                ...prev[tableName],
                [column.name]: value
            }
        }));

        // Clear the validation error as soon as the user starts typing
        setValidator((prevValidator) => ({
            ...prevValidator,
            [tableName]: {
                ...prevValidator[tableName],
                [column.name]: false
            }
        }));
        // Update the selectedKeys state
        setSelectedKeys((prev) => {
            const updatedKeys = { ...prev };

            updatedKeys[tableName] = updatedKeys[tableName]
                ? [...updatedKeys[tableName].filter((col) => col.name !== column.name), { ...column, Context: value }]
                : [{ ...column, Context: value }];

            return updatedKeys;
        });
    };

    // TABLE CONTEXT FOR EACH ACCORDIAN
    const handleContextChange = (tableName, value) => {
        setTableContext(prev => ({
            ...prev,
            [tableName]: value
        }
        ));

        setFinalJson((prev) => {
            const previousSchema = prev[tableName]?.column_Schema ? prev[tableName].column_Schema : [];
            return {
                ...prev,
                [tableName]: {
                    ...prev[tableName],
                    column_Schema: previousSchema,
                    table_context: value // update the table_context value
                }
            };
        });
    }

    // HANDLE ROW SELECT HANDLER
    const handleRowSelection = (tableName, column, isChecked, inputValue) => {
        const Context = inputValue?.[tableName]?.[column?.name] || "";

        setSelectedKeys((prev) => {
            const updatedKeys = { ...prev };

            // If the row is checked (selected)
            if (isChecked) {
                if (Context !== "") {
                    setValidator((prevValidator) => ({
                        ...prevValidator,
                        [tableName]: {
                            ...prevValidator[tableName],
                            [column.name]: false, // No error if context is present
                        }
                    }));
                    updatedKeys[tableName] = updatedKeys[tableName]
                        ? [...updatedKeys[tableName], { ...column, Context: Context }]
                        : [{ ...column, Context: Context }];
                } else {
                    // Clear validation when row is unchecked
                    setValidator((prevValidator) => ({
                        ...prevValidator,
                        [tableName]: {
                            ...prevValidator[tableName],
                            [column.name]: true, // Set error if context is empty
                        }
                    }));
                }
            } else {
                if (updatedKeys[tableName]) {
                    updatedKeys[tableName] = updatedKeys[tableName].filter(
                        (col) => col.name !== column.name
                    );
                }
            }
            return updatedKeys;
        });
    };



    // FORMATING THE JSON DEPENDING ON CHECKED ROW 
    useEffect(() => {
        Object.keys(selectedKeys)?.map((data, index) => {
            setFinalJson(prev => ({
                ...prev,
                [data]: {
                    ...prev[data],
                    column_Schema: selectedKeys[data],
                    "table_context": tableContext[data] || ""
                }
            }))
        })

    }, [selectedKeys, inputValue])

    console.log("finalJson",finalJson)

    const handleSubmit = async () => {
        const response=await DBSubmit(formData,convertedData,userId,finalJson);
        if(response?.status==="200"){
          navigate(`/${convertedData?.space?.[0]?.attributes?.Name}/${response?.response?.data?.attributes?.Type}/${response?.response?.data?.id}`)
        }else{
            toast.error("Something went wrong. Try after some time.")
        }

       

    };

    if (loading) {
        return <Loading darkMode={darkMode} />
    }
    return (
        <div>
            <div className="pt-4">
                <Accordion selectionMode="multiple">
                    {schema?.tables && Object.keys(schema.tables).map((tableName) => (
                        <AccordionItem key={tableName} aria-label={tableName} title={tableName}>
                            <Input
                                label="Table Context"
                                labelPlacement="outside"
                                placeholder={`Add context to ${tableName}`}
                                value={tableContext?.[tableName] || ''}
                                onChange={(e) => handleContextChange(tableName, e.target.value)}
                            />
                            <div className='mt-8'>
                                <Table removeWrapper aria-label={`Table data for ${tableName}`}>
                                    <TableHeader>
                                        <TableColumn>Column Name</TableColumn>
                                        <TableColumn>Type</TableColumn>
                                        <TableColumn>Context</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                        {schema?.tables[tableName].map((column, index) => {
                                            const data = finalJson[tableName]?.column_Schema?.find((data) => data.name === column.name)

                                            return <TableRow key={index + 1} value={index}>
                                                <TableCell>
                                                    <Checkbox isSelected={data ? true : false} onChange={(e) => handleRowSelection(tableName, column, e.target.checked, inputValue)} />
                                                    {column.name}
                                                </TableCell>
                                                <TableCell>{column.type}</TableCell>
                                                <TableCell>
                                                    <div>
                                                        <Input
                                                            value={inputValue[tableName]?.[column.name] || ''} // Access input value for the specific row
                                                            onChange={(e) => handleInputChange(tableName, column, e.target.value)}
                                                            errorMessage={validator[tableName]?.[column.name] === true ? "Context is required." : ''}
                                                            isInvalid={validator[tableName]?.[column.name] === true} />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
            <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                <Button variant="light" onClick={onPrevious}>Back</Button>
                <Button variant="flat" color="secondary" isDisabled={!formData.type} onClick={handleSubmit}>Create</Button>
            </div>
        </div>
    )
}

export default Context;
