import React, { useState, useEffect } from 'react';
import { Avatar, Tabs, Tab } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import { toolsSkeleton } from '../../components/Skeleton';
import NotFound from "../../components/NotFound";
import ServerIssue from "../../components/ServerIssue";
import Forbidden from "../../components/Forbbiden";
import { toast } from "react-hot-toast";
import { colorPicker } from "../../controllers/basicControllers";
import Configuration from '../subPages/Configuration';
import Settings from '../subPages/Settings';

const ToolsCreate = ({ darkMode, titleVisible, onPrevious }) => {
    // INITIAL DECLARATIONS
    const [toolsData, setToolsData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [reload, setReload] = useState();
    const [notFound, setNotFound] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const params = useParams();
    const type = "tools";
    const [selected, setSelected] = React.useState();


    // TOOLS SINGLE DATA RENDERING
    useEffect(() => {
        if (params._id) {
            const url = `filters[space][Name][$eq]=${params._spaceid}&filters[id][$eq]=${params._id}&filters[Type][$eq]=${type}&[populate][data_product][on]`
            CommonCollectionFilterApi('collections', url)
                .then((data) => {
                    setLoader(false);
                    if (data.error) {
                        if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
                        if (data.error.status === 404) { setNotFound(true) }
                        if (data.error.status === 403) { setForbidden(true) }
                    } else if (data.data.length > 0) {
                        setLoader(false);
                        setToolsData(data?.data?.[0])
                    } else {
                        setNotFound(true);
                    }
                })
                .catch((error) => { toast.error("Oops!, something went wrong, please try after some time."); setLoader(false); })
        } else {
            setLoader(false);
        }
    }, [params._id, reload])


    if (notFound) {
        return <NotFound />
    }

    if (serverIssue) {
        return <ServerIssue />
    }

    if (forbidden) {
        return <Forbidden />
    }

    return (
        <div>
            {loader ? toolsSkeleton() : <>
                <div className='flex gap-4 items-center'>
                    <Avatar
                        radius='sm'
                        src={toolsData?.attributes?.data_product?.data?.attributes?.Image}
                        width={30} />
                    <div className='text-3xl font-medium'>{toolsData?.attributes?.Name}</div>
                </div>
                {/* Description */}
                <div className='mt-8'>
                    {toolsData?.attributes?.Description}
                </div>
                <div className='pt-8'>
                    <Tabs
                        aria-label="Options"
                        color={colorPicker(toolsData?.id)}
                        variant="underlined"
                        selectedKey={selected}
                        onSelectionChange={setSelected}>
                        <Tab key="overview" title="Overview">
                        </Tab>
                        <Tab key="Configuration" title="Configuration">
                            <Configuration darkMode={darkMode} collection={toolsData} setReload={setReload} setLoader={setLoader} />
                        </Tab>
                        <Tab key="settings" title="Settings">
                            <Settings darkMode={darkMode} collection={toolsData} setReload={setReload} setLoader={setLoader} />
                        </Tab>
                    </Tabs>
                </div>
            </>}
        </div>
    )
}

export default ToolsCreate;