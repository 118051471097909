import React from 'react'

const EmptyArray = ({message}) => {
  return (
    <div className="flex flex-row items-center justify-center pt-24 text-slate-400">
        {message}
        </div>
  )
}

export default EmptyArray;