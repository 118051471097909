// validationUtils.js
export const validateTemplateForm = (formData) => {
    const errors = {};
  
    if (!formData.form?.Name) {
      errors.Name = "Name is required.";
    } else if (formData?.form?.Name?.length < 3) {
      errors.Name = "Name must be at least 3 characters long.";
    }
  
    if (!formData.form?.Description) {
      errors.Description = "Description is required.";
    }
  
    return errors;
  };
  