import React, { useState, useEffect } from 'react';
import { getAllData, UpdateData } from "../../controllers/strapiController";
import SubNavbar from "../../components/SubNavbar"
import { Input, Button } from "@nextui-org/react";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const JoinSpace = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [spaceData, setSpaceData] = useState();
    const [inputText, setInputText] = useState('');
    const [decryptedText, setDecryptedText] = useState();
    const userDetails = JSON.parse(sessionStorage.getItem("userData"))
    const userID = userDetails && userDetails.user && userDetails.user.id;
    const [validationMessage, setValidatonMessage] = useState('');
    const [validTest, setValidTest] = useState(false);
    const urlRegex = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/i;
    const navigate = useNavigate();

    // API RESPONSE
    useEffect(() => {
        getAllData("spaces")
            .then((data) => setSpaceData(data.data))
            .catch((error) => console.log("error", error))
    }, [userID])

    // STRING ENCRYPTION
    const queryString = inputText.substring(inputText.indexOf("?") + 1);
    const spaceURL = inputText && inputText.split("/").map((data) => data.trim());
    const handleDecrypt = () => {
        try {
            const id = queryString
            const hash = id;
            const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`; // Replace with your actual secret key
            const base64Decoded = atob(hash)
            const decrypted = CryptoJS.AES.decrypt(base64Decoded, secretKey).toString(CryptoJS.enc.Utf8);
            setDecryptedText(decrypted.toString());
        } catch (error) {
            setDecryptedText('Decryption failed');
        }
    }

    const userExists = userDetails && decryptedText && (JSON.stringify(decryptedText) === JSON.stringify(userDetails.user.email));
    const spaceExists = spaceData && spaceData.find((data) => data.attributes.Name.includes(spaceURL && spaceURL[3]))

    // INPUT TEXT CONVERTER
    useEffect(() => {
        handleDecrypt()
    }, [inputText])

    // FORM VALIDATION FUNCTION
    const validateForm = () => {
        let isValid = true;

        const isValidURL = urlRegex.test(inputText);

        if (!isValidURL) {
            isValid = false;
            setValidatonMessage("Enter a valid URL.");
        } else if (isValidURL && !spaceExists) {
            isValid = false;
            setValidatonMessage("Enter a valid URL.");
        }
        else if (isValidURL && spaceExists && !userExists) {
            isValid = false;
            setValidatonMessage("Please login with the same email where you got the link.");
        }
        return isValid;
    };

    // JOIN SPACE FUNCTION
    const joinSpaceHandler = async () => {
        const validation = validateForm();

        if (validation) {
            if (spaceExists?.attributes?.admin?.data?.id !== userID) {
                const spaceId = spaceExists && spaceExists.id
                setValidTest(false);
                const payload = { members: { disconnect: [], connect: [{ id: userID, position: { end: true } }] } }
                const response = await UpdateData("spaces", spaceId, payload);
                if (response) {
                    navigate(`/${spaceExists.attributes.Name}`)
                }
            } else {
                navigate(`/${spaceExists.attributes.Name}`)
            }
        } else {
            setValidTest(true);
        }
    }

    // RETURN UI
    return (
        <div className="max-w-6xl mx-auto h-screen flex flex-col">
            <div className="w-full">
                <SubNavbar darkMode={darkMode} />
            </div>
            <div className='h-full flex flex-col justify-center items-center mx-auto min-h-[590px] w-full max-w-[450px]'>
                <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
                    Join an existing overview
                </h1>
                <div className='mt-8'>
                    Enter an invite to join an existing overview
                </div>
                <div className='mb-4 space-y-2 w-full mt-8' >
                    <Input
                        radius="sm"
                        className='border-slate-6'
                        key={"outside"}
                        type="text"
                        label="Invite Link"
                        variant={"faded"}
                        labelPlacement={"outside"}
                        isInvalid={validTest}
                        errorMessage={validationMessage}
                        placeholder="Invite overview link"
                        onChange={(e) => setInputText(e.target.value)}
                    />
                </div>
                <div className='mb-4 space-y-2'>
                </div>
                <Button id='CreateAccount' isDisabled={inputText === ''} onClick={joinSpaceHandler} radius="sm" className={`w-full gap-1 lt-500 ${darkMode.value === true ? 'text-black hover:text-black bg-white' : 'text-white bg-black hover:bg-gray-700 hover:text-white'}`}>
                    Join overview
                    <lord-icon
                        src="https://cdn.lordicon.com/vduvxizq.json"
                        trigger="hover"
                        target="#CreateAccount"
                        colors={`primary:${darkMode.value === true ? "#000000" : "#ffffff"}`}
                        style={{
                            width: "18px",
                            height: "20px"
                        }}
                    />
                </Button>
            </div>
        </div>
    )
}

export default JoinSpace