// import React from 'react';
import React from 'react';
import { Input, Switch, Textarea } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Update_settings = ({ darkMode, formData, setFormdata, validationCondition, formError, TextRef, tagInput, handleChange, renderTags, tags, ref, FilteredTags, FilterInput, handleTagInputKeyPress, HandleOnClickInput, handleKeyPress, selectedTag, Validatetags }) => {

    // TAGS INPUT ONCHANGE HANDLER
    const handleTitleChange = (e) => {
        const userInput = e.target.value;
        const regex = /^[a-zA-Z_ -]*$/;
        if (regex.test(userInput)) {
            setFormdata({ ...formData, Name: userInput })
        }
    }

    return (
        <>
            <Input
                isRequired
                key="outside"
                className=''
                type="text"
                value={formData.Name}
                onChange={handleTitleChange}
                isInvalid={!formData.Name && validationCondition ? !formData.Name || validationCondition : ''}
                errorMessage={!formData.Name && validationCondition ? formError.NameError : ""}
                label="Collection Name"
                labelPlacement="outside"
                placeholder="Enter your collection folder name"
            />
            <Textarea
                key={"outside"}
                isRequired
                type="text"
                value={formData.Description}
                onChange={(e) => { setFormdata({ ...formData, Description: e.target.value }) }}
                isInvalid={!formData.Description && validationCondition ? !formData.Description || validationCondition : ''}
                errorMessage={!formData.Description && validationCondition ? formError.DescriptionError : ""}
                label="Decription"
                labelPlacement={"outside"}
                placeholder="Enter your collection's Description"
            />
            <div className='flex flex-col gap-1'>
                <div className='flex gap-2 flex-col'>
                    <p className='text-sm'>Make collection public</p>
                    <Switch isSelected={formData.isPublic} onChange={(e) => { setFormdata({ ...formData, isPublic: !formData.isPublic }) }} size='sm' color="secondary"></Switch>
                </div>
                {formData.isPublic ? <p className='text-xs text-slate-400'>By making your collection public anyone inside the space can view and upload your documents.</p> : <></>}
            </div>
            <div className="relative">
                <lable className="text-sm">Tags</lable>
                <div style={{ Background: "#f4f4f5" }} className={`border rounded-xl  flex flex-col border-divider p-2`}>
                    <input
                        className={`bg-background border-0 rounded text-sm`}
                        key={"outside"}
                        ref={TextRef}
                        style={{ outline: 'none' }}
                        type="text"
                        variant="bordered"
                        labelPlacement={"outside"}
                        placeholder="Press enter to add multiple tags"
                        value={tagInput && tagInput.trim()}
                        onChange={handleChange}
                        onKeyPress={(event) => { if (event.key === 'Enter') { handleKeyPress(event) } }}
                    />
                    <div className="pt-4 flex flex-wrap gap-2">
                        {renderTags()}
                    </div>
                    <p className="pt-2 text-xs text-red-400">{tags.includes(Text) || tags.includes(selectedTag) ? Validatetags : ""}</p>
                </div>
                {tagInput !== '' && tagInput !== ' ' ?
                    <div ref={ref} className='transition ease-in-out delay-200 z-50 w-full pt-2 ' >
                        <div className={`rounded-xl border w-full border-divider divide-y divide-divider overflow-y-auto  ${darkMode.value === true ? "bg-black" : "bg-white"}`} style={{ maxHeight: "325px" }} >
                            {FilteredTags && FilteredTags.length > 0 ?
                                <>
                                    {FilteredTags && FilteredTags.map((data) => {
                                        const TagsCheck = tags && tags.some((tag) => tag === data);
                                        return <div onClick={() => { HandleOnClickInput(data) }} className="p-2 text-sm flex flex-row gap-2 items-center justify-between">
                                            <div className="flex flex-row gap-2 items-center">
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/prjooket.json"
                                                    colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                    trigger="hover"
                                                    style={{ width: "16px", height: "16px" }}>
                                                </lord-icon>
                                                {data} </div>
                                            {TagsCheck ? <div>
                                                <FontAwesomeIcon icon={faCheck} />
                                            </div> : ""}
                                        </div>
                                    })}
                                    {FilterInput === false ?
                                        <p className="p-2 text-sm" onClick={handleTagInputKeyPress}>+ Add <b>"{tagInput}"</b> to tag list. </p> : ""}
                                </> :
                                <p className="p-2 text-sm" onClick={handleTagInputKeyPress}>+ Add <b>"{tagInput}"</b> to tag list. </p>
                            }
                        </div>
                    </div> : ""}
            </div>
        </>
    )
}

export default Update_settings;