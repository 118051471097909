import React, { useState, useEffect, useRef } from 'react';
import { useDisclosure, Button, ModalHeader, ModalBody, Input } from "@nextui-org/react";
import UpdateCollection from "../Collections/UpdateCollection";
import Card from '../../components/Cards';
import { DeleteSingleAttribute, UpdateData, CreateData } from "../../controllers/strapiController";
import { StringLimit } from "../../controllers/basicControllers";
import DeleteModal from "../../components/DeleteModal";
import ModalPopup from "../Collections/ModalPopup";
import SearchIcon from '../../Images/Search';
import { useNavigate, useParams } from "react-router-dom";
import EditCondition from './EditCondition';
import CreateTeamCollection from './CreateTeamCollection';
import { cardSkeleton } from "../../components/Skeleton";
import { colorPicker } from '../../controllers/basicControllers';

const TeamsCollection = ({ darkMode, teamsData, TeamsID, Collections, setreload, setLoader }) => {
  // STATE INITIALIZATION
  const { isOpen: isCollectionOpen, onOpen: onCollectionOpen, onOpenChange: onCollectionOpenChange } = useDisclosure();
  const [SelectCollection, setSelectCollection] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [selectData, setSelectData] = useState(null);
  const [validationCondition, setValidationCondition] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [IsOpen, setIsOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [InputText, setInputText] = useState('');
  const [createCollection, setCreateCollection] = useState(false);
  const [tagsData, setTagsData] = useState();
  const [tagInput, setTagInput] = useState('');
  const [response, setresponse] = useState('');
  const Navigate = useNavigate();
  const [colLoader, setColLoader] = useState(false);
  const params = useParams();
  const userDetails = JSON.parse(sessionStorage.getItem("userData"))
  const userId = userDetails && userDetails.user.id;
  const [formData, setFormdata] = useState({
    Name: InputText !== "" ? InputText : "",
    Description: '',
    Tags: [],
    isPublic: false
  });
  const [formError, setFormError] = useState({
    NameError: '',
    DescriptionError: '',
    CommonError: ''
  });
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const { isOpen: isWarningOpen, onOpen: onWarningOpen, onOpenChange: onWarningOpenChange } = useDisclosure();


  // DELETE WARNING 
  const DeleteWarningHandle = (data) => {
    setDeleteData(data);
    onDeleteOpen();
  }

  // COLLECTION FILTER WHICH HAVE RELATION TEAMS
  const teamsCollection = teamsData?.attributes?.collections?.data;

  // EDITOR OPEN HANDLER
  const EditOpen = (SelectedCollection) => {
    onWarningOpen();
    setSelectCollection(SelectedCollection)
  }

  // DELETE COLLECTION HANDLER
  const DeleteHandler = async (onClose) => {
    setLoader(true)
    const response = await DeleteSingleAttribute("collections", deleteData.id)
    if (response) {
      onClose();
      setreload(response);
    }
  }

  useEffect(() => {
    setFormdata((previous) => ({ ...previous, Name: InputText }))
  }, [InputText])

  // FILTERING COLLECTION WITH INPUT FIELD DATA
  const filteredData = Collections && Collections.find(item => {
    return item?.attributes?.Name?.toLowerCase() === formData?.Name?.toLowerCase();
  });

  // HANDLE KEY ENTER TO RENDER DATA
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && InputText?.trim() !== '') {
      if (filteredData) {

        setSelectData(filteredData);
        setShowResults(false);
        setCreateCollection(true);
      } else {
        setShowResults(false);
        setCreateCollection(true);
      }
    }
    else {
      setIsOpen(false);
    }
  }

  const CommonCollectionId = teamsData?.attributes?.collections?.data?.map((data) => data.id)

  // INPUT HANDLER TO FILTER COLLECTION DATA
  const HandleChange = (event) => {
    const TextValue = event.target.value;
    setInputText(TextValue);
    const Filterdata = Collections && Collections.filter((data) => data?.attributes?.Name?.toLowerCase().includes(TextValue.toLowerCase()))
    setShowResults(true);
    if (Filterdata && Filterdata.length > 0) {
      setSearchResults(Filterdata);
      setSelectData(null);
      setCreateCollection(false)
    } else {
      setSearchResults(null);
      setSelectData(null);
      setCreateCollection(false)
    }
  }


  const resultsRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShowResults(!showResults);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resultsRef]);

  // ONcKCICK TO SELECT COLLECTION DATA
  const OnSelectHandler = (data, name) => {
    setSelectData(data);
    setInputText(name);
    setShowResults(false);
  }

  // ON ENTER TO CREATE NEW COLLECTION WITH INPUT NAME
  const onCreateHandler = (e) => {
    setSelectData(null);
    setShowResults(false);
    setCreateCollection(true);
  }

  // FORM VALIDATION
  const Validation = () => {
    let IsValid = true;

    if (!formData.Name) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
    } else {
      setFormError((previous) => ({ ...previous, NameError: null }))
    }

    if (filteredData) {
      setFormError((previous) => ({ ...previous, NameError: "Collection name already exists." }));
    } else {
      setFormError((previous) => ({ ...previous, NameError: null }))
    }

    if (!formData.Description) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
    } else {
      setFormError((previous) => ({ ...previous, DescriptionError: null }))
    }
    return IsValid;
  }


  // TAGS FILTER DROPDOWN
  const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userId)
  // TAGS PRESENTS
  const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
  // FILTER FOR INPUT TEXT RELATED TO TAGS
  const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

  // FINAL DISPLAY TAGS
  const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []
  const FilterInput = FilteredTags && FilteredTags.some((tag) => tag.toLowerCase() === tagInput.toLowerCase())


  const TagsRender = formData.Tags && formData.Tags !== null && TagsLength && formData.Tags.filter((data) => !TagsLength.includes(data));
  const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
  const TagsID = FilteredUser?.[0]?.id;

  // SUBMIT HANDLER TO CREATE COLLECTION
  const SubmitHandler = async (onClose) => {
    const Validate = Validation();
    const payload = {
      Name: formData.Name,
      Description: formData.Description,
      author: { disconnect: [], connect: [{ id: userId, position: { end: true } }] },
      teams: { disconnect: [], connect: [{ id: TeamsID, position: { end: true } }] },
      // inviteds: { disconnect: [],connect: inviteUser},
      Public: formData.isPublic,
      color: "",
      Tags: { "tags": formData.Tags },
    }

    const TagsPayload = {
      Tags: { tags: NewRenderation }
    }

    if (Validate) {
      setColLoader(true)
      setValidationCondition(false);
      const response = await CreateData("collections", payload)
      if (response) {
        if (FilteredUser && FilteredUser.length > 0) {
          const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
          setreload(updateResponse)
          if (response) {
            setresponse(response)
            setFormdata({ Name: '', Description: '', Tags: [], isPublic: false });
          }
        }
        if (FilteredUser && FilteredUser.length === 0) {
          const payload = {
            users_permissions_user: { disconnect: [], connect: [{ id: userId, position: { end: true } }] },
            Tags: { "tags": formData.Tags }
          }
          const response = await CreateData("tags", payload);
          if (response) {
            setresponse(response)
          }
        }
      }
      if (response) {
        const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
        setreload(updateResponse)
        onClose();
        setColLoader(false);
      }
    } else {
      setValidationCondition(true);
    }
  }

  // ADD RELATION WITH COLLECTION AND TEAMS
  const AddCollectionHandler = async (onClose) => {
    const payload = {
      collections: {
        disconnect: [],
        connect: [{
          id: selectData.id,
          position: true
        }]
      }
    }

    const response = await UpdateData("teams", TeamsID, payload)
    if (response) {
      setreload(response);
      setSelectData(null);
      setInputText('')
      onClose();
    }
  }

  // CREATE COLLECTION MODAL
  const CollectionModal = () => {
    return <>
      <ModalHeader className="flex flex-col  gap-1">Add/Create a new Collection</ModalHeader>
      <ModalBody className='gap-0 min-h-96 h-96'>
        <div className='relative w-full '>
          <div className='w-full relative'>
            <Input
              classNames=""
              onChange={HandleChange}
              value={InputText}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleKeyPress(event);
                }
              }}
              placeholder='Search by files, tags, content...' className=''
              startContent={<SearchIcon darkMode={darkMode} className={`text-large text- pointer-events-none flex-shrink-0 `} />}
            />
          </div>
          {InputText !== "" ? <> {showResults && (
            <div className='transition ease-in-out delay-200 z-50 w-full pt-2 '>
              <div ref={resultsRef} className={`rounded-xl border border-divider divide-y divide-divider overflow-y-auto min-h-16 ${darkMode.value === true ? "bg-transforent" : "bg-white"}`} style={{ maxHeight: "325px" }} >
                <div className='p-4' onClick={onCreateHandler}>
                  <p id='File' className='flex flex-row items-center text-foreground gap-2'>
                    <lord-icon
                      src="https://cdn.lordicon.com/kkvxgpti.json"
                      trigger="hover"
                      colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                      style={{ width: "20px", height: "20px" }}>
                    </lord-icon>  Create new collection "{InputText}"
                  </p>
                </div>
                {searchResults && searchResults.length > 0 ?
                  <>
                    {searchResults && searchResults.map((data, index) => {
                      const name = data?.attributes?.Name;
                      const regex = new RegExp(`(${InputText})`, 'i');
                      const parts = name.split(regex);
                      const highlightedIndex = parts.findIndex(part => part.toLowerCase() === InputText.toLowerCase());
                      const IsDisable = CommonCollectionId && CommonCollectionId.some((value) => value === data.id)

                      return (
                        <div key={data?.id} className={`p-4 ${IsDisable ? " cursor-not-allowed" : "cursor-pointer"}`} onClick={() => IsDisable ? "" : OnSelectHandler(data, name)}>
                          <p id='File' className={`flex flex-row items-center gap-1 ${IsDisable ? "pointer-events-none opacity-50" : ""}`} >
                            <lord-icon
                              src="https://cdn.lordicon.com/jkzgajyr.json"
                              trigger="hover"
                              target="#File"
                              colors={`primary:${darkMode && darkMode.value === true ? "#ffffff" : "#000000"}`}
                              style={{ width: "20px", height: "20px", paddingRight: "8px" }}>
                            </lord-icon>
                            <p>
                              {parts.map((part, i) => {
                                const NewFormatData = part.endsWith(" ") && `${part} `
                                return <span key={`${i}`}>
                                  {i === highlightedIndex ?
                                    <span className='bg-indigo-200 text-black'>{part}</span>
                                    : <span className='whitespace-nowrap'>{NewFormatData ? (<>{part.trim()}<span className='invisible'>_</span></>) : part}</span>
                                  }
                                </span>
                              })}
                            </p>
                          </p>
                          <p className='text-slate-400 text-sm'>{data.attributes.Description && StringLimit(data.attributes.Description, 50)}</p>
                        </div>
                      );
                    })}</>
                  : ""
                }
              </div>
            </div>)}</> : ""}
          <p>{ }</p>
        </div>
        <div className="z-40">
          {selectData && selectData ?
            <> <EditCondition
              isWarningOpen={isWarningOpen}
              onWarningOpenChange={onWarningOpenChange}
              SelectCollection={selectData}
              setLoader={setLoader}
              darkMode={darkMode}
              setReload={setreload}
              formData={selectData}
            // reload={reload}
            /></> :
            <>{createCollection &&
              <CreateTeamCollection
                darkMode={darkMode}
                UserID={userId}
                setFormdata={setFormdata}
                tagsData={tagsData}
                formData={formData}
                tagInput={tagInput}
                filteredData={filteredData}
                validationCondition={validationCondition}
                setTagsData={setTagsData}
                setTagInput={setTagInput}
                FilterInput={FilterInput}
                collections={Collections}
                FilteredTags={FilteredTags}
                formError={formError}
              />}</>}
        </div>
      </ModalBody>
    </>
  }

  const CreateFooter = (onClose) => {
    return <>
      <Button color="none" onClick={() => { onClose(); setFormdata({ Name: "", Description: '', Tags: [], isPublic: false }); setInputText(''); setSelectData(null); setCreateCollection(false) }}>Cacel</Button>
      <Button color="secondary" onClick={selectData ? () => AddCollectionHandler(onClose) : () => SubmitHandler(onClose)}>{selectData ? "Add" : "Create"}</Button>
    </>
  }

  if (colLoader) {
    return cardSkeleton()
  }

  return (
    <div>
      <div className="">
        <div className="flex flex-row items-end justify-between">
          <p className="text-lg font-medium pt-8">Collections</p>
          <Button color="secondary" onClick={() => { onCollectionOpen(); setFormdata({ Name: "", Description: '', Tags: [], isPublic: false }); setInputText(''); setSelectData(null); setCreateCollection(false) }} >Add or Create collection</Button>
        </div>
        <p className="text-base pt-4">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Saepe repellat recusandae, incidunt provident similique corrupti nemo quisquam magni dolor laudantium quasi beatae, laboriosam dolore? Amet quidem perspiciatis ea ducimus ex.</p>
      </div>
      <div>
        <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >
          {teamsCollection && teamsCollection.map((data, index) => {
            const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
            const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
            const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)

            return <div key={`${index}`} className=' ' onClick={() => Navigate(`/${params._spaceid}/collections/${data.id}`)}>
              <Card
                data={data}
                MurgedUsers={MurgedUsers}
                colorIndex={colorPicker(data.id)}
                darkMode={darkMode}
                EditOpen={EditOpen}
                userId={userId}
                ActionVisible={true}
                DeleteWarningHandle={DeleteWarningHandle} />
            </div>
          })}
        </div>
      </div>
      <UpdateCollection
        isWarningOpen={isWarningOpen}
        onWarningOpenChange={onWarningOpenChange}
        SelectCollection={SelectCollection}
        setLoader={setLoader}
        darkMode={darkMode}
        setReload={setreload}
      // reload={reload}
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        onOpenChange={onDeleteOpenChange}
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        Collectionname={"collection"}
        setLoader={setLoader}
        DeleteHandler={DeleteHandler}
      />
      <ModalPopup
        isOpen={isCollectionOpen}
        size="2xl"
        onOpenChange={onCollectionOpenChange}
        ModalBodyData={CollectionModal}
        footer={CreateFooter}
        scrollBehavior="inside"
      />
    </div>
  )
}

export default TeamsCollection;