import React, { useState, useEffect } from 'react'
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import NotFound from "../../components/NotFound";
import { TableCell, TableBody, TableRow, Table, TableHeader, TableColumn, Avatar, AvatarGroup, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, useDisclosure } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonCollectionFilterApi, DeleteSingleAttribute, userLoginDetails } from "../../controllers/strapiController";
import { NameCreater, titleCreater } from "../../controllers/basicControllers";
import CreateTeam from "./CreateTeam";
import { useDispatch } from "react-redux";
import DeleteModal from "../../components/DeleteModal";
import { tableSkeleton } from '../../components/Skeleton';
import { reDirect } from "../../redux/actions";
import Paginate from "../../components/Pagination";
import { colorPicker } from '../../controllers/basicControllers';
import EmptyArray from '../../components/EmptyArray';
import { toast } from "react-hot-toast";

const Teams = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [Loader, setLoader] = useState(true);
    const Navigate = useNavigate();
    const [teamsData, setTeamsData] = useState([]);
    const [deleteData, setDeleteData] = useState([]);
    const [spaceData, setSpaceData] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
    const params = useParams();
    const userId = userLoginDetails();
    const title = params._spaceid;
    const dispatch = useDispatch();
    const userID = userLoginDetails()?.userId

    // API RENDERING
    useEffect(() => {
        setLoader(true);

        const url2 = `filters[$or][0][admin][id][$eq]=${userId.userId}&filters[$or][1][members][id][$eq]=${userId.userId}&filters[Name][$in][1]=${title}&[populate][admin][on]`
        CommonCollectionFilterApi("spaces", url2)
            .then((data) => {
                if (data.error || data.data === null) {
                    setTimeout(() => { setNotFound(true) }, 300)
                } else {
                    setSpaceData(data.data);
                    setNotFound(false);
                }
            })
            .catch((error) => console.log("error", error))

        var url = `filters[$or][0][author][id][$eq]=${userId.userId}&sort[0]=createdAt:desc&filters[$or][1][members][id][$eq]=${userId.userId}&[populate][Logo][on]&[populate][Cover][on]&[populate][collections][on]&[populate][collections]populate[collections][populate][inviteds][populate][Picture][fields][0]=name&populate[collections][populate][inviteds][populate][Picture][populate][fields][3]=url&[populate][collections]populate[collections][populate][viewed][populate][Picture][fields][0]=name&populate[collections][populate][viewed][populate][Picture][populate][fields][3]=url&[populate][collections]populate[collections][populate][requests][populate][Picture][fields][0]=name&populate[collections][populate][requests][populate][Picture][populate][fields][3]=url&[populate][members]populate[members][populate][Picture][fields][0]=name&populate[members][populate][Picture][populate][fields][3]=url`
        CommonCollectionFilterApi("teams", url)
            .then((data) => {
                setTimeout(() => { setLoader(false); }, 300)
                setTeamsData(data.data)
            })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    // TEST USER IS ENTERPRISES OR SHARED
    const filterAdmin = spaceData && spaceData[0] && spaceData[0] && spaceData[0].attributes.type !== "Enterprise";

    const sortedData = teamsData && teamsData.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))

    const recordsPerPage = 10;

    // Calculate indexes for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedData && sortedData.slice(indexOfFirstRecord, indexOfLastRecord);


    const EditHandler = (data) => {
        Navigate(`${data.id}`);
        dispatch(reDirect("Settings"))
    }

    const DeleteHandler = async (onClose) => {
        setLoader(true)
        const response = await DeleteSingleAttribute("teams", deleteData.id)
        if (response) {
            window.location.reload();
        }
    }

    if (notFound || filterAdmin) {
        return <NotFound />
    }

    if (spaceData.length > 0 && sortedData) {
        return (
            <div className=" max-w-6xl mx-auto py-8">
                <div className='text-3xl font-medium'>Teams</div>
                <p className="mt-4 leading-relaxed">
                    Incorporate your own teams into your data mesh to foster collaboration and data ownership. This feature allows you to define roles, assign responsibilities, and integrate team-specific data sources, ensuring that every team can contribute to and benefit from a unified data ecosystem.
                </p>
                <div className='flex mt-4 justify-end'>
                    <CreateTeam darkMode={darkMode} spaceData={spaceData?.[0]} />
                </div>
                <div className='pt-8 px-8'>
                    {Loader ?
                        tableSkeleton()
                        : <>
                            {sortedData && sortedData.length > 0 ? <>
                                <Table removeWrapper aria-label="Example static collection table">
                                    <TableHeader>
                                        <TableColumn></TableColumn>
                                        <TableColumn>Team name</TableColumn>
                                        <TableColumn>Team members</TableColumn>
                                        <TableColumn className="flex justify-center items-center">Actions</TableColumn>
                                    </TableHeader>
                                    <TableBody>
                                        {currentRecords && currentRecords.map((data, index) => {
                                            const name = data?.attributes?.Name;
                                            const slitedData = name && name.split(" ");
                                            const dataCount = slitedData?.length > 1 ? slitedData && (slitedData[0].slice(0, 1) + slitedData[1].slice(0, 1)) : slitedData && slitedData[0]
                                            const Capitalize = dataCount && dataCount.toUpperCase();
                                            return <TableRow onClick={() => Navigate(`/${params._spaceid}/teams/${data.id}`)} key="1" className='cursor-pointer hover:bg-grey-200'>
                                                <TableCell>
                                                    <Avatar size='sm' name={Capitalize} color={colorPicker(data.id)} src={data?.attributes?.Logo?.data !== null && `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data?.attributes?.Logo?.data?.attributes?.url}` : `${data?.attributes?.Logo?.data?.attributes?.url}`}`} />
                                                </TableCell>
                                                <TableCell>{titleCreater(data?.attributes?.Name)}</TableCell>
                                                <TableCell>
                                                    {data?.attributes?.members?.data?.length > 0 ?
                                                        <AvatarGroup className="justify-start" size='sm' max={3}>
                                                            {data?.attributes?.members?.data?.map((image, i) => {
                                                                return <Avatar color={colorPicker(image.id)}
                                                                    src={image?.attributes?.Picture?.data !== null && `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${image?.attributes?.Picture?.data?.attributes?.url}` : `${image?.attributes?.Picture?.data?.attributes?.url}`}`} name={NameCreater(image.attributes.firstname, image.attributes.email)} />
                                                            })}
                                                        </AvatarGroup>
                                                        : "-"}
                                                </TableCell>
                                                <TableCell className="flex justify-center items-center">
                                                    <Dropdown placement="bottom-end">
                                                        <DropdownTrigger>
                                                            <span className=" text-2xl leading-3 cursor-pointer">...</span>
                                                        </DropdownTrigger>
                                                        <DropdownMenu aria-label="Profile Actions" variant="flat">
                                                            <DropdownItem key="edit" >
                                                                <div className='flex items-center gap-2' onClick={() => EditHandler(data)}>
                                                                    <lord-icon
                                                                        src="https://cdn.lordicon.com/pflszboa.json"
                                                                        colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                                        style={{ width: "16px", height: "16px" }}>
                                                                    </lord-icon>
                                                                    Edit
                                                                </div>
                                                            </DropdownItem>
                                                            <DropdownItem key="configurations" className='text-danger' color="danger">
                                                                <div className='flex items-center gap-2' onClick={() => { onDeleteOpen(); setDeleteData(data) }}>
                                                                    <lord-icon
                                                                        src="https://cdn.lordicon.com/wpyrrmcq.json"
                                                                        colors="primary:#e1426c"
                                                                        style={{ width: "16px", height: "16px" }}>
                                                                    </lord-icon>
                                                                    Delete
                                                                </div>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </TableCell>
                                            </TableRow>
                                        })}
                                    </TableBody>
                                </Table>
                            </>
                                : <EmptyArray message={`No Teams created yet. Click on upload "Create new Team" to create your Team.`} />
                            }
                            <>
                                {sortedData.length > 10 && <div className='flex justify-center pt-8'>
                                    <Paginate
                                        totalPage={sortedData}
                                        initialPage={"1"}
                                        id={userID}
                                        currentRecords={currentRecords}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        recordsPerPage={recordsPerPage}
                                    />
                                </div>
                                }
                            </>
                        </>}
                </div>

                <DeleteModal
                    isOpen={isDeleteOpen}
                    onOpenChange={onDeleteOpenChange}
                    deleteData={deleteData}
                    setDeleteData={setDeleteData}
                    Collectionname={"teams"}
                    setLoader={setLoader}
                    DeleteHandler={DeleteHandler}
                />
            </div>
        )
    }
}

export default Teams;