import React, { useState } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button } from '@nextui-org/react';
import { colorPicker, StringLimit } from "../../controllers/basicControllers";
import Context from '../DataProducts/Database/Context';

const TableProperty = ({ darkMode, connectorData }) => {
    const schema = connectorData?.attributes?.Additional_Context?.properties;
    const [editContext, setEditContext] = useState(false);

    const handleEdit = () => {
        setEditContext(!editContext);
    }

    return (
        <>
            <div className='flex flex-col gap-8'>
                <div className='flex justify-end'>
                    <Button color={colorPicker(connectorData?.id)} onClick={handleEdit}>{editContext ? "Back to context" : "Edit context"}</Button>
                </div>
                {editContext ?
                    <Context darkMode={darkMode} selectProduct={connectorData} option={"update"} setEditContext={setEditContext} /> : <>
                        {schema && Object.keys(schema).map((tableName) => {
                            return <div key={tableName}>
                                <div className='border-b border-divider pb-4 text-xl font-medium'>{tableName}</div>
                                {schema[tableName]?.table_context && <div className='pt-4'>
                                    {schema[tableName]?.table_context}
                                </div>}
                                <div className='pt-4'>
                                    <Table removeWrapper aria-label={`Table data for ${tableName}`}>
                                        <TableHeader>
                                            <TableColumn>Column Name</TableColumn>
                                            <TableColumn>Type</TableColumn>
                                            <TableColumn>Context</TableColumn>
                                        </TableHeader>
                                        <TableBody>
                                            {schema?.[tableName]?.column_schema?.map((column, index) => {
                                                return <TableRow key={index + 1} value={index}>
                                                    <TableCell>
                                                        {/* <Checkbox isSelected={true}/> */}
                                                        {column.name}
                                                    </TableCell>
                                                    <TableCell>{column.type}</TableCell>
                                                    <TableCell>
                                                        <div>
                                                            {StringLimit(column?.Context, 140)}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        })}
                    </>}
            </div>
        </>
    )
}

export default TableProperty;