import CryptoJS from "crypto-js";

export const NavigateSign = async (email, password2, path) => {
    const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/signin`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password2, path }),
    });

    const data = await response.json();

    // Store the user data in session storage
    if (data.userData) {
        sessionStorage.setItem("userData", JSON.stringify(data.userData));
    }

    return data.navigate_route;
};
