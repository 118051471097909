import { Avatar, Button, Card, CardBody, CardHeader, Chip, Input, ModalBody, ModalHeader, Textarea, Tooltip, useDisclosure } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'
import { colorPicker, StringLimit, timeDifference, titleCreater } from '../../controllers/basicControllers'
import { CommonCollectionFilterApi, getAllData, CreateData, UpdateData } from '../../controllers/strapiController'
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from 'react-router-dom';
import ModalPopup from '../Collections/ModalPopup';
import Tags from '../../components/Tags';

const Domains = ({ darkMode }) => {


  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [formData, setFormdata] = useState({ Name: '', Description: '', Tags: [] });
  const [domains, setDomains] = useState([]) // Initialize as an empty array
  const collectionname = "domains"
  const params = useParams();
  const navigate = useNavigate();
  const [validationCondition, setValidationCondition] = useState(false);
  const [formError, setFormError] = useState({ NameError: '', DescriptionError: '' });
  const [tagsData, setTagsData] = useState();
  const [tagInput, setTagInput] = useState('');
  const [validatetags, setValidatetags] = useState('');

  const [allCollections, setAllCollections] = useState(null);
  const [loader, setLoader] = useState(true);
  const [reload, setReload] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [spaceData, setSpaceData] = useState(null);
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails?.user?.id;
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const url = `filters[space][Name][$eq]=${params._spaceid}&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`
    CommonCollectionFilterApi(collectionname, url)
      .then((data) => { setDomains(data.data) })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [])

  useEffect(() => {
    setLoader(true)
    const url = `filters[Name][$eq]=${params._spaceid}`;
    CommonCollectionFilterApi("spaces", url)
      .then((data) => { setLoader(false); setSpaceData(data.data) })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [reload])

  const Validation = () => {
    let IsValid = true;

    if (!formData.Name) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
    } else {
      setFormError((previous) => ({ ...previous, NameError: null }))
    }

    if (!formData.Description) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
    } else {
      setFormError((previous) => ({ ...previous, DescriptionError: null }))
    }
    return IsValid;
  }
  const SortedData = allCollections && allCollections.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
  const spaceID = spaceData && spaceData[0] && spaceData[0].id;
  const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userID)
  // TAGS PRESENTS
  const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
  // FILTER FOR INPUT TEXT RELATED TO TAGS
  const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

  // FINAL DISPLAY TAGS
  const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

  const TagsRender = formData?.Tags && formData?.Tags !== null && TagsLength && formData.Tags.filter((data) => !TagsLength.includes(data));
  const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
  const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

  const SubmitHandler = async (onClose) => {
    const Validate = Validation();
    const payload = {
      Name: formData.Name,
      Description: formData.Description,
      Tags: { "tags": formData.Tags },
      space: {
        disconnect: [],
        connect: [{ id: spaceID, position: { end: true } }]
      }
    }

    const TagsPayload = {
      Tags: { tags: NewRenderation }
    }

    if (Validate) {
      setLoader(true)
      setValidationCondition(false);
      onClose();
      const response = await CreateData(collectionname, payload)
      if (response) {
        if (FilteredUser && FilteredUser.length > 0) {
          const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
          setReload(updateResponse)
          if (response) {
            setReload(response)
            navigate(`${response.data.id}`);
            setFormdata({ Name: '', Description: '', Tags: [], isPublic: false });
          }
        }
        if (FilteredUser && FilteredUser.length === 0) {
          const payload = {
            users_permissions_user: {
              disconnect: [],
              connect: [{ id: userID, position: { end: true } }]
            },
            Tags: { "tags": formData.Tags }
          }
          const response = await CreateData("tags", payload);
          if (response) {
            setReload(response);
          }
        }
      }
      if (response) {
        const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
        setReload(updateResponse);
        setLoader(false);
        navigate(`${response.data.id}`);
      }
    } else {
      setValidationCondition(true);
    }
  }


  const CardTags = ({ tags, colorIndex }) => (
    <div className='flex pt-2 gap-3'>
      {tags?.slice(0, 3).map((tag, index) => (
        <Chip key={index} size='sm' color={colorIndex} variant='flat'>
          {tag}
        </Chip>
      ))}
      {tags?.length > 3 && (
        <Tooltip
          content={tags.slice(3).map((tag, index) => (
            <div key={index}>{tag}</div>
          ))}
          placement='bottom'
          size='sm'
          closeDelay={10}
        >
          <Chip size='sm' color={colorIndex} variant="flat">
            + {tags?.length - 3}
          </Chip>
        </Tooltip>
      )}
    </div>
  );
  const handleChange = (e) => {
    const userInput = e.target.value;
    const regex = /^[a-zA-Z_ -]*$/;
    if (regex.test(userInput)) {
      setFormdata({ ...formData, Name: userInput })
    }
  }

  const ModalBodyData = () => {
    return <>
      <ModalHeader>Create a new Domain</ModalHeader>
      <ModalBody className='gap-0 h-96'>
        <Input
          isRequired
          key="outside"
          type="text"
          value={formData.Name}
          onChange={handleChange}
          isInvalid={!formData.Name && validationCondition ? !formData.Name || validationCondition : ''}
          errorMessage={!formData.Name && validationCondition ? formError.NameError : ""}
          label="Domain Name"
          labelPlacement="outside"
          placeholder="Enter a name for your Domain"
        />
        <Textarea
          className='pt-4'
          key={"outside"}
          isRequired
          type="text"
          value={formData.Description}
          onChange={(e) => { setFormdata({ ...formData, Description: e.target.value }) }}
          isInvalid={!formData.Description && validationCondition ? !formData.Description || validationCondition : ''}
          errorMessage={!formData.Description && validationCondition ? formError.DescriptionError : ""}
          label="Decription"
          labelPlacement={"outside"}
          placeholder="Enter your collection's Description"
        />
        <Tags
          darkMode={darkMode}
          tagsData={tagsData}
          setTagsData={setTagsData}
          tagInput={tagInput}
          setTagInput={setTagInput}
          tags={tags}
          validatetags={validatetags}
          setTags={setTags}
          setValidatetags={setValidatetags}
          setFormdata={setFormdata}
          formData={formData}
          background={"bg-transparent"}
        />
      </ModalBody>
    </>

  }

  const footerCreate = (onClose) => {
    return <>
      <Button color="default" variant="flat" onClick={() => { setFormdata({}); onClose(); }}> Cancel </Button>
      <Button color="warning" onClick={() => { SubmitHandler(onClose) }}>Create</Button>

    </>
  }

  return (
    <div>
      <div className='text-3xl font-medium'>Domains</div>
      <div className='mt-4 leading-relaxed'>
        Explore the fundamental building blocks of our data mesh architecture. Each domain represents a specific area of expertise or business function, designed to manage its own data as a product. Discover how domains are defined, structured, and interconnected to facilitate scalable, decentralized data management.
      </div>
      <div className='flex w-full justify-end mt-4'>
        <Button color='warning' onPress={() => { onOpen(); setFormdata({}) }}>Create a Domain</Button>
      </div>
      <div className='mt-8'>
        <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >

          {domains && domains.map((item) => (
            <div onClick={() => navigate(`${item.id}`)}>

              <Card
                key={item.id} // Added key prop for unique identification
                className="max-w-[340px] min-w-[340px] min-h-[240px] h-full p-2 border border-divider cursor-pointer"
                shadow='none'
              >
                <CardHeader>
                  <div className="flex gap-5">
                    <Avatar isBordered radius="full" name={item?.attributes?.Name?.slice(0, 1).toUpperCase()} size="md" color={colorPicker(item?.id)} />
                    <div className="flex flex-col gap-1 items-start justify-center">
                      <h4 className="text-small font-semibold leading-none text-default-600">{titleCreater(item?.attributes?.Name)}</h4>
                      <h5 className="text-small tracking-tight text-default-400">Updated {timeDifference(item?.attributes?.createdAt)}</h5>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="px-3 py-0 pb-4 text-small flex flex-col justify-between text-default-500">
                  <p className='py-4'>
                    {StringLimit(item?.attributes?.Description || item?.attributes?.Context, 120)}

                  </p>
                  <CardTags tags={item?.attributes?.Tags?.tags} colorIndex={colorPicker(item?.id)} />

                </CardBody>
              </Card>
            </div>

          ))}

          <ModalPopup
            size="2xl"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            ModalBodyData={ModalBodyData}
            footer={footerCreate}
            scrollBehavior="inside" />
        </div>
      </div>
    </div >

  )
}

export default Domains
