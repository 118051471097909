import React from 'react'

const ExtensionsSlack = () => {
  return (
    <div>
      <div className='text-3xl font-medium'>Connect Haya to Slack</div>
      

    </div>
  )
}

export default ExtensionsSlack