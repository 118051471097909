import React, { useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, Textarea, ModalFooter, Button } from "@nextui-org/react";
import { InviteUser } from '../controllers/strapiController';
import { toast } from "react-hot-toast";

const Feedback = ({ isOpen, onOpenChange,setInputText,inputText }) => {

    const submitHandler = async (onClose) => {
        onClose();

        const payload = {
            "to": "sreevatsan.s@unifie.io",
            "subject": "Invitation from Haya Knowledge.",
            "html": `<html><body><p>${inputText} </p></body></html>`
        };
        toast.success("Thank you for your feedback!")
        setInputText('');
        await InviteUser(payload);
    }
    return (
        <Modal
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            placement="center">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Feedback</ModalHeader>
                        <ModalBody className='gap-0'>
                            <p>We value your input!</p>
                            <p>Whether it's a suggestion for improvement, a bug you've encountered, or simply your thoughts on how we can make our product better, we're all ears.</p>
                            <Textarea
                                autoFocus
                                isRequired
                                label="Share your feedback"
                                variant='faded'
                                minRows={10}
                                onChange={(e) => setInputText(e.target.value)}
                                value={inputText}
                                className='pt-16'
                                labelPlacement='outside'
                            />
                        </ModalBody>
                        {/* Your buttons for submitting or canceling feedback can go here */}
                        <ModalFooter>
                            <Button color="default" variant="flat" onPress={onClose}>
                                Close
                            </Button>
                            <Button color="danger" isDisabled={inputText === ''} onClick={() => submitHandler(onClose)}>
                                Submit Feedback
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    )
}

export default Feedback;