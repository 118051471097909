import React, { useState, useEffect } from 'react';
import { Button } from "@nextui-org/react";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import { useParams, useNavigate } from "react-router-dom";
import Forbbiden from "../../components/Forbbiden";
import UnAuthorized from "../../components/UnAuthorized";
import ServerIssue from "../../components/ServerIssue";
import NotFound from "../../components/NotFound";
import { cardSkeleton } from "../../components/Skeleton";
import Cards from "../../components/Cards";
import ViewAllCard from '../../components/ViewAllCard';
import { DatabaseLogo } from '../Connectors/ConnectorData';
import { colorPicker } from '../../controllers/basicControllers';
import { toast } from 'react-hot-toast';

const DataProducts = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [collections, setCollections] = useState([]);
    const [connectors, setConnectors] = useState([]);
    const [tools, setTools] = useState([]);
    const [loader, setLoader] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const LogoData = DatabaseLogo;

    // USER INFO
    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
    const userID = userDetails?.user?.id;

    // API RESPONSE
    useEffect(() => {
        setLoader(true);
        // COLLECTIONS API
        const url = `filters[space][Name][$eq]=${params._spaceid}&filters[Type][$eq]=collections&sort[0]=id:desc&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`
        CommonCollectionFilterApi("collections", url)
            .then((data) => {
                if (data?.error) {
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        toast.error("Something went wrong with upload, please try after some time.")
                    }
                } else if (data.data.length > 0) {
                    setCollections(data.data);
                    setLoader(false);
                } else {
                    setLoader(false);
                }
            })
            .catch((error) => {
                toast.error("Something went wrong with upload, please try after some time.")
            })

        // CONNECTORS API
        const url2 = `filters[space][Name][$eq]=${params._spaceid}&filters[Type][$eq]=connectors&sort[0]=id:desc&[populate][author][on]&[populate][space][on]&&[populate][connector_list][on]&[populate][database][on]`
        CommonCollectionFilterApi("collections", url2)
            .then((data) => setConnectors(data.data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

        // TOOLS API
        const url3 = `filters[space][Name][$eq]=${params._spaceid}&filters[Type][$eq]=tools&sort[0]=id:desc&[populate][author][on]&&[populate][space][on]`
        CommonCollectionFilterApi("collections", url3)
            .then((data) => setTools(data.data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

    }, [params._spaceid])

    // NAVIGATION FOR CREATE 
    const navigateHandler = () => {
        navigate('create')
    }

    // SERVER ERROR CONDITION PAGE RENDER
    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerIssue />;
    if (authorized) return <UnAuthorized message='Authorization error.' />;
    if (forbidden) return <Forbbiden />;

    // RETURN UI
    return (
        <div>
            <h1 className='text-3xl font-medium'>Data Products</h1>
            <div className='mt-4 leading-relaxed'>
                Seamlessly integrate your data sources into your data mesh and unify all your enterprise data products in one environment.
            </div>
            <div className='flex mt-4 pb-8 justify-end'>
                <Button color='danger' onClick={navigateHandler}>
                    + Create a data product
                </Button>
            </div>
            <div className='flex flex-col gap-8'>
                <div>
                    <h1 className='text-xl font-medium border-b pb-2 border-divider'>Collections</h1>
                    <p className='pt-2'>
                        Effortlessly connect and integrate diverse data sources with our connectors for seamless, consistent data flow across your organization.
                    </p>


                    {loader ? cardSkeleton() : <>
                        {collections && collections.length > 0 ? (
                            <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >
                                <>
                                    {collections.slice(0, 2).map((data, index) => {
                                        const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
                                        const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
                                        const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)
                                        const colorIndex = colorPicker(data.id);
                                        return <div key={`${index}`} className=' ' onClick={() => navigate(`/${params._spaceid}/collections/${data.id}`)}>
                                            <Cards
                                                data={data}
                                                MurgedUsers={MurgedUsers}
                                                colorIndex={colorIndex}
                                                darkMode={darkMode}
                                                EditOpen={""}
                                                userId={userID}
                                                DeleteWarningHandle={""}
                                                ActionVisible={false}
                                                Logo={""}
                                            />
                                        </div>
                                    })}
                                    {collections?.length > 2 &&
                                        <div onClick={() => navigate(`/${params._spaceid}/collections`)}>
                                            <ViewAllCard
                                                title={"collections"}
                                                params={params}
                                                icon={<lord-icon
                                                    src="https://cdn.lordicon.com/jkzgajyr.json"
                                                    trigger="hover"
                                                    target="#Collections"
                                                    state="morph-home-2"
                                                    colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                    style={{ width: "16px", height: "16px" }}>
                                                </lord-icon>}
                                                id="Collections" />
                                        </div>}
                                </>
                            </div>) :
                            <div className='italic font-medium pt-8 flex text-sm justify-center text-coolGray-400'> You do not have any collections inside your data mesh.</div>}
                    </>}
                </div>
                <div>
                    <h1 className='text-xl font-medium border-b pb-2 border-divider'>Connectors</h1>
                    <p className='pt-2'>
                        Connect and sync structured data across databases effortlessly with our advanced connectors, ensuring seamless integration and consistency in your data management.
                    </p>


                    {loader ? cardSkeleton() : <>
                        {connectors && connectors.length > 0 ? (
                            <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >
                                <>
                                    {connectors?.slice(0, 2).map((data, index) => {
                                        const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
                                        const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
                                        const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)
                                        const colorIndex = colorPicker(data.id);
                                        const logo = data?.attributes?.database?.data !== null ? LogoData.find((item) => item.label === data?.attributes?.database?.data?.attributes?.Database) : '--';
                                        return <div key={`${index}`} className=' ' onClick={() => navigate(`/${params._spaceid}/connectors/${data.id}`)}>
                                            <Cards
                                                data={data}
                                                MurgedUsers={MurgedUsers}
                                                colorIndex={colorIndex}
                                                darkMode={darkMode}
                                                EditOpen={""}
                                                userId={userID}
                                                DeleteWarningHandle={""}
                                                Logo={logo?.avatar}
                                                ActionVisible={false}
                                            />
                                        </div>
                                    })}
                                    {connectors?.length > 2 &&
                                        <div onClick={() => navigate(`/${params._spaceid}/connectors`)}>
                                            <ViewAllCard
                                                title={"connectors"}
                                                params={params}
                                                icon={<lord-icon
                                                    src="https://cdn.lordicon.com/jzjtyyqx.json"
                                                    trigger="hover"
                                                    target="#Connectors"
                                                    state="morph-home-2s"
                                                    colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                    style={{ width: "16px", height: "16px" }}>
                                                </lord-icon>}
                                                id="Connectors" />
                                        </div>}
                                </>
                            </div>) :
                            <div className='italic font-medium pt-8 flex text-sm justify-center text-coolGray-400'> You do not have any connectors inside your data mesh.</div>}
                    </>}
                </div>
                <div>
                    <h1 className='text-xl font-medium  border-b pb-2 border-divider'>Tools</h1>
                    <p className='pt-2'>
                        Seamlessly connect third-party applications to your data mesh with our tools, enhancing integration and data flow across your ecosystem.
                    </p>

                    {loader ? cardSkeleton() : <>
                        {tools && tools.length > 0 ? (
                            <div className='grid md:grid-cols-2 gap-8 pt-8 sm:grid-cols-1 lg:grid-cols-3' >
                                <>
                                    {tools.slice(0, 2).map((data, index) => {
                                        const InvitedUsers = data?.attributes?.inviteds?.data?.length > 0 ? data.attributes.inviteds.data : [];
                                        const ViewedUsers = data?.attributes?.viewed?.data?.length > 0 ? data.attributes.viewed.data : [];
                                        const MurgedUsers = ViewedUsers && ViewedUsers.concat(InvitedUsers && InvitedUsers)
                                        const colorIndex = colorPicker(data.id);
                                        return <div key={`${index}`} className=' ' onClick={() => navigate(`/${params._spaceid}/tools/${data.id}`)}>
                                            <Cards
                                                data={data}
                                                MurgedUsers={MurgedUsers}
                                                colorIndex={colorIndex}
                                                darkMode={darkMode}
                                                EditOpen={""}
                                                userId={userID}
                                                DeleteWarningHandle={""}
                                                ActionVisible={false}
                                                Logo={""} />
                                        </div>
                                    })}
                                    {tools?.length > 2 &&
                                        <div onClick={() => navigate(`/${params._spaceid}/tools`)}>
                                            <ViewAllCard
                                                title={"tools"}
                                                params={params}
                                                icon={<lord-icon
                                                    src="https://cdn.lordicon.com/pflszboa.json"
                                                    trigger="hover"
                                                    target="#Tool"
                                                    state="morph-home-2"
                                                    colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                    style={{ width: "16px", height: "16px" }}>
                                                </lord-icon>}
                                                id="Tool" />
                                        </div>}
                                </>
                            </div>) :
                            <div className='italic font-medium pt-8 flex text-sm justify-center text-coolGray-400'>
                                You do not have any Tools inside your data mesh.
                            </div>}
                    </>}
                </div>
            </div>
        </div>
    )
}

export default DataProducts;