import React, { useState, useEffect } from 'react';
import { Image, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar, useDisclosure } from "@nextui-org/react";
import { getOneData } from "../controllers/strapiController";
import { NameCreater, colorPicker } from "../controllers/basicControllers";
import { useNavigate, useLocation } from "react-router-dom";
import { SubNavbarItems } from "../components/NavbarItems";
import { Outlet } from "react-router-dom";
import Feedback from './Feedback';
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../redux/actions";

const SubNavbar = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();
    const Location = useLocation()
    const navbarItem = SubNavbarItems(darkMode);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
    const userID = userDetails && userDetails.user && userDetails.user.id;
    const dispatch = useDispatch();
    const data = useSelector(state => state);
    const indexData = data && JSON.stringify(data, null, 2);
    const convertedData = JSON.parse(indexData);
    const URLPath = Location.pathname.split("/");
    const [inputText, setInputText] = useState('');

    // NAVIGATION CONDITION
    useEffect(() => {
        if (userDetails === null) {
            navigate('/sign-in');
        }
    }, [])

    // REDUX UPDATE
    useEffect(() => {
        getOneData('users', userID)
            .then((data) => { dispatch(setData(data)); setUserInfo(data) })
    }, [userID])

    // USERNAME RENDER
    const NameFormat = userInfo && NameCreater(userInfo.firstname, userInfo.email);

    const handleChange = () => {
        onOpen();
        setInputText('')
    }

    // RETURN UI
    return (
        <div className='flex flex-col max-w-6xl  mx-auto'>
            <div className={`flex flex-row justify-between fixed left-0 right-0 ${darkMode.value === true ? "bg-black" : "bg-white"} z-50  pt-8 max-w-6xl mx-auto`}>
                <Image
                    width={50}
                    alt="NextUI hero Image"
                    src="Assets/haya-logo.png"
                    className='cursor-pointer'
                    onClick={() => navigate("/")}
                />
                <Dropdown placement="bottom-end">
                    <DropdownTrigger>
                        <Avatar
                            isBordered
                            as="button"
                            color={colorPicker(convertedData?.data?.id)}
                            className="transition-transform"
                            name={NameFormat}
                            src={convertedData?.data?.Picture !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${convertedData?.data?.Picture?.url}` : `${convertedData?.data?.Picture?.url}`}` : ""}
                        />
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Profile Actions" variant="flat">
                        <DropdownItem key="profile" className="h-14 gap-2">
                            <p className="font-semibold">Signed in as</p>
                            <p className="font-semibold">{userInfo && userInfo.email}</p>
                        </DropdownItem>
                        {navbarItem && navbarItem.map((data) => {
                            const Verified = `/${URLPath[1]}` === data.path;
                            return <DropdownItem color={data.title === "Logout" ? "danger" : "default"} className={`${Verified ? (darkMode.value === false ? 'text-foreground bg-grey-200' : 'text-foreground bg-grey-200') : ''}`} key={data.id} onClick={() => { data.title === "Help & Feedback" ? handleChange() : navigate(data.path) }}>
                                <div className='flex flex-row gap-1 items-center' id={data.id}>
                                    {data.icon}
                                    <span>{data.title}</span>
                                </div>
                            </DropdownItem>
                        })}
                    </DropdownMenu>
                </Dropdown>
            </div>
            <Feedback isOpen={isOpen} onOpenChange={onOpenChange} inputText={inputText} setInputText={setInputText} />
            <div className='pt-12'>
                <Outlet />
            </div>
        </div>
    )
}

export default SubNavbar;