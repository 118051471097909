import React, { useState, useEffect } from 'react';
import { Avatar, Button, Chip, Input, Select, SelectItem, Textarea, Switch } from '@nextui-org/react';
import { CommonCollectionFilterApi, userLoginDetails } from '../../controllers/strapiController'
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import { DBErrorHandler } from "../../Utils/DBErrorHandler";
import Tags from "../../components/Tags";
import DBSubmit from './Database/DBSubmit';
import { useSelector } from "react-redux";



const initialState = { "Name": "", "Context": "", "Domain": "", "isPublic": false };

const CreateProduct = ({ darkMode, formData, onNext, setFormData, onPrevious, selectProduct }) => {
    // STATE INITIALIZATION
    const params = useParams();
    const [formValues, setFormValues] = useState(initialState);
    const [validator, setValidator] = useState(null);
    const [tagsData, setTagsData] = useState();
    const [tagsdata, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [validatetags, setValidatetags] = useState('');
    const userId = userLoginDetails()?.userId;
    const spaceData = useSelector(state => state);
    const indexData = spaceData && JSON.stringify(spaceData, null, 2);
    const convertedData = JSON.parse(indexData);
    const navigate = useNavigate();


    // PARTICULAR PRODUCT SELECT TO CREATE
    const tags = selectProduct?.attributes?.Tags?.tags;
    const [domains, setDomains] = useState([]) // Initialize as an empty array
    const collectionname = "domains"

    useEffect(() => {
        const url = `filters[space][Name][$eq]=${params._spaceid}&[populate][author][on]&[populate][inviteds][on]&[populate][viewed][on]&[populate][requests][on]&[populate][teams][on]&[populate][space][on]`
        CommonCollectionFilterApi(collectionname, url)
            .then((data) => { setDomains(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

        setFormValues({
            Name: formData?.inputFields?.Name || "",
            Context: formData?.inputFields?.Context || "",
            Domain: formData?.inputFields?.Domain?.connect?.[0]?.id || "",
            isPublic: formData?.inputFields?.isPublic || false
        })
        setTags(formData?.Tags)
    }, [])

    // Handle input change to update state
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === "Domain") {
            if (value) {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    [name]: {
                        disconnect: [],
                        connect: [{ id: parseInt(value), position: { end: true } }]
                    },
                }));
            } else {
                setFormValues((prevValues) => ({
                    ...prevValues,
                    [name]: "",
                }));
            }
        } else if (name === "isPublic") {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: event?.target?.checked,
            }));
        } else {
            setFormValues((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }
    };


    // TAGS FILTER DROPDOWN
    const FilteredUser = tagsData?.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userId)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags ? FilteredUser?.[0]?.attributes?.Tags?.tags : [];
    // FILTER FOR INPUT TEXT RELATED TO TAGS
    const FilteredText = tagInput !== '' ? TagsLength?.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

    // FINAL DISPLAY TAGS
    const FilteredTags = FilteredText?.length > 0 ? FilteredText : []

    const TagsRender = formData?.Tags && TagsLength && formData.Tags.filter((data) => !TagsLength.includes(data));
    const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
    const TagsID = FilteredUser?.[0]?.id;

    const TagsPayload = {
        Tags: { tags: NewRenderation }
    }

    const createTagspayload = {

        users_permissions_user: {
            disconnect: [],
            connect: [{ id: userId, position: { end: true } }]
        },
        Tags: { "tags": formData?.Tags }
    }

    useEffect(() => {
        if (formData?.Tags?.length > 0) {
            if (FilteredUser?.length > 0) {
                setFormData(prev => ({
                    ...prev,
                    inputFields: { ...formValues, tagsFunction: { func: "update", tagId: TagsID, payload: TagsPayload } }
                }))
            } else {
                setFormData(prev => ({
                    ...prev,
                    inputFields: { ...formValues, tagsFunction: { func: "create", payload: createTagspayload } }
                }))
            }
        } else {
            setFormData(prev => ({
                ...prev,
                inputFields: { ...formValues }
            }))
        }

    }, [formValues, formData?.Tags])


    const handleSubmit = async () => {
        const validator = DBErrorHandler(formValues);

        // Only log properties that can be serialized
        if (validator?.isValid) {
            if (selectProduct?.attributes?.Schema !== null) {
                onNext();
                setValidator(false);
            } else {
                const response = await DBSubmit(formData, convertedData, userId);
                if (response?.status === "200") {
                    navigate(`/${convertedData?.space?.[0]?.attributes?.Name}/${response?.response?.data?.attributes?.Type}/${response?.response?.data?.id}`)
                }
            }
        }
        else {
            setValidator(validator);
        }
    };


    return (
        <>
            <div className='pb-8'>
                <h1 className='text-3xl font-medium pb-4 gap-4 flex items-center'>
                    <Avatar src={selectProduct.attributes.Image} radius='md' size="md" />
                    {selectProduct.attributes.Name}
                </h1>
                {tags && tags.length > 0 ?
                    <div className='flex gap-2'>
                        {tags.map((item, index) => (
                            <div key={index}>
                                <Chip variant='flat' className='mb-8' color='secondary'>{item}</Chip>
                            </div>
                        ))}
                    </div>
                    : null}
                <p>
                    {selectProduct.attributes.Description}
                </p>
                <div className='flex flex-col gap-8 pt-8'>
                    <div >
                        <Input
                            label="Name of the data product"
                            placeholder='Give a name to your data product'
                            labelPlacement='outside'
                            name="Name"
                            value={formValues.Name}
                            onChange={handleInputChange}
                            isRequired
                            errorMessage={validator?.errors?.Name}
                            isInvalid={formValues.Name === "" && !!validator?.errors?.Name}
                        />
                    </div>
                    <div >
                        <Textarea
                            label="Enter context to your data product"
                            placeholder='Give context to your data product'
                            labelPlacement='outside'
                            name="Context"
                            value={formValues?.Context}
                            onChange={handleInputChange}
                            isRequired
                            errorMessage={validator?.errors?.Context}
                            isInvalid={formValues.Context === "" && !!validator?.errors?.Context}
                        />
                    </div>
                    {selectProduct?.attributes?.Type === "collections" && <div className='flex flex-col gap-1'>
                        <div className='flex gap-2 flex-col'>
                            <p className='text-sm'>Make collection public</p>
                            <Switch isSelected={formValues?.isPublic} name="isPublic" onChange={handleInputChange} size='sm' color="secondary"></Switch>
                        </div>
                        {formData.isPublic ? <p className='text-xs text-slate-400'>By making your collection public anyone inside the space can view and upload your documents.</p> : <></>}
                    </div>}
                    <div>
                        <Select
                            label="Domain"
                            placeholder='Attach your data product to a domain'
                            labelPlacement='outside'
                            name="Domain"
                            defaultSelectedKeys={[formData?.inputFields?.Domain?.connect?.[0]?.id]}
                            value={formValues?.Domain}
                            onChange={handleInputChange} // Update formValues on change
                            isRequired
                            errorMessage={validator?.errors?.Domain}
                            isInvalid={formValues.Domain === "" && !!validator?.errors?.Domain}
                        >
                            {domains?.map((item) => (
                                <SelectItem key={item?.id} value={item?.id}>
                                    {item?.attributes?.Name}
                                </SelectItem>
                            ))}
                        </Select>
                    </div>
                    <div className='-mt-4'>
                        <Tags
                            darkMode={darkMode}
                            tagsData={tagsData}
                            setTagsData={setTagsData}
                            tagInput={tagInput}
                            setTagInput={setTagInput}
                            tags={tagsdata}
                            validatetags={validatetags}
                            setTags={setTags}
                            setValidatetags={setValidatetags}
                            setFormdata={setFormData}
                            formData={formData}
                            background={"bg-transparent"}
                        />
                    </div>
                </div>
                <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
                    <Button variant="light" onClick={onPrevious}>Back</Button>
                    <Button variant="flat" color="secondary" isDisabled={!formData.type} onClick={handleSubmit}>Next</Button>
                </div>
            </div>
        </>
    );
};

export default CreateProduct;
