import React, { useState, useEffect } from 'react';
import { Button, useDisclosure } from "@nextui-org/react";
import { UpdateData, CommonCollectionFilterApi, userLoginDetails, getAllData, CreateData, DeleteSingleAttribute } from "../../controllers/strapiController";
import { useNavigate } from "react-router-dom";
import CreateConnector from "../DataProducts/CreateOptions/CreateConnector";
import UnStructuredDB from '../DataProducts/Database/UnStructuredDB';
import { formSkeleton } from '../../components/Skeleton';
import { toast } from "react-hot-toast";
import DeleteModal from "../../components/DeleteModal";
import Dangerzone from '../../components/Dangerzone';

const ConnectorSettings = ({ darkMode, connectorData, loader, setReload }) => {
    // STATE INITIALIZATION
    const navigate = useNavigate();
    const [validationCondition, setValidationCondition] = useState(false);
    const [formData, setFormData] = useState({ Name: '', Context: '', Tags: '', ConnectionJSON: '' })
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
    const [formError, setFormError] = useState({ NameError: '', ContextError: '' });
    const [JsonData, setJsonData] = useState();
    const [databaseType, setDatabaseType] = useState([]);
    const [JsonDataError, setJsonDataError] = useState();
    const [tagInput, setTagInput] = useState('');
    const [tagsData, setTagsData] = useState();
    const userdata = userLoginDetails().userId;

    // DATABASE API RESPONSE RENDERING
    useEffect(() => {
        const url = `filters[Type][$eq]=structured`;
        CommonCollectionFilterApi("databases", url)
            .then((data) => { setDatabaseType(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

        getAllData("tags")
            .then((data) => {
                setTagsData(data.data)
                // setLoader(false);
            })
    }, [])

    //   STATE UPDATE
    useEffect(() => {
        if (connectorData) {
            setFormData({
                Name: connectorData?.attributes?.Name,
                Context: connectorData?.attributes?.Description,
                Tags: connectorData?.attributes?.Tags?.tags,
                ConnectionJSON: connectorData?.attributes?.ConnectionJSON,
                database: connectorData?.attributes?.database?.data?.id
            })
        }
    }, [connectorData])

    // FILTER DATABASE
    const filteredJSONForm = databaseType?.find((data) => JSON.stringify(data.id) === JSON.stringify(formData.database));
    // TAGS FILTER DROPDOWN
    const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userdata)
    // TAGS PRESENTS
    const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
    // FILTER FOR INPUT TEXT RELATED TO TAGS
    const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

    // FINAL DISPLAY TAGS
    const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

    const TagsRender = formData.Tags && formData.Tags !== null && TagsLength && formData.Tags.filter((data) => !TagsLength.includes(data));
    const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
    const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

    // VALIDATIONS
    const Validation = () => {
        let errors = {}
        let IsValid = true;

        if (!formData.Name) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
        } else {
            setFormError((previous) => ({ ...previous, NameError: null }))
        }

        if (!formData.Context) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, ContextError: "Context is required." }))
        } else {
            setFormError((previous) => ({ ...previous, ContextError: null }))
        }

        filteredJSONForm?.attributes?.JSON_Form_Schema?.properties?.forEach((data) => {
            if (!JsonData[data.name]) {
                IsValid = false;
                errors[data.name] = `${data.name} is required.`
            }
        })

        setJsonDataError(errors);
        return IsValid;
    }

    // UPDATE CONNECTOR HANDLER
    const updateHandler = async () => {
        const validate = Validation();
        if (validate) {
            setValidationCondition(false);
            const payload = {
                Name: formData.Name,
                Description: formData.Context,
                Tags: { tags: formData.Tags },
                ConnectionJSON: { properties: JsonData }
            }
            const TagsPayload = {
                Tags: { tags: NewRenderation }
            }
            const response = await UpdateData("collections", connectorData?.id, payload)
            if (response) {
                if (FilteredUser && FilteredUser.length > 0) {
                    await UpdateData("tags", TagsID, TagsPayload)
                    if (response) {
                        toast.success("Connector updated successfully.");
                        setReload(response);
                    }
                }
                if (FilteredUser && FilteredUser.length === 0) {
                    const payload = {
                        users_permissions_user: {
                            disconnect: [],
                            connect: [{ id: userdata, position: { end: true } }]
                        },
                        Tags: { "tags": formData.Tags }
                    }
                    await CreateData("tags", payload);
                }
            }
            if (response) {
                await UpdateData("tags", TagsID, TagsPayload)

            }
        } else {
            setValidationCondition(true);
        }
    }

    // REVERT BACK TO OLD CHANGES
    const resetHandler = () => {
        setFormData({
            Name: connectorData?.attributes?.Name,
            Context: connectorData?.attributes?.Context,
            Tags: connectorData?.attributes?.Tags?.tags,
            ConnectionJSON: connectorData?.attributes?.ConnectionJSON,
            database: connectorData?.attributes?.database?.data?.id
        })
        setJsonData(connectorData?.attributes?.ConnectionJSON?.properties)
    }

    // DELETE HANDLER TO DELETE COLLECTION
    const deleteHandler = async () => {
        const response = await DeleteSingleAttribute("collections", connectorData?.id)
        if (response) {
            navigate(-1)
        }
    }

    if (loader) {
        return formSkeleton();
    }
    return (
        <div>
            <CreateConnector
                darkMode={darkMode}
                validationCondition={validationCondition}
                setValidationCondition={setValidationCondition}
                formError={formError}
                setFormError={setFormError}
                ButtonVisible={false}
                tagInput={tagInput}
                setTagInput={setTagInput}
                connectorData={formData}
                setConnectorData={setFormData} />
            <div className='pt-4'>
                <UnStructuredDB
                    darkMode={darkMode}
                    defaultValue={formData}
                    ButtonVisible={false}
                    setJsonData={setJsonData}
                    JsonData={JsonData}
                    setValidationCondition={setValidationCondition}
                    validationCondition={validationCondition}
                    JsonDataError={JsonDataError}
                    setJsonDataError={setJsonDataError}
                    Disabled={connectorData?.attributes?.database?.data?.id ? true : false} />
            </div>
            <div className='flex flex-row items-end justify-end pt-8 gap-4'>
                <Button onClick={resetHandler}>Cancel</Button>
                <Button color='secondary' onClick={updateHandler}>Submit</Button>
            </div>
            <div className='pt-8'>
                <Dangerzone collection={connectorData?.attributes?.Name} onDeleteOpen={onDeleteOpen} />
            </div>
            <DeleteModal
                isOpen={isDeleteOpen}
                onOpenChange={onDeleteOpenChange}
                deleteData={""}
                setDeleteData={""}
                Collectionname={connectorData?.attributes?.Name}
                setLoader={""}
                DeleteHandler={deleteHandler}
            />
        </div>
    )
}

export default ConnectorSettings;