import React, { useState, useEffect } from 'react'
import { Input, Button } from "@nextui-org/react";
import { test_schema } from '../../../controllers/vectordbController';
import { convertKeysToLowercase } from '../../../controllers/basicControllers';
import { userLoginDetails } from '../../../controllers/strapiController';
import { toast, Toaster } from "react-hot-toast";
import ButtonSpinner from '../../../components/Loader/ButtonSpinner';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createDataproduct } from '../../../controllers/dataProducts';

const DB_Selector = ({ darkMode, formData, setFormData, onPrevious, onNext, selectProduct }) => {
    const [JsonData, setJsonData] = useState();
    const [formDB, setFormDB] = useState();
    const [errors, setErrors] = useState({});
    const [buttonLoad, setButtonLoad] = useState(false);
    const userId = userLoginDetails()?.userId;
    const spaceData = useSelector(state => state);
    const indexData = spaceData && JSON.stringify(spaceData, null, 2);
    const convertedData = JSON.parse(indexData);
    const navigate = useNavigate();

    useEffect(() => {
        // Update jsonData state and log it when selectProduct changes
        const data = selectProduct?.attributes?.Schema;
        setJsonData(data);
        if (!formData?.DBJson) {

            data?.properties?.map((data) => (
                setFormDB(prev => ({
                    ...prev,
                    [data?.name]: ''
                }))
            ))
        } else {
            setFormDB(formData?.DBJson)
        }
    }, [selectProduct]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormDB((prev) => ({
            ...prev,
            [name]: value,
        }));
        // Clear error on input change
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    const validateField = (field, value) => {
        let error = '';
        if (!value) {
            error = `${field.name} is required`;
        }
        return error;
    }

    const validation = () => {
        let isValid = true;
        const newErrors = {};

        JsonData?.properties?.forEach((field) => {
            const error = validateField(field, formDB[field.name]);
            if (error) {
                newErrors[field.name] = error;
                isValid = false;
            }
        });
        setErrors(newErrors);
        return isValid;
    }

    const handleSubmit = async () => {
        const validate = validation();
        if (validate) {
            setButtonLoad(true)
            if (selectProduct?.attributes.Type === "connectors") {
                const ConnectionPayload = {
                    source: selectProduct?.attributes.Name,
                    ...formDB
                };
                const ConnectionPayloadLowerCase = convertKeysToLowercase(ConnectionPayload);
                const response = await test_schema(ConnectionPayloadLowerCase)

                if (response?.tables) {
                    setButtonLoad(false);
                    setFormData(prev => ({
                        ...prev,
                        DBJson: formDB
                    }));
                    onNext();
                } else {
                    setButtonLoad(false);
                    toast.error("Incorrect database credentials.")
                }
            }
            else {
                const data = { ...formData, DBJson: formDB }
                const response = await createDataproduct(data, convertedData, userId)
                if (response?.status === "200") {
                    setButtonLoad(false);
                    navigate(`/${convertedData?.space?.[0]?.attributes?.Name}/${response?.response?.data?.attributes?.Type}/${response?.response?.data?.id}`)
                }
            }
        } else {
            setButtonLoad(false);
        }
    }

    const formCreater = () => {
        return (
            <div className='flex flex-col gap-8 pt-8'>
                {JsonData?.properties?.map((data) => (
                    <Input
                        isRequired
                        key={data.name} // Ensure unique key for each input
                        type="text"
                        htmlFor={data.name}
                        name={data.name}
                        value={formDB?.[data.name] || ''}
                        onChange={handleInputChange} 
                        label={data.name}
                        labelPlacement="outside"
                        placeholder={`Enter a ${data.name}`}
                        errorMessage={errors?.[data.name]} // Show error message
                        isInvalid={!!errors?.[data.name]}
                    />
                ))}
            </div>
        );
    };

    return <div>
        <Toaster position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                style: {
                    boxShadow: "none",
                    border: "1px solid #bdb5b5"
                },
                error: { duration: 6000 }
            }} />
        <div className='pb-8'>
            <h1 className='text-3xl font-medium pb-4'>{selectProduct?.attributes?.Type === "tools" ? `Integrate ${selectProduct?.attributes?.Name} to ${convertedData?.space?.[0]?.attributes?.SpaceName}` : "Connect to your database"}</h1>
            <p>Connect to your database by providing your database credentials</p>
        </div>
        {formCreater()}
        <div className="pt-8 pr-2 flex flex-row gap-4 justify-end">
            <Button variant="light" onClick={onPrevious}>Back</Button>
            <Button isLoading={buttonLoad} color="danger" spinner={buttonLoad ? ButtonSpinner() : ""} isDisabled={!formData.type} onClick={handleSubmit}>Next</Button>
        </div>
    </div>

}
export default DB_Selector;