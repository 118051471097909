import React from 'react';
import {Card} from "@nextui-org/react";

const ViewAllCard = ({title,params,icon,id}) => {

  return (
    <Card id={id} className="max-w-[340px] min-w-[340px] min-h-[240px] h-full p-2 border border-divider cursor-pointer" shadow='none'>
        <div className='flex flex-col justify-center items-center h-full w-full'>
          {icon}
        <h1>View all</h1>
        <h1> {title}</h1>
        </div>
    </Card>

  )
}

export default ViewAllCard;