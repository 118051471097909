import React, { useState, useEffect } from 'react';
import { Select, SelectItem, Button, Input } from "@nextui-org/react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { CommonCollectionFilterApi, CreateData, UpdateData } from "../../../controllers/strapiController";

const UnStructuredDB = ({ darkMode, JsonData, JsonDataError, setJsonDataError, setJsonData, validationCondition, setValidationCondition, formData, setFormData, onPrevious, onNext, ButtonVisible, defaultValue, Disabled }) => {
  // STATE INITIALIXZATION
  const [databaseType, setDatabaseType] = useState([]);
  const [value, setValue] = useState();
  const collectionname = 'databases';
  const params = useParams();
  const navigate = useNavigate();

  //DATABASE API RESPONSE RENDERING
  useEffect(() => {
    const url = `filters[Type][$eq]=structured`;
    CommonCollectionFilterApi(collectionname, url)
      .then((data) => { setDatabaseType(data.data) })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

    const initialValues = {};
    filteredJSONForm?.attributes?.JSON_Form_Schema?.properties?.forEach((field) => {
      initialValues[field.name] = ''; // Set default value here (e.g., empty string or predefined value)
    });
    setJsonData(initialValues);
  }, [value])

  // DEFAULT SELECT KEY UPDATE
  useEffect(() => {
    if (defaultValue.database) {
      setValue(`${defaultValue.database}`)
    } else {
      setValue('');
    }
  }, [defaultValue])

  // DATABASE FILTER
  const filteredJSONForm = databaseType?.find((data) => JSON.stringify(data.id) === value)

  // INITIAL VALUES SET STATE
  useEffect(() => {
    if (filteredJSONForm) {
      if (ButtonVisible) {
        const initialValues = {};
        filteredJSONForm.attributes?.JSON_Form_Schema?.properties?.forEach((field) => {
          initialValues[field.name] = ''; // Set default value here (e.g., empty string or predefined value)
        });
        setJsonData(initialValues);
      } else if (defaultValue.ConnectionJSON.properties) {
        const initialValues = {};
        filteredJSONForm.attributes?.JSON_Form_Schema?.properties?.forEach((field) => {
          initialValues[field.name] = defaultValue.ConnectionJSON.properties[field.name]; // Set default value here (e.g., empty string or predefined value)
        });
        setJsonData(initialValues);
      }
    }
  }, [databaseType, value]);

  // HANDLE CHANGE FOR DATABASE STRINGS
  const handleChange = (e) => {
    const { name, value } = e.target;
    setJsonData((prev) => ({ ...JsonData, [name]: value }));
  }

  // VALIDATION FOR DATABASE
  const validation = () => {
    let isValid = true;
    let errors = {}

    filteredJSONForm?.attributes?.JSON_Form_Schema?.properties?.forEach((data) => {
      if (!JsonData[data.name]) {
        isValid = false;
        errors[data.name] = `${data.name} is required.`
      }
    })
    setJsonDataError(errors);
    return isValid;
  }

  // DYNAMICALLY FORM RENDERING
  const formCreater = () => {
    return <div className='flex flex-col gap-8 pt-8'>
      {filteredJSONForm?.attributes?.JSON_Form_Schema?.properties?.map((data) => {
        return <Input
          isRequired
          key={data.name}
          type="text"
          htmlFor={data.name}
          name={data.name}
          value={JsonData && JsonData[data.name]}
          isInvalid={JsonData && !JsonData[data.name] && validationCondition ? !JsonData[data.name] || validationCondition : ''}
          errorMessage={JsonData && !JsonData[data.name] && validationCondition ? JsonDataError[data.name] : ""}
          onChange={handleChange}
          label={data.name}
          labelPlacement="outside"
          placeholder={`Enter a ${data.name}`}
        />
      })}
    </div>
  }

  // SUBMIT HANDLER FOR CREATE CONNECTOR
  const submitHandler = async () => {
    const jsonPayload = {
      properties: JsonData
    }

    const database = { disconnect: [], connect: [{ id: value, position: { end: true } }] }

    const validate = validation();
    if (validate) {
      setValidationCondition(false);
      onNext();
      const payload = { ...formData.payload, ConnectionJSON: jsonPayload, database };
      const TagsPayload = formData.tags.payload;
      const TagsID = formData.tags.id;
      const response = await CreateData("connectors", payload)
      if (response) {
        if (formData.tags.func === 'UpdateData') {
          const updateResponse = await UpdateData("tags", TagsID, TagsPayload);
          if (updateResponse) {
            setTimeout(() => {
              navigate(`/${params._spaceid}/connectors/${response.id}`)
            }, 200)
          }
        }
      }
    } else {
      setValidationCondition(true);
    }
  }

  // UI RENDERING
  return (
    <div>
      <Select
        placeholder="Choose database"
        className='mt-8'
        classNames={{ base: "max-w-full" }}
        isDisabled={Disabled}
        selectedKeys={value}
        isInvalid={validationCondition && defaultValue.database === ''}
        errorMessage={'Please select database'}
        defaultSelectedKeys={defaultValue && defaultValue.database && [defaultValue.database]}
        onChange={(e) => { setValue(e.target.value); setFormData((prev) => ({ ...prev, database: e.target.value })) }}
        label="Database"
        labelPlacement="outside"
      >
        {databaseType?.map((data) => {
          return <SelectItem key={data.id} value={data.id} >{data?.attributes?.Database}</SelectItem>
        })}
        <SelectItem key="no database" onClick={() => console.log("I dont have a database")}>I dont have a database</SelectItem>
      </Select>
      {value ?
        <div>{formCreater()}</div>
        : ''}
      {ButtonVisible &&
        <div className='flex flex-row justify-end h-full gap-4 pt-12'>
          <Button onClick={onPrevious}>Previous</Button>
          <Button isDisabled={!value} color='secondary' onClick={submitHandler}>Submit</Button>
        </div>
      }
    </div>
  )
}

export default UnStructuredDB;