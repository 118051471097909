import React, { useEffect, useState } from 'react';
import { Button, Divider, Image, Input, Link } from "@nextui-org/react"
import { EyeSlashFilledIcon } from '../../Images/EyeSlashFilledIcon';
import { EyeFilledIcon } from '../../Images/EyeFilledIcon';
import Github from "../../Images/Github.jsx";
import Google from '../../Images/Google.jsx';
import { CommonCollectionFilterApi } from '../../controllers/strapiController.js';
import { NavigateSign } from '../../controllers/loginController.js';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Loading from '../../components/Loader/Loading.jsx';
import { GitHubAuth, GoogleAuth } from '../../controllers/social.js';
import CryptoJS from "crypto-js";
import ServerIssue from "../../components/ServerIssue";

const Signin = ({ darkMode }) => {
  const [searchParams] = useSearchParams();
  const path = searchParams.get('path');
  // LOGIN WITH GOOGLE
  const handleGoogleAuth = async (e) => {
    e.stopPropagation();
    const data = await GoogleAuth();
    window.location.href = data.urlWithQueryParams;
  }

  // LOGIN WITH GITHUB
  const handleGithubAuth = async (e) => {
    e.stopPropagation();
    const data = await GitHubAuth();
    window.location.href = data.urlWithQueryParams;
  }

  // INITIALIZATION
  const [isVisible, setIsVisible] = React.useState(false);
  const [Users, setUsers] = useState(null);
  const Navigate = useNavigate();
  const [ValidationConditions, setValidationConditions] = useState(false);
  const [decryptedText, setDecryptedText] = useState('');
  const [Loader, setLoader] = useState(true);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const Location = useLocation();
  const [connectionRefused, setConnectonRefused] = useState(false);
  const [LoginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
  });
  const [LoginError, setLoginError] = useState({
    emailError: '',
    passwordError: '',
    FinalError: ''
  });
  // PASSWORD REGEX
  var PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

  // NAVIAGE FOR FORGOT PASSWORD
  const navigate_forgot = () => {
    Navigate('/auth/reset-password')
  }

  // USER API
  useEffect(() => {
    const userEmail = LoginDetails.email.toLowerCase().trim()
    const url = `filters[email][$eq]=${userEmail}`
    CommonCollectionFilterApi('users', url)
      .then((data) => {
        setLoader(false);
        if (data.error) {
          setConnectonRefused(true);
        } else {
          setUsers(data);
          setConnectonRefused(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setConnectonRefused(true);
      })
    if (Location.pathname === "/sign-in") {
      sessionStorage.removeItem("userData");
    }

  }, [LoginDetails.email])

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100) + 1;
  };

  const [randomNumber, setRandomNumber] = useState(generateRandomNumber());

  const secretKey = '';
  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  // DECRYPT PASSWORD
  const handleDecrypt = () => {
    try {
      // const url = window.location.href;
      const id = Users && Users.length > 0 && Users[0].Password2
      const hash = id;
      const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`; // Replace with your actual secret key
      const base64Decoded = atob(hash)
      const decrypted = CryptoJS.AES.decrypt(base64Decoded, secretKey).toString(CryptoJS.enc.Utf8);
      setDecryptedText(decrypted.toString());
    } catch (error) {
      console.error('Decryption error:', error);
      setDecryptedText('Decryption failed');
    }
  };

  useEffect(() => {
    handleDecrypt();
  }, [Users])

  // VERIFYING USER EXISTS OR NOT
  const ComparedPassword = Users && Users.length > 0 && JSON.stringify(decryptedText) !== JSON.stringify(LoginDetails.password)

  // VALIDATION FOR SIGN IN
  const Validation = () => {
    let IsValid = true;

    if (LoginDetails.email === '') {
      IsValid = false;
      setLoginError((previous) => ({ ...previous, emailError: "Email is required." }));
    } else if (!EmailRegex.test(LoginDetails.email)) {
      IsValid = false;
      setLoginError((previous) => ({ ...previous, emailError: "Enter valid email format." }));
    } else {
      setLoginError((previous) => ({ ...previous, emailError: '' }));
    }

    if (LoginDetails.password === '') {
      IsValid = false;
      setLoginError((previous) => ({ ...previous, passwordError: "Password is required." }));
    } else if (LoginDetails.password !== '' && !PasswordRegex.test(LoginDetails.password)) {
      setLoginError((previous) => ({ ...previous, passwordError: "Enter a strong password." }));
    }

    if (ComparedPassword) {
      IsValid = false;
      setLoginError((previous) => ({ ...previous, passwordError: "Password didn't match." }));
      setLoginError((previous) => ({ ...previous, FinalError: null }));
    }
    return IsValid;
  }

  // ENTER KEY TO RUN SUBMIT HANDLER
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      SubmitHandler();
    }
  };

  // SUBMIT HANDLER FOR SIGN IN
  const SubmitHandler = async () => {
    setLoader(true)
    const Validate = Validation();
    if (Validate) {
      setValidationConditions(false);
      const navigate_route = await NavigateSign(LoginDetails.email, LoginDetails.password, path);
      if (navigate_route && navigate_route) {
        setLoader(false);
        Navigate(navigate_route);
      } else {
        setLoader(false);
      }

    } else {
      setValidationConditions(true);
      setLoader(false);
    }
  }

  // PASSWORD EYE FUNCTIONALITY
  const toggleVisibility = () => setIsVisible(!isVisible);

  // LOADER 
  if (Loader) {
    return <div className={`absolute z-[100]  inset-0 h-screen w-screen ${darkMode.value === true ? 'bg-black' : 'bg-slate-50'}`}>
      <Loading darkMode={darkMode} />
    </div>
  }

  // SERVER ERROR
  if (connectionRefused) {
    return <ServerIssue />
  }

  // RETURN UI  
  if (!connectionRefused && Users) {
    return (
      <>
        <div className={`flex h-screen items-center justify-center ${darkMode.value === true ? 'bg-black' : 'bg-slate-50'}`}>
          <main className='mx-auto min-h-[590px] w-full max-w-[450px]'>
            <Image
              width={50}
              alt="NextUI hero Image"
              src="Assets/haya-logo.png"
            />
            <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
              Sign in to Haya Data Mesh
            </h1>
            <p className='pb-8 sm:text-left text-base text-slate-11 font-normal'>
            </p>
            <form data-gtm-form-interact-id="0" onKeyDown={handleKeyPress}>
              {LoginError.FinalError ? <p className='sm:text-left text-base text-slate-11 font-normal'>
                {LoginError.FinalError}
              </p> : ''}
              <div className='mb-4 space-y-2'>
                <Input
                  radius="sm"
                  className='border-slate-6'
                  isInvalid={ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) && LoginDetails.email !== null ? ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) : ""}
                  errorMessage={ValidationConditions && (LoginDetails.email === '' || !EmailRegex.test(LoginDetails.email)) && LoginDetails.email !== null ? LoginError.emailError : ''}
                  key={"outside"}
                  type="email"
                  label="Email"
                  variant={"faded"}
                  onChange={(e) => setLoginDetails({ ...LoginDetails, email: e.target.value })}
                  labelPlacement={"outside"}
                  placeholder="Enter your email"
                />
              </div>
              <div className='mb-4 space-y-2'>
                <Input
                  radius="sm"
                  className='py-4'
                  variant={"faded"}
                  onChange={(e) => setLoginDetails({ ...LoginDetails, password: e.target.value })}
                  isInvalid={
                    ValidationConditions && (
                      LoginDetails.password === '' ||
                      ComparedPassword ||
                      (LoginDetails.password !== '' && !PasswordRegex.test(LoginDetails.password))
                    )
                  }
                  errorMessage={
                    ValidationConditions && (
                      LoginDetails.password === '' ||
                      ComparedPassword ||
                      (LoginDetails.password !== '' && !PasswordRegex.test(LoginDetails.password))
                    ) ? LoginError.passwordError : ''
                  }
                  label={<div className='flex justify-between ' style={{ width: "442px" }}>
                    <span>Password</span>
                    <span className='cursor-pointer' onClick={navigate_forgot}>Forgot your password?</span>
                  </div>}
                  labelPlacement={"outside"}
                  placeholder="Enter your password"
                  endContent={
                    <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                      {isVisible ? (
                        <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      ) : (
                        <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
              </div>
              <Button id='CreateAccount' radius="sm" onClick={SubmitHandler} className={`w-full gap-1 lt-500 ${darkMode.value === true ? 'text-black hover:text-black bg-white' : 'text-white bg-black hover:bg-gray-700 hover:text-white'}`}>
                Sign In
                <lord-icon
                  src="https://cdn.lordicon.com/vduvxizq.json"
                  trigger="hover"
                  target="#CreateAccount"
                  colors={`primary:${darkMode.value === true ? "#000000" : "#ffffff"}`}
                  style={{
                    width: "18px",
                    height: "20px"
                  }}
                />
              </Button>
              <div className='mb-6 mt-6 flex items-center justify-center'>
                <Divider className="my-2" style={{ maxWidth: "44%" }} />
                <span className='text-small text-default-400 px-4'>OR</span>
                <Divider className="my-2" style={{ maxWidth: "44%" }} /></div>
            </form>
            <div className='flex w-full flex-row flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4'>
              <Button radius="sm" variant={"faded"} className='flex gap-1' style={{ width: "48%" }} onClick={handleGithubAuth}>
                <div className='flex items-center gap-2'>
                  <Github />
                  Sign in with GitHub
                </div>
              </Button>
              <Button radius="sm" variant={"faded"} className='flex w-1/2 gap-1 bg' style={{ width: "48%" }} onClick={handleGoogleAuth}>
                <Google />Sign in with Google
              </Button>
            </div>
            <p className='text-small text-default-400 mt-8'>
              By signing in, you agree to our {" "}
              <Link href="#" size='sm'>Terms of Service </Link>
              {" "}and {" "}
              <Link href="#" size='sm'>Privacy Policy </Link>.
            </p>
          </main>
        </div>
      </>
    )
  }
}

export default Signin;