import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { CommonCollectionFilterApi } from '../../controllers/strapiController';
import { Avatar, Chip, Tab, Tabs } from '@nextui-org/react';
import { colorPicker, titleCreater } from '../../controllers/basicControllers';
import DomainSettings from './DomainTabs/DomainSettings';
import DomainDataProd from './DomainTabs/DomainDataProd';

const SingleDomain = ({ darkMode }) => {
    const params = useParams();
    const [domainData, setDomain] = useState(null);
    const [Loader, setLoader] = useState(true);
    const [Response, setResponse] = useState('');
    const CollectionName = "domains";
    const [notFound, setNotFound] = useState(false);
    const [Forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [connectionRefused, setConnectonRefused] = useState(false);
    const UserDetails = JSON.parse(sessionStorage.getItem("userData"));
    const UserID = UserDetails && UserDetails.user && UserDetails.user.id;

    useEffect(() => {
        const url = `filters[id][$in][0]=${params._id}&filters[space][Name][$eq]=${params._spaceid}&[populate][space][on]&[populate][author][on]&[populate][inviteds][on]&[populate][requests][on]&[populate][viewed][on]`
        CommonCollectionFilterApi(CollectionName, url)
            .then((data) => {
                setLoader(false)
                if (data.error) {
                    if (data.error.status !== 404 && data.error.status !== 403) { setServerIssue(true) }
                    if (data.error.status === 404) { setNotFound(true) }
                    if (data.error.status === 403) { setForbidden(true) }
                } else if (data.data.length > 0) {
                    setLoader(false)
                    setDomain(data.data)
                } else {
                    setNotFound(true)
                }
            })
            .catch((error) => { setLoader(false); setConnectonRefused(true) })

    }, [Response, params._id, params._spaceid])

    const Domain = domainData && domainData[0];
    const UserOptions = Domain?.attributes?.author?.data?.id === UserID;

    return (
        <div>
            <div className='text-3xl font-medium flex gap-4 items-center'>
                <Avatar isBordered radius="full" name={Domain?.attributes?.Name.slice(0, 1).toUpperCase()} size="md" color={colorPicker(Domain?.id)} />
                {titleCreater(Domain?.attributes?.Name)}
            </div>
            <div className='mt-8 gap-2 flex flex-wrap'>
                {Domain && Domain?.attributes?.Tags?.tags?.map((tag, index) => (
                    <Chip key={`${index}`} color={colorPicker(Domain?.id)} variant='flat' size='sm'>
                        {tag}
                    </Chip>
                ))}
            </div>
            <div className='mt-4 leading-relaxed '>
                {Domain && Domain?.attributes?.Description}
            </div>
            <div className='w-full'>

                <Tabs aria-label="Options" color={colorPicker(Domain?.id)} variant="underlined" className="pt-8">

                    <Tab key="Data Products" title="Data Products">
                        <DomainDataProd SelectDomain={Domain}  />
                    </Tab>
                    <Tab key="Settings" title="Settings">
                        <DomainSettings darkMode={darkMode} setLoader={setLoader} setReload={setResponse} SelectDomain={Domain} />
                    </Tab>
                </Tabs>
            </div>


        </div>
    )
}

export default SingleDomain