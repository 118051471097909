export const GoogleAuth = async () => {
    const authorizationUrl = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/authorisationurl?rid=thirdparty&thirdPartyId=google&redirectURIOnProviderDashboard=${process.env.REACT_APP_IP_ADDRESS}/authenticate/google`;

    const response = await fetch(authorizationUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            // Add any additional headers if needed
            // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
}


export const getGoogleInfo = async (code) => {

    try {

        // Exchange the authorization code for an access token
        const tokenResponse = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                code,
                client_id: '929256088947-vaebnv0vru5oqiejr84vc7mpeu1kqi4s.apps.googleusercontent.com',
                client_secret: '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',
                redirect_uri: `${process.env.REACT_APP_IP_ADDRESS}/authenticate/google`,
                grant_type: 'authorization_code',
            }),
        });

        if (!tokenResponse.ok) {
            throw new Error(`Token request failed with status ${tokenResponse.status}`);
        }

        const tokenData = await tokenResponse.json();

        if (tokenData.error) {
            throw new Error(`Token request error: ${tokenData.error.message}`);
        }

        const { access_token } = tokenData;

        if (!access_token) {
            throw new Error('Access token is undefined');
        }

        // Use the access token to fetch user details
        const userResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo', {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        const userDetails = await userResponse.json();
        return userDetails;
        // res.send('User details logged in the console.'); // You can customize the response as needed
    } catch (error) {
        console.error('Error:', error.message);
        // res.status(500).send('Error occurred. Check the server logs for details.');
    }
};


//  Github

const github_client_id = "2d005eeb0a7d05821634"
const github_client_secret = "465d8699e656919d3bbaaf5330bc96f64bc89af7"
// Function to get GitHub authorization URL
export const GitHubAuth = async () => {
    const authorizationUrl = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/auth/authorisationurl?rid=thirdparty&thirdPartyId=github&redirectURIOnProviderDashboard=${process.env.REACT_APP_IP_ADDRESS}/authenticate/github`;

    const response = await fetch(authorizationUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            // Add any additional headers if needed
            // Authorization: process.env.REACT_APP_5000_BEARER_TOKEN,
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData;
}
export const getGitHubInfo = async (code) => {
    try {
        // Exchange the authorization code for an access token
        const tokenResponse = await fetch('https://github.com/login/oauth/access_token/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                client_id: github_client_id,
                client_secret: github_client_secret,
                redirect_uri: `${process.env.REACT_APP_IP_ADDRESS}/authenticate/github`,
                grant_type: 'authorization_code',
                code,
            }),
        });

        if (!tokenResponse.ok) {
            throw new Error(`Token request failed with status ${tokenResponse.status}`);
        }

        const tokenData = await tokenResponse.json();

        if (tokenData.error) {
            throw new Error(`Token request error: ${tokenData.error}`);
        }

        const { access_token } = tokenData;

        if (!access_token) {
            throw new Error('Access token is undefined');
        }

        // Use the access token to fetch user details
        const userResponse = await fetch('https://api.github.com/user', {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });

        const userDetails = await userResponse.json();

        return userDetails;
    } catch (error) {
        console.error('GitHub Error:', error.message);
        // Handle the error as per your application's requirement
        throw error;
    }
};
