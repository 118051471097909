import { Avatar } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { getOneData } from '../../controllers/strapiController';
import { colorPicker } from '../../controllers/basicControllers';

const FetchUserDataAndRenderAvatar = ({ userId }) => {
    const [userData, setUserData] = useState(null);

    // RENDER LST OF USERS
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userData = await getOneData("users", userId);
                setUserData(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };
        fetchUserData();
    }, [userId]);


    return userData && userData.Picture ?
        <Avatar size='sm' color={userData && colorPicker(userData.id)} src={`${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userData && userData.Picture && userData.Picture.url}` : `${userData?.Picture?.url}`}`} />
        : <Avatar size='sm' color={userData && colorPicker(userData.id)} name={userData && userData.username && userData.username.slice(0, 1) && userData.username.slice(0, 1).toUpperCase()} />;
};

export default FetchUserDataAndRenderAvatar;
