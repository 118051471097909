import React from 'react';
import { Tabs, Tab, Button, Tooltip, Card } from "@nextui-org/react";
import { iconRender } from "../../controllers/basicControllers";
import { toast } from "react-hot-toast";
import {useParams} from "react-router-dom"

const CurlComponent = ({ darkMode }) => {
    const [selected, setSelected] = React.useState("curl");
    const params=useParams();

    const curlCommand = `
    curl -X POST ${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/ingest
    -H "Content-Type: application/json"
    -H "Authorization: API Key "
    -d '{
        "collection": ${params?._id},
        "app_id":${JSON.stringify(params?._spaceid)},
        "name" : "Name of the file",
        "file_type": "",
        "source":"json",
        "data": {
            "context_data": "Contextual data",
            "meta_data": {
                "meta_data_key_1": "meta_data_value_1",
                "meta_data_key_2": "meta_data_value_2"
            }
        }
    }'`;

    // FUNCTION TO HANDLE THE CLICK AND COPY THE CURL COMMAND
    const copyCurlCode = () => {
        navigator.clipboard.writeText(curlCommand).then(() => {
            // toast.success('Curl command copied to clipboard!');
            toast.custom((t) => (
                <div
                    className={`${t.visible ? 'animate-enter' : 'animate-leave'
                        } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                >
                    <div className="p-2">
                        <p className="mt-1 text-sm text-gray-500">
                            Curl command copied to clipboard!
                        </p>
                    </div>
                </div>
            ))
        }).catch(err => {
            toast.error('Failed to copy text: ', err);
        });
    }

    const formatCurlCommand = (command) => {
        return (
            <pre className="code-container">
                <code>
                    <span className="text-success">curl</span> <span className="text-success">-X POST</span> <span className="text-secondary">http://localhost:8080/ingest</span><br />
                    <span className="text-success">-H</span> <span className="text-warning">"Content-Type"</span> <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>: "application/json"</span><br />
                    <span className="text-success">-H</span> <span className="text-warning">"Authorization"</span> <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>: "API Key"</span><br />
                    <span className="text-success">-d</span> <span className="text-default">{'{'}</span><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"collection"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>{params?._id}</span>,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"app_id"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>{params?._spaceid}</span>,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"name"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>"Name of the file"</span>,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"file_type"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>""</span>,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"source"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>""</span>,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"data"</span>: {'{'}<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"context_data"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>"Contextual data"</span>,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-warning">"meta_data"</span>: {'{'}<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-danger">"meta_data_key_1"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>"meta_data_value_1"</span><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-danger">"meta_data_key_2"</span>: <span className={darkMode?.value === true ? "text-default-600" : "text-default"}>"meta_data_value_2"</span><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;{'}'}<br />
                    {'}'}
                </code>
            </pre>
        );
    };
    return (
        <>
            <Card className='text-background bg-black border border-divider' shadow='none'>
                <div className='flex justify-end relative'>
                    <Tooltip content="Copy" delay={0} closeDelay={0}>
                        <Button isIconOnly color="warning" variant="none" aria-label="" onClick={copyCurlCode}>
                            {iconRender(darkMode, "https://cdn.lordicon.com/vdjwmfqs.json", "Copy")}
                        </Button>
                    </Tooltip>
                </div>
                <Tabs
                    aria-label="Options"
                    className='absolute text-foreground'
                    selectedKey={selected}
                    variant="underlined"
                    onSelectionChange={setSelected}
                    color={"white"}
                >
                    <Tab id="tabsData" key="curl" title="curl" className='px-6 text-default'>
                        {formatCurlCommand(curlCommand)}
                    </Tab>
                </Tabs>
            </Card>
        </>
    )
}

export default CurlComponent;