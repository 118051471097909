import React, { useState, useEffect } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableCell, TableRow, useDisclosure } from '@nextui-org/react';
import { CommonCollectionFilterApi, DeleteSingleAttribute, userLoginDetails } from "../../controllers/strapiController";
import { StringLimit, timeDifference, titleCreater } from "../../controllers/basicControllers";
import { useParams } from "react-router-dom";
import { tableSkeleton } from "../../components/Skeleton";
import DeleteModal from "../../components/DeleteModal";
import DropdownActions from '../../components/DropdownActions';
import EmptyArray from '../../components/EmptyArray';
import { toast } from "react-hot-toast";
import NotFound from "../../components/NotFound";
import ServerError from "../../components/ServerIssue";
import UnAuthorized from "../../components/UnAuthorized";
import Forbbiden from "../../components/Forbbiden";
import Paginate from "../../components/Pagination";

const KeysTable = ({ darkMode, setLoader, loader, setReloader, reload, onApikeyOpen, setFormdata, setKeysAction, setSelectedData }) => {
    //    STATE INITIALIZATION
    const [tools, setTools] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const params = useParams();
    const userId=userLoginDetails()?.userId;
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();

    // API RENSPONSE
    useEffect(() => {
        const url = `filters[space][Name][$eq]=${params._spaceid}&sort[0]=createdAt:desc`
        CommonCollectionFilterApi('keys', url)

            .then((data) => {
                if (data?.error) {
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        toast.error("Something went wrong with collections, please try after some time.")
                    }
                }
                else if (data?.data?.length > 0) { setTools(data.data); setLoader(false) }
                else { setLoader(false) }
            })
            .catch((error) => {
                setTools([]); setLoader(false)
                toast.error("Something went wrong with collections, please try after some time.")
            })
    }, [reload])

    // SORTING THE DATA ACCORDING TO DATE
    const sortedData = tools && tools.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));

    // EDIT KEYS
    const EditOpen = (data) => {
        onApikeyOpen();
        setKeysAction(false);
        setFormdata({
            Name: data.attributes.Name,
            Description: data.attributes.Description
        })
        setSelectedData(data.id)
    }

    // DELETE WARNING MODAL
    const DeleteWarningHandle = (data) => {
        onDeleteOpen();
        setSelectedItem(data);
    }

    // DELETE TOOLS HANDLER
    const DeleteHandler = async () => {

        const response = await DeleteSingleAttribute('keys', selectedItem.id);
        if (response) {
            setReloader(response);
        }
    }

    const recordsPerPage = 10;

    // Calculate indexes for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedData && sortedData.slice(indexOfFirstRecord, indexOfLastRecord);


    // OTHER ERROR PAGES
    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerError />;
    if (authorized) return <UnAuthorized message='Authorization error.' />;
    if (forbidden) return <Forbbiden />;

    return (
        <div>
            {loader ? tableSkeleton() : <>
                {sortedData?.length > 0 ?
                    <Table removeWrapper aria-label="Example static collection table">
                        <TableHeader>
                            <TableColumn>Name</TableColumn>
                            <TableColumn>Description</TableColumn>
                            <TableColumn>Token</TableColumn>
                            <TableColumn>Permissions</TableColumn>
                            <TableColumn>Created At</TableColumn>
                            <TableColumn></TableColumn>
                        </TableHeader>
                        <TableBody>
                            {currentRecords && currentRecords.map((data) => {
                                return <TableRow key="1" className='cursor-pointer hover:bg-grey-200'>
                                    <TableCell>
                                        {titleCreater(data?.attributes?.Name)}
                                    </TableCell>
                                    <TableCell>
                                        {StringLimit(data.attributes.Description, 100)}
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{timeDifference(data.attributes.createdAt)}</TableCell>
                                    <TableCell>
                                        <DropdownActions darkMode={darkMode} EditOpen={() => EditOpen(data)} DeleteWarningHandle={() => DeleteWarningHandle(data)} />
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                    : <EmptyArray message={`No keys created yet. Click on "Create an API key" to create your API key.`} />
                }
                <>
                    {sortedData.length > 10 && <div className='flex justify-center pt-8'>
                        <Paginate
                            totalPage={sortedData}
                            initialPage={"1"}
                            id={userId}
                            currentRecords={currentRecords}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            recordsPerPage={recordsPerPage}
                        />
                    </div>
                    }
                </>
            </>}
            <DeleteModal
                isOpen={isDeleteOpen}
                onOpenChange={onDeleteOpenChange}
                deleteData={""}
                setDeleteData={""}
                Collectionname={"tools"}
                setLoader={setLoader}
                DeleteHandler={DeleteHandler}
            />
        </div>
    )
}

export default KeysTable;