// dynamicImport.js
import NotFound from './components/NotFound';
import Signin from './pages/Signin/Signin';
import GoogleRedirect from './pages/Signin/GoogleRedirect';
import GithubRedirect from './pages/Signin/GithubRedirect';
import CombinedResetPassword from './pages/Signin/CombinedResetPassword';
import Onboarding from './pages/Onboarding/Onboarding';
import Wizard from './pages/CreateSpace/Wizard';
import JoinSpace from './pages/JoinSpace/JoinSpace';
import BuildKB from './pages/Onboarding/BuildKB';
import CreateEnterprise from './pages/Onboarding/CreateEnterprise';
import SubNavbar from './components/SubNavbar';
import Home from './pages/Home/Home';
import AccountSettings from './pages/subPages/AccountSettings';
import Profile from './pages/subPages/Profile';
import DocumentsUploaded from './pages/subPages/DocumentsUploaded';
import Navbar from './components/Navbar';
import KnowledgeBase from './pages/KnowledgeBase/KnowledgeBase';
import Collections from './pages/Collections/Collections';
import DataProducts from './pages/DataProducts/DatabaseWizard';
import CreateDatabase from './pages/DataProducts/CreateDatabase';
import Playground from './pages/Playground/Playground';
import SingleCollection from './pages/Collections/SingleCollection';
import Upload from './pages/Collections/Upload';
import Connectors from './pages/Connectors/Connectors';
import Create from './pages/Connectors/Create';
import Tags from './pages/Tags/Tags';
import Keys from './pages/Keys/Keys';
import Tools from './pages/Tools/Tools';
import ToolsCreate from './pages/Tools/ToolsCreate';
import Extensions from './pages/Extensions/Extensions';
import Analytics from './pages/Analytics/Analytics';
import ExtensionsSlack from './pages/Extensions/Docs/ExtensionsSlack';
import ExtensionsDiscord from './pages/Extensions/Docs/ExtensionsDiscord';
import ExtensionsTeams from './pages/Extensions/Docs/ExtensionsTeams';
import ExtensionsReact from './pages/Extensions/Docs/ExtensionsReact';
import Haya from './pages/Haya/Haya';
import Charts from './pages/Charts/Charts';
import Teams from './pages/Teams/Teams';
import SingleTeam from './pages/Teams/SingleTeam';
import SingleConnector from './pages/Connectors/SingleConnector';
import KnowledgeTree from './pages/KnowledgeTree/KnowledgeTree';
import Archive from './pages/Archive/Archive';
import Members from './pages/Members/Members';
import Documents from './pages/Documents/Documents';
import Search from './pages/Search/Search';
import Trash from './pages/Trash/Trash';
import Settings from './pages/Settings/Settings';
import HayaSettings from './pages/Settings/HayaSettings';
import Test from './pages/Test/Test';
import Templates from "./pages/Templates/Templates";
import Domains from "./pages/Domains/Domains";
import SingleDomain from "./pages/Domains/SingleDomain"
import CreateTemplate from './pages/Templates/CreateTemplate';
import SingleTemplate from './pages/Templates/SingleTemplate';
const components = {

  NotFound,
  Signin,
  GoogleRedirect,
  GithubRedirect,
  CombinedResetPassword,
  Onboarding,
  Wizard,
  JoinSpace,
  BuildKB,
  CreateEnterprise,
  SubNavbar,
  Home,
  AccountSettings,
  Profile,
  DocumentsUploaded,
  Navbar,
  KnowledgeBase,
  Collections,
  DataProducts,
  CreateDatabase,
  Playground,
  SingleCollection,
  Upload,
  Connectors,
  Create,
  Tags,
  Keys,
  Tools,
  ToolsCreate,
  Extensions,
  Analytics,
  ExtensionsSlack,
  ExtensionsDiscord,
  ExtensionsTeams,
  ExtensionsReact,
  Haya,
  Charts,
  Teams,
  SingleTeam,
  SingleConnector,
  KnowledgeTree,
  Archive,
  Members,
  Documents,
  Search,
  Trash,
  Settings,
  HayaSettings,
  Test,
  Templates,
  Domains,
  SingleDomain,
  CreateTemplate,
  SingleTemplate
};

export const getComponent = (name, darkMode) => components[name] || NotFound;
