import React from 'react'
import { Button } from "@nextui-org/react";
import ConnectorTable from './ConnectorTable.jsx';
import { useNavigate, useParams } from 'react-router-dom';


const Connectors = ({ darkMode }) => {
  // STATE INITIALIZATION
  const params = useParams()

  const naivgate = () => {
    navigate(`/${params._spaceid}/create-connector`)
  }

  const navigate = useNavigate()


  return (
    <div>
      <div className='text-3xl font-medium'> Connectors</div>
      <div className='mt-4 leading-relaxed'>
        Efficiently connect and synchronize your structured data across multiple databases using our advanced connectors. This feature streamlines data integration, ensuring seamless access and consistency between different systems, and optimizing your data management and analysis processes.
      </div>
      <div className='flex mt-4 justify-end'>
        <Button color='danger' onClick={naivgate}>
          + Add a connector
        </Button>
      </div>
      <div className='pt-8'>
        <ConnectorTable darkMode={darkMode} />
      </div>
    </div>
  )
}

export default Connectors;