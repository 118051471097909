import { Button, Image, Input } from '@nextui-org/react';
import React, { useState, useEffect } from 'react';
import CryptoJS from "crypto-js";
import { toast } from "react-hot-toast";
import hayalogo from "../../Images/haya-logo.png";
import { CommonCollectionFilterApi, InviteUser } from '../../controllers/strapiController';

const ResetPassword = ({ darkMode }) => {
    // STATE INITIALIZZATION
    const [email, setEmail] = useState('');
    const [validate, setValidate] = useState('');
    const [userData, setUserData] = useState(null);
    const [validationCondition, setValidationCondition] = useState(false);
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // API RENDERING
    useEffect(() => {
        const url = `filters[email][$eqi]=${email}`
        CommonCollectionFilterApi("users", url)
            .then((data) => setUserData(data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [email])

    const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
    const inputString = email;
    const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
    const AESHash = encrypted.toString();

    // VALIDATION FORM
    const validationForm = () => {
        let isValid = true;

        if (email !== '' && !EmailRegex.test(email)) {
            isValid = false;
            setValidate("Enter a valid email.");
        }

        if (EmailRegex.test(email) && userData?.length === 0) {
            isValid = false;
            setValidate("Enter email not exists, register now.");
        }
        return isValid;
    }

    // SUBMIT FUNCTION TO RESET PASSWORD
    const SubmitHandler = async () => {

        const Valid = validationForm();
        if (Valid) {
            setValidationCondition(false);
            const currentTimestamp = Math.floor(Date.now() / 1000);
            const newTimestamp = currentTimestamp + 5 * 60;
            const url = `${process.env.REACT_APP_IP_ADDRESS}/auth/reset-password?email=${btoa(AESHash)}&limit=${newTimestamp}`

            const payload = {
                "to": email,
                "subject": "Invitation from Haya Knowledge.",
                "html": `<html><body><a href="${url}">${url}</a></body></html>`
            };
            toast.success("Password change link sent to your email please ckeck it.")
            setEmail('');
            await InviteUser(payload);
        } else {
            setValidationCondition(true);
        }
    }


    // RETURN UI
    return (
        <>
            <div className={`flex h-screen items-center justify-center ${darkMode.value === true ? 'bg-black' : 'bg-slate-50'}`}>
                <main className='mx-auto min-h-[590px] flex flex-col justify-center w-full max-w-[450px]'>
                    <Image width={50} alt="NextUI hero Image" src={hayalogo} />
                    <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
                        Reset Password
                    </h1>
                    <p className='pb-8 mt-8 sm:text-left text-base text-slate-11 font-normal'>
                        Include the email address associated with your account and we’ll send you an email with instructions to reset your password.
                    </p>
                    <form data-gtm-form-interact-id="0">
                        <div className='mb-4 space-y-2'>
                            <Input
                                radius="sm"
                                className='border-slate-6'
                                key={"outside"}
                                type="email"
                                label="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                isInvalid={validationCondition && (!EmailRegex.test(email) || (EmailRegex.test(email) && userData?.length === 0))}
                                errorMessage={validationCondition && validate}
                                variant={"faded"}
                                labelPlacement={"outside"}
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className='pt-8'>
                            <Button id='CreateAccount' isDisabled={email === ""} radius="sm" onClick={SubmitHandler} className={`w-full gap-1 lt-500 ${darkMode.value === true ? 'text-black hover:text-transporent bg-foreground' : 'text-white bg-black hover:bg-gray-700 hover:text-white'}`}>
                                Send reset instructions
                                <lord-icon
                                    src="https://cdn.lordicon.com/vduvxizq.json"
                                    trigger="hover"
                                    target="#CreateAccount"
                                    colors={`primary:${darkMode.value === true ? "#000000" : "#ffffff"}`}
                                    style={{ width: "18px", height: "20px" }}
                                />
                            </Button>
                        </div>
                    </form>
                </main >
            </div >
        </>
    )
}

export default ResetPassword