import React, { useState, useEffect } from 'react';
import { Button } from "@nextui-org/react";
import { CreateData, CommonCollectionFilterApi, userLoginDetails,getAllData,UpdateData } from "../../controllers/strapiController";
import { useNavigate, useParams } from "react-router-dom";
import CreateConnector from "../DataProducts/CreateOptions/CreateConnector";
import UnStructuredDB from '../DataProducts/Database/UnStructuredDB';
import {useSelector} from 'react-redux';
import {toast} from "react-hot-toast";


const Create = ({ darkMode }) => {
    // STATE INITIALIZATION
    const navigate = useNavigate();
    const [validationCondition, setValidationCondition] = useState(false);
    const [tagInput, setTagInput] = useState('');
    const [formData, setFormData] = useState({ Name: '', Context: '', Tags: [], ConnectionJSON: '',database:'' })
    const [formError, setFormError] = useState({ NameError: '', ContextError: '' });
    const [JsonData, setJsonData] = useState();
    const [databaseType, setDatabaseType] = useState([]);
    const [JsonDataError, setJsonDataError] = useState();
    const [tagsData, setTagsData] = useState();
    const userdata=userLoginDetails().userId;
    const spaceData=useSelector(data=>data);
    const indexData=JSON.stringify(spaceData,null,2);
    const convertedData=JSON.parse(indexData);
    const spaceID=convertedData?.space?.[0]?.id;
    const params=useParams();

    // DATABASE API RESPONSE RENDERING
    useEffect(() => {
        const url = `filters[Type][$eq]=structured`;
        CommonCollectionFilterApi("databases", url)
          .then((data) => { setDatabaseType(data.data) })
          .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

          getAllData("tags")
          .then((data) => {
            setTagsData(data.data)
            // setLoader(false);
          })
      }, [])

 // TAGS FILTER DROPDOWN
 const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userdata)
 // TAGS PRESENTS
 const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
 // FILTER FOR INPUT TEXT RELATED TO TAGS
 const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

 // FINAL DISPLAY TAGS
 const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

const TagsRender = formData.Tags&&formData.Tags !== null && TagsLength && formData.Tags.filter((data) => !TagsLength.includes(data));
const NewRenderation = TagsRender && FilteredTags && TagsRender.concat(FilteredTags);
const TagsID = FilteredUser && FilteredUser[0] && FilteredUser[0].id;

    // FILTER DATABASE
    const filteredJSONForm = databaseType?.find((data) => JSON.stringify(data.id) === formData.database)

    // VALIDATIONS
    const Validation = () => {
        let errors = {}
        let IsValid = true;

        if (!formData.Name) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
        } else {
            setFormError((previous) => ({ ...previous, NameError: null }))
        }

        if (!formData.Context) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, ContextError: "Context is required." }))
        } else {
            setFormError((previous) => ({ ...previous, ContextError: null }))
        }

        if(!formData.database){
            IsValid = false;
        }

        filteredJSONForm?.attributes?.JSON_Form_Schema?.properties?.forEach((data) => {
          if (!JsonData[data.name]) {
            IsValid = false;
            errors[data.name] = `${data.name} is required.`
          }
        })
    
        setJsonDataError(errors);
        return IsValid;
    }
    
    // UPDATE CONNECTOR HANDLER
    const updateHandler = async () => {
        const validate = Validation();
        if (validate) {
            setValidationCondition(false);
            const payload = {
                Name: formData.Name,
                Context: formData.Context,
                Tags: { tags: formData.Tags },
                ConnectionJSON: { properties: JsonData },
                database:{
                    disconnect: [],
                    connect: [{ id: parseInt(formData.database), position: { end: true } }]
                },
                space:{
                    disconnect: [],
                    connect: [{ id: spaceID, position: { end: true } }]
                  },
                  author:{
                    disconnect: [],
                    connect: [{ id: userdata, position: { end: true } }]
                  }
            }

            const TagsPayload = {
                Tags: { tags: NewRenderation }
              }
            const response = await CreateData("connectors", payload)
            if (response) {
                if (FilteredUser && FilteredUser.length > 0) {
                  const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
                  if (response) {
                    navigate(`/${params._spaceid}/connectors`);
                  }
                }
                if (FilteredUser && FilteredUser.length === 0) {
                  const payload = {
                    users_permissions_user: {
                      disconnect: [],
                      connect: [{ id: userdata, position: { end: true } }]
                    },
                    Tags: { "tags": formData.Tags }
                  }
                  const response = await CreateData("tags", payload);
                }
              }
              if (response) {
                const updateResponse = await UpdateData("tags", TagsID, TagsPayload)
                navigate(`/${params._spaceid}/connectors`);
              }
        } else {
            setValidationCondition(true);
        }
    }

    // REVERT BACK TO OLD CHANGES
    const resetHandler = () => {
      navigate(`/${params._spaceid}/connectors`)
    }

    return (
        <div>
            <CreateConnector
                darkMode={darkMode}
                validationCondition={validationCondition}
                setValidationCondition={setValidationCondition}
                formError={formError}
                setFormError={setFormError}
                ButtonVisible={false}
                connectorData={formData}
                setTagInput={setTagInput}
                tagInput={tagInput}
                setConnectorData={setFormData} />
            <div className='pt-4'>
                <UnStructuredDB
                    darkMode={darkMode}
                    defaultValue={formData}
                    ButtonVisible={false}
                    setJsonData={setJsonData}
                    setFormData={setFormData}
                    JsonData={JsonData}
                    setValidationCondition={setValidationCondition}
                    validationCondition={validationCondition} 
                    JsonDataError={JsonDataError}
                    setJsonDataError={setJsonDataError}
                    Disabled={false}/>
            </div>
            <div className='flex flex-row items-end justify-end pt-8 gap-4'>
                <Button onClick={resetHandler}>Cancel</Button>
                <Button color='secondary' onClick={updateHandler}>Submit</Button>
            </div>
        </div>
    )
}

export default Create;