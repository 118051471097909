import React from 'react';
import { CreateData, UpdateData } from '../../../controllers/strapiController';
import { getAllDataProducts } from '../../../controllers/vectordbController'
import {toast} from "react-hot-toast";


 const DBSubmit = async(formData,convertedData,userId,finalJson) => {

    const spaceID = convertedData?.space?.[0]?.id;

    const strapiPayload = {
        Name: formData?.inputFields?.Name,
        Description: formData?.inputFields?.Context,
        domains: formData?.inputFields?.Domain,
        Public: formData?.inputFields?.isPublic||false,
        Type: formData?.dataProduct?.attributes?.Type,
        author: { disconnect: [], connect: [{ id: userId, position: { end: true } }] },
        space: { disconnect: [], connect: [{ id: spaceID, position: { end: true } }] },
        data_product: { disconnect: [], connect: [{ id: parseInt(formData?.type), position: { end: true } }] },
        Tags: { tags: formData?.Tags||[] },
        ConnectionJSON: { properties: formData?.DBJson },
        Additional_Context:{ properties:finalJson}
    }


    const response = await CreateData("collections", strapiPayload);
    if (!response?.error) {
        if (formData?.inputFields?.tagsFunction?.func === "create") {
            await CreateData("tags", formData?.inputFields?.tagsFunction?.payload)
        } else if (formData?.inputFields?.tagsFunction?.func === "update") {
            await UpdateData("tags", formData?.inputFields?.tagsFunction?.tagId, formData?.inputFields?.tagsFunction?.payload)
        }

        const vectorPayload = {
            "app_id": convertedData?.space?.[0]?.attributes?.Name,
            "collection_id": response?.data?.id,
            "data_product": formData?.dataProduct?.attributes?.Name,
            "description": formData?.inputFields?.Context,
            "id": Math.floor(Date.now() / 1000),
            "name": formData?.inputFields?.Name,
            "tags": formData?.Tags,
            "type": response?.data?.attributes?.Type
        }

        const collectionName = "data-products";
        console.log("vectorPayload",vectorPayload)
        await getAllDataProducts(parseInt(response?.data?.id), convertedData?.space?.[0]?.attributes?.Name, collectionName, vectorPayload);

        if( response?.data?.attributes?.Type!=="collections"){
        const formatedJSON =finalJson&&Object.keys(finalJson)?.map((data)=>({
            table_name:data,
            description:finalJson[data]?.table_context,
            collection_id:response?.data?.id,
            schema:finalJson[data]?.column_Schema
        }))
      
        const collectionNames = "data-product-specs";

        for (let item of formatedJSON) {
            // Send API request for each vectorPayload
            await getAllDataProducts(
                parseInt(response?.data?.id),
                convertedData?.space?.[0]?.attributes?.Name,
                collectionNames,
                item
            );
        }
    }
        toast.success("Data product created successfully.");
    return  {status:"200",response}
    } else if (response?.error) {
        toast.error("Something went wrong. try to create after some time.")
        return {status:"500"}
    }
}

export default DBSubmit
