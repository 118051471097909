import { Card, Skeleton, TableColumn, TableHeader, Table, TableBody, TableRow, TableCell, CardHeader, CardBody } from "@nextui-org/react";


export const settingSkeleton = () => {
  return <div className="flex flex-col gap-8">
    <Card className="w-full  shadow-none border border-divider rounded-2xl p-4" radius="lg">
      <div className="flex flex-row items-center gap-4">
        <div>
          <Skeleton className="flex rounded-full w-12 h-12" />
        </div>
        <div className="w-full flex flex-col gap-2">
          <Skeleton className="h-6 w-3/5 rounded-lg" />
        </div>
      </div>
      <div className="flex flex-col gap-4 pt-8">
        <Skeleton className="h-6 w-full rounded-lg" />
        <Skeleton className="h-6 w-full rounded-lg" />
      </div>
      <div className="pt-4">
        <Skeleton className="h-6 w-1/3 rounded-lg" />
      </div>
    </Card>
    <Card className="w-full shadow-none border border-divider rounded-2xl p-4" radius="lg">
      <div className="flex flex-row items-center gap-4">
        <Skeleton className="h-6 w-3/5 rounded-lg" />
      </div>
      <div className="flex flex-col gap-4 pt-8">
        <Skeleton className="h-6 w-full rounded-lg" />
        <Skeleton className="h-6 w-full rounded-lg" />
      </div>
      <div className="pt-4">
        <Skeleton className="h-6 w-1/3 rounded-lg" />
      </div>
    </Card>
    <Card className="w-full shadow-none border border-divider rounded-2xl p-4" radius="lg">
      <div className="flex flex-row items-center gap-4">
        <Skeleton className="h-6 w-3/5 rounded-lg" />
      </div>
      <div className="flex flex-col gap-4 pt-8">
        <Skeleton className="h-6 w-full rounded-lg" />
        <Skeleton className="h-6 w-full rounded-lg" />
      </div>
      <div className="pt-4">
        <Skeleton className="h-6 w-1/3 rounded-lg" />
      </div>
    </Card>
  </div>
}

export const tableSkeleton = () => {
  return <div className="flex flex-col gap-8">
    <Table removeWrapper aria-label="Example static collection table">
      <TableHeader>
        <TableColumn></TableColumn>
        <TableColumn></TableColumn>
        <TableColumn></TableColumn>
        <TableColumn className="flex justify-center items-center"></TableColumn>
      </TableHeader>
      <TableBody>
        {[1, 2].map((item) => (
          <TableRow>
            {[1, 2, 3, 4].map((text) => (
              <TableCell>
                <Skeleton className="h-6 w-full rounded-lg" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
}

export const cardSkeleton = () => {
  return <div className='grid md:grid-cols-2 gap-8 sm:grid-cols-1 lg:grid-cols-3' >
    {[1, 2, 3].map((item) => (
      <div className="flex flex-row pt-8">
        <Card className="max-w-[340px] min-w-[340px] min-h-[240px] h-full p-4 border border-divider cursor-pointer" shadow='none' radius="lg">
          <div className="flex flex-row items-center gap-4">
            <div>
              <Skeleton className="flex rounded-full w-12 h-12" />
            </div>
            <div className='flex flex-col w-full gap-4'>
              <Skeleton className="w-3/5 rounded-lg">
                <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
              </Skeleton>
              <Skeleton className="w-4/12 rounded-lg">
                <div className="h-3 w-4/12 rounded-lg bg-default-200"></div>
              </Skeleton>
            </div>
          </div>
          <div className='pt-4 flex flex-col gap-2'>
            <Skeleton className="h-4 w-full rounded-lg" />
            <Skeleton className="h-4 w-full rounded-lg" />
            <Skeleton className="h-4 w-full rounded-lg" />
          </div>
          <div className='pt-4'>
            <Skeleton className="h-4 w-3/5 rounded-lg" />
          </div>
          <div className='flex flex-row gap-2 pt-4 justify-end'>
            <Skeleton className="h-6 w-6 rounded-lg" />
            <Skeleton className="h-6 w-6 rounded-lg" />
          </div>
        </Card>
      </div>
    ))}
  </div>
}

export const teamSkeleton = () => {
  return <div className="pb-8">
    <div className="relative">
      <div className="relative w-full h-40 ">
        <Skeleton className="h-40 w-full" />
      </div>
      <div className="z-10 absolute -bottom-7 left-24">
        <Skeleton className="h-16 w-16 rounded-md" />
      </div>
    </div>
    <div className="max-w-6xl mx-auto w-full pt-14">
      <div className="flex flex-col justify-between pb-8 border-b border-divider">
        <div className="flex flex-row justify-between pb-4 gap-20">
          <Skeleton className="h-6 w-1/4 rounded-lg" />
          <Skeleton className="h-6 w-1/4 rounded-lg" />
          <Skeleton className=" rounded-lg" />
        </div>
        <div>
          <Skeleton className="h-6 w-2/12 rounded-lg" />
        </div>
        <div className="pt-4">
          <Skeleton className="h-6 w-4/12 rounded-lg" />
        </div>
      </div>
      <div className="pt-8 pb-8">
        <Skeleton className="h-6 w-4/12 rounded-lg" />
      </div>
      <div>
        <Skeleton className="h-28 w-full rounded-lg" />
      </div>
      <div className="flex flex-row justify-between pt-8">
        <Skeleton className="h-6 w-4/12 rounded-lg" />
        <Skeleton className="h-6 w-2/12 rounded-lg" />
      </div>
    </div>
  </div>
}

export const collectionSkeleton = () => {
  return <div className="flex flex-col">
    <div className='text-3xl font-medium flex gap-4 items-center'>
      <div>
        <Skeleton className="flex rounded-full w-12 h-12" />
      </div>
      <Skeleton className="h-6 w-4/12 rounded-lg" />
    </div>
    <div className='pt-8'>
      <Skeleton className="h-6 w-1/5 rounded-lg" />
    </div>
    <div className='flex flex-col gap-4 pt-8'>
      <Skeleton className="h-32 w-full rounded-lg" />
    </div>
    <div className='flex flex-row gap-4 pt-8'>
      <Skeleton className="h-6 w-2/12 rounded-lg" />
      <Skeleton className="h-6 w-2/12 rounded-lg" />
      <Skeleton className="h-6 w-2/12 rounded-lg" />
    </div>
    <div className='flex flex-row justify-between gap-4 pt-8'>
      <Skeleton className="h-6 w-3/4 rounded-lg" />
      <Skeleton className="h-6 w-2/12 rounded-lg" />
    </div>
    <div className='pt-8'>
      <Skeleton className="h-32 w-full rounded-lg" />
    </div>
  </div>
}

export const HayaSettingSkeleton = () => {
  return <div className="flex flex-col gap-8">
    <Card className="w-full  shadow-none border border-divider rounded-2xl p-4" radius="lg">
      <div className="flex flex-col gap-4 pt-8">
        <Skeleton className="h-6 w-full rounded-lg" />
        <Skeleton className="h-6 w-full rounded-lg" />
      </div>
    </Card>
    <Card className="w-full shadow-none border border-divider rounded-2xl p-4" radius="lg">
      <div className="flex flex-row items-center gap-4">
        <Skeleton className="h-6 w-3/5 rounded-lg" />
      </div>
      <div className="flex flex-col gap-4 pt-8">
        <Skeleton className="h-6 w-full rounded-lg" />
        <Skeleton className="h-6 w-full rounded-lg" />
      </div>
      <div className="pt-4">
        <Skeleton className="h-6 w-1/3 rounded-lg" />
      </div>
    </Card>
    <Card className="w-full shadow-none border border-divider rounded-2xl p-4" radius="lg">
      <div className="flex flex-row items-center gap-4">
        <Skeleton className="h-6 w-3/5 rounded-lg" />
      </div>
      <div className="flex flex-col gap-4 pt-8">
        <Skeleton className="h-6 w-full rounded-lg" />
        <Skeleton className="h-6 w-full rounded-lg" />
      </div>
      <div className="pt-4">
        <Skeleton className="h-6 w-1/3 rounded-lg" />
      </div>
    </Card>
  </div>
}

export const uploadSkeleton = () => {
  return <div className="flex flex-col gap-4 pt-8">
    <Skeleton className="h-8 w-1/3 rounded-lg" />
    <Skeleton className="h-6 w-full rounded-lg" />
    <Skeleton className="h-6 w-full rounded-lg" />
    <div className='pt-4 pb-4'>
      <Skeleton className="h-8 w-1/5 rounded-lg" />
    </div>
    <Skeleton className="h-8 w-1/3 rounded-lg" />
    <Skeleton className="h-24 w-full rounded-lg" />
    <Skeleton className="h-8 w-2/12 rounded-lg" />
    <Skeleton className="h-24 w-full rounded-lg" />
  </div>
}

export const knowledgeBaseSkeleton = () => {
  return <div className="flex flex-col gap-4 pt-8">
    <Skeleton className="h-8 w-1/3 rounded-lg" />
    <Skeleton className="h-8 w-full rounded-lg" />
    <div className='flex justify-between pt-8' >
      <Card className="py-4 w-[300px] h-[300px] border border-divider" shadow='none'>
        <CardHeader className="pb-0 pt-2 px-4 flex-col gap-2 items-start">
          <Skeleton className="h-4 w-2/12 rounded-lg" />
          <Skeleton className="h-4 w-2/3 rounded-lg" />
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <Skeleton className="h-full w-full rounded-lg" />
        </CardBody>
      </Card>
      <Card className="py-4 w-[800px] h-[300px] border border-divider" shadow='none'>
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <Skeleton className="h-4 w-1/4 rounded-lg" />
          <div className="w-full pt-8">{tableSkeleton()}</div>
        </CardHeader>
      </Card>
    </div>
    <div className='flex justify-between gap-8 pt-8'>
      <Card className="py-4 w-[600px] h-[300px] border border-divider" shadow='none'>
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <Skeleton className="h-8 w-2/3 rounded-lg" />
        </CardHeader>
      </Card>
      <Card className="py-4 w-[600px] h-[300px] border border-divider" shadow='none'>
        <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
          <Skeleton className="h-8 w-2/3 rounded-lg" />
        </CardHeader>
        <CardBody>
          <div className="w-full pt-8">{tableSkeleton()}</div>
        </CardBody>
      </Card>
    </div>
  </div>
}

export const extensionSkeleton = () => {
  return <div className="flex flex-col gap-4 pt-8">
    <Skeleton className="h-8 w-1/3 rounded-lg" />
    <Skeleton className="h-8 w-full rounded-lg" />
    <div className='mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12'>
      {[1, 2, 3, 4].map((data) => (
        <Card className="h-[300px]">
          <CardHeader className='pt-4'>
            <Skeleton className="h-4 w-1/3 rounded-lg" />
          </CardHeader>
          <CardBody className='pt-8'>
            <Skeleton className="h-8 w-full rounded-lg" />
          </CardBody>
        </Card>
      ))}
    </div>
  </div>
}

export const searchCard = () => {
  return <div className='flex flex-row items-start justify-between'>
    <div className="flex flex-col">
      <div className='mt-8 flex flex-col gap-8'>
        {[1, 2, 3].map((data) => (
          <Card className='w-[900px] h-[170px] card-color border border-divider' shadow='none'>
            <CardHeader className="flex gap-3 flex-col justify-between px-4">
              <div className="flex w-full flex-row items-center gap-4">
                <div>
                  <Skeleton className="flex rounded-full w-12 h-12" />
                </div>
                <div className='w-full flex flex-col gap-2'>
                  <Skeleton className="h-4 w-3/12 rounded-lg" />
                  <Skeleton className="h-4 w-1/3 rounded-lg" />
                </div>
              </div>
              <CardBody className='flex gap-2'>
                <Skeleton className="h-4 w-3/12 rounded-lg" />
                <Skeleton className="h-4 w-full rounded-lg" />
                <Skeleton className="h-4 w-full rounded-lg" />
              </CardBody>
            </CardHeader>
          </Card>
        ))}
      </div>
    </div>
    <div className='mt-8'>
      <Card className="w-[200px] h-[250px] card-color border border-divider" shadow='none'>
        <CardHeader className="flex gap-3 ">
          <Skeleton className="h-6 w-full rounded-lg" />
        </CardHeader>
        <CardBody className='pt-8 flex gap-4'>
          <Skeleton className="h-6 w-full rounded-lg" />
          <Skeleton className="h-6 w-full rounded-lg" />
          <Skeleton className="h-6 w-full rounded-lg" />
          <Skeleton className="h-6 w-full rounded-lg" />
        </CardBody>
      </Card>
    </div>
  </div>
}

export const toolsSkeleton = () => {
  return (
    <div className='flex flex-col '>
      <Skeleton className="h-8 w-1/3 rounded-lg" />
      <Skeleton className="h-8 w-full rounded-lg mt-8" />
      <div className='flex mt-8 justify-between gap-8'>
        <div className='w-full flex flex-col gap-12'>
          <Skeleton className="h-8 w-full rounded-lg" />
          <Skeleton className="h-36 w-full rounded-lg" />
          <Skeleton className="h-56 w-full rounded-lg" />
          <Skeleton className="h-8 w-full rounded-lg" />
          <div className='flex justify-end'>
            <Skeleton className="h-8 w-1/4 rounded-lg" />
          </div>
        </div>
        <div className='w-full flex flex-col gap-8'>
          <Skeleton className="h-8 w-full rounded-lg" />
          <Skeleton className="h-8 w-full rounded-lg" />
          <Card className='h-[250px] w-full border border-divider' shadow='none' >
            <Skeleton className="h-full w-full rounded-lg" />
          </Card>
          <Card className='h-[250px] w-full border border-divider' shadow='none' >
            <Skeleton className="h-full w-full rounded-lg" />
          </Card>
        </div>
      </div>
    </div>
  )
}

export const TabsSkeleton = (data) => {
  return <div className='flex flex-row gap-4'>
    {data.map(data => <Skeleton className="h-6 w-1/4 rounded-lg" />)}
  </div>
}

export const formSkeleton = (data) => {
  return <div className='pt-8'>
    <div className='flex flex-col gap-8'>
      <Skeleton className="h-8 w-full rounded-lg" />
      <Skeleton className="h-32 w-full rounded-lg" />
      <Skeleton className="h-32 w-full rounded-lg" />
      <Skeleton className="h-8 w-full rounded-lg" />
      <Skeleton className="h-8 w-full rounded-lg" />
    </div>
  </div>
}
export const ConnectorSkeleton = () => {
  return <div>
    <div className='text-3xl font-medium pb-4 flex gap-4 items-center'>
      <div>
        <Skeleton className="flex rounded-md w-12 h-12 " />
      </div>
      <Skeleton className="h-6 w-4/12 rounded-lg" />
    </div>
    <Skeleton className="h-32 w-full rounded-lg" />
    <div className='pt-4'>
      {TabsSkeleton([1, 2])}
    </div>
  </div>
}