import React, { useState, useEffect } from 'react';
import { Button, Input, Textarea } from "@nextui-org/react";
import { getAllData } from "../../../controllers/strapiController";
import { useSelector } from "react-redux";
import Tags from '../../../components/Tags';
import { toast } from "react-hot-toast";

const CreateConnector = ({ darkMode, tagInput, setTagInput, setFormError, formError, setValidationCondition, validationCondition, setFormData, onNext, payload, onPrevious, connectorData, setConnectorData, ButtonVisible }) => {
  // STATE INITIALIZATION
  const [validatetags, setValidatetags] = useState('');
  const [tags, setTags] = useState([]);
  const [tagsData, setTagsData] = useState();
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userID = userDetails?.user?.id;
  const data = useSelector(data => data);
  const indexData = JSON.stringify(data, null, 2);
  const convertedData = JSON.parse(indexData);
  const spaceID = convertedData?.space?.[0]?.id;

  // TAGS API CALL
  useEffect(() => {
    getAllData("tags")
      .then((data) => { setTagsData(data.data) })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [])

  // INITIAL STATE UPLOAD
  useEffect(() => {
    if (payload) {
      setConnectorData({
        Name: payload.Name,
        Context: payload.Context,
        Tags: payload?.Tags?.tags
      })
      setTags(payload?.Tags?.tags)
    }
  }, [payload])

  
  useEffect(() => {
    setTags(connectorData?.Tags)
  }, [!ButtonVisible, connectorData])

  // VALIDATIONS
  const Validation = () => {
    let IsValid = true;

    if (!connectorData.Name) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
    } else {
      setFormError((previous) => ({ ...previous, NameError: null }))
    }

    if (!connectorData.Context) {
      IsValid = false;
      setFormError((previous) => ({ ...previous, ContextError: "Context is required." }))
    } else {
      setFormError((previous) => ({ ...previous, ContextError: null }))
    }
    return IsValid;
  }

  // TAGS FILTER DROPDOWN
  const FilteredUser = tagsData && tagsData.filter((data) => data?.attributes?.users_permissions_user?.data?.id === userID)
  // TAGS PRESENTS
  const TagsLength = FilteredUser?.[0]?.attributes?.Tags?.tags;
  // FILTER FOR INPUT TEXT RELATED TO TAGS
  const FilteredText = tagInput !== '' ? TagsLength && TagsLength.filter((data) => data && data.toLowerCase().includes(tagInput && tagInput.trim().toLowerCase())) : TagsLength;

  // FINAL DISPLAY TAGS
  const FilteredTags = FilteredText && FilteredText.length > 0 ? FilteredText : []

  const TagsRender = connectorData?.Tags !== null && connectorData?.Tags !== '' && TagsLength && connectorData?.Tags?.filter((data) => !TagsLength.includes(data));
  const NewRenderation = FilteredTags && TagsRender && TagsRender.concat(FilteredTags);
  const TagsID = FilteredUser?.[0]?.id;
  // const spaceID = spaceData && spaceData[0] && spaceData[0].id;


  // SUBMIT HANDLER TO CREATE CONNECTOR
  const submitHandler = async () => {
    // onNext()
    const validate = Validation();
    const payload = {
      Name: connectorData.Name,
      Context: connectorData.Context,
      Tags: { "tags": connectorData.Tags },
      author: { disconnect: [], connect: [{ id: userID, position: { end: true } }] },
      space: { disconnect: [], connect: [{ id: spaceID, position: { end: true } }] },
      connector_list: { disconnect: [], connect: [] }
    }

    const TagsPayload = { Tags: { tags: NewRenderation } }

    if (validate) {
      setValidationCondition(false);
      onNext();
      setFormData((prev) => ({ ...prev, payload: payload, tags: TagsPayload }));
      setValidationCondition(false);
      if (FilteredUser && FilteredUser.length > 0) {
        setFormData((prev) => ({ ...prev, payload: payload, tags: { id: TagsID, payload: TagsPayload, func: 'UpdateData' } }))
      }
      if (FilteredUser && FilteredUser.length === 0) {
        const TagsData = {
          users_permissions_user: { disconnect: [], connect: [{ id: userID, position: { end: true } }] },
          Tags: { "tags": connectorData.Tags }
        }
        setFormData((prev) => ({ ...prev, payload: payload, tags: { id: TagsID, payload: TagsData, func: 'CreateData' } }));
      } else {
        setFormData((prev) => ({ ...prev, payload: payload, tags: { id: TagsID, payload: TagsPayload, func: 'UpdateData' } }));
      }
    } else {
      setValidationCondition(true);
    }
  }

        // TAGS INPUT ONCHANGE HANDLER
        const handleChange = (e) => {
          const userInput = e.target.value;
          const regex = /^[a-zA-Z_ -]*$/;
          if (regex.test(userInput)) {
            setConnectorData({ ...connectorData, Name: userInput }) 
          }
      }
  // RETURNED UI
  return (
    <div>
      <Input
        isRequired
        key="outside"
        type="text"
        value={connectorData.Name}
        onChange={handleChange}
        isInvalid={!connectorData.Name && validationCondition ? !connectorData.Name || validationCondition : ''}
        errorMessage={!connectorData.Name && validationCondition ? formError.NameError : ""}
        label="Name"
        labelPlacement="outside"
        placeholder="Enter your connector name"
      />
      <Textarea
        className='pt-4'
        key={"outside"}
        isRequired
        type="text"
        value={connectorData.Context}
        onChange={(e) => { setConnectorData({ ...connectorData, Context: e.target.value }) }}
        isInvalid={!connectorData.Context && validationCondition ? !connectorData.Context || validationCondition : ''}
        errorMessage={!connectorData.Context && validationCondition ? formError.ContextError : ""}
        label="Context"
        labelPlacement={"outside"}
        placeholder="Enter your collection's Context"
      />
      <Tags
        darkMode={darkMode}
        tagsData={tagsData}
        setTagsData={setTagsData}
        tagInput={tagInput}
        setTagInput={setTagInput}
        tags={tags}
        validatetags={validatetags}
        setTags={setTags}
        setValidatetags={setValidatetags}
        setFormdata={setConnectorData}
        formData={connectorData}
        background={"bg-background"}
      />
      {ButtonVisible &&
        <div className="flex flex-row gap-4 justify-end pt-8">
          <Button onClick={onPrevious}>Previous</Button>
          <Button color='secondary' onClick={submitHandler}>Submit</Button>
        </div>
      }
    </div>
  )
}

export default CreateConnector;