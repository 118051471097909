import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import input from "./input.css";
import { NextUIProvider } from '@nextui-org/react';
import { Provider } from 'react-redux';
import store from "./redux/store"
import Widget from "react-resolv"
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode className='min-h-full text-foreground bg-background '>
    <NextUIProvider className={`text-foreground bg-background min-h-full max-h-screen max-w-screen-3xl relative container mx-auto flex-grow`} style={{width: '100%'}}>
      <main className="min-h-full ">
      <Provider store={store}>
        {/* <Widget.ResolvProvider appId={"53kajgpgqhKoZ9vaqiA7l4qx1FuY0co3"} customerId={`haya_sreevatsHaya@gmail.com`}> */}
        <App />
        {/* </Widget.ResolvProvider> */}
        </Provider>
      </main>
    </NextUIProvider>
  </React.StrictMode>,
);

reportWebVitals();
