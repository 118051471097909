import React from 'react';
import {Switch} from "@nextui-org/react";

const SwitchComponent = ({color,defaultSelected,size,onChange,name}) => {
  return (
    <Switch defaultSelected={defaultSelected} size={size} color={color} name={name} onChange={onChange}>
  </Switch>
  )
}

export default SwitchComponent