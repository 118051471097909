import React from 'react';
import { Input, Textarea, Card, Button, Tooltip } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { Editor } from "@monaco-editor/react";

const ToolsCreateForm = ({ darkMode, formData, setFormData, validateCondition, validate, titleVisible, onPrevious, buttonDisabled, submitTest, submitQuery, runAll, reactFormJSON, filteredJSONForm, setJsonData, JsonData, JsonDataError, toolsData }) => {
    const params = useParams();

    // HANDLE CHANGE FOR DATABASE STRINGS
    const handleChange = (e) => {
        const { name, value } = e.target;
        setJsonData((prev) => ({ ...JsonData, [name]: value }));
    }

    // TAGS INPUT ONCHANGE HANDLER
    const handleTitleChange = (e) => {
        const userInput = e.target.value;
        const regex = /^[a-zA-Z_ -]*$/;
        if (regex.test(userInput)) {
            setFormData((prev) => ({ ...prev, name: userInput }))
        }
    }

    // DYNAMICALLY FORM RENDERING
    const formCreater = () => {
        return <div className='flex flex-col gap-8'>
            {filteredJSONForm?.attributes?.Connector_schema?.properties?.map((data) => {
                return <Input
                    isRequired
                    key="outside"
                    type={data.type}
                    htmlFor={data.name}
                    name={data.name}
                    value={JsonData && JsonData[data.name]}
                    isInvalid={JsonData && !JsonData[data.name] && validateCondition ? !JsonData[data.name] || validateCondition : ''}
                    errorMessage={JsonData && !JsonData[data.name] && validateCondition ? JsonDataError[data.name] : ""}
                    onChange={handleChange}
                    label={data.name}
                    labelPlacement="outside"
                    placeholder={`Enter a ${data.name}`}
                />
            })}
        </div>
    }
    return (
        <div className='flex mt-8 justify-between gap-8'>
            {/* Form to fill in the details */}
            <div className='w-full flex flex-col gap-8'>
                <Input
                    isRequired
                    label='Name'
                    type='text'
                    labelPlacement='outside'
                    value={formData.name}
                    onChange={handleTitleChange}
                    isInvalid={!formData.name && validateCondition ? !formData.name || validateCondition : ''}
                    errorMessage={!formData.name && validateCondition ? validate.nameError : ""}
                    placeholder='Enter tool name' />
                <Textarea
                    isRequired
                    labelPlacement='outside'
                    label="Context"
                    placeholder="Enter your context"
                    value={formData.description}
                    onChange={(e) => setFormData((prev) => ({ ...prev, description: e.target.value }))}
                    isInvalid={!formData.description && validateCondition ? !formData.description || validateCondition : ''}
                    errorMessage={!formData.description && validateCondition ? validate.descriptionError : ""}
                    className=""
                    minRows='6'
                />
                {!reactFormJSON && <div>
                    <div className='flex flex-row items-end gap-1'><p className='text-sm'>YAML Open API specification</p><Tooltip
                        size='sm' placement='right' content='Use variables in a form to automatically replace the variables in the prompt'
                        classNames={{ base: ["before:bg-neutral-400 dark:before:bg-white",], content: ["w-44", "p-3"], }}>
                        <lord-icon
                            src="https://cdn.lordicon.com/yxczfiyc.json"
                            colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                            style={{ width: "14px", height: "14px" }}>
                        </lord-icon>
                    </Tooltip></div>
                    <Card className={`p-4 mt-2 max-h-[100vh] min-h-64  border ${(validateCondition && formData.YAML === '') ? "border-danger" : " border-divider"} `} shadow='none' >
                        <Editor
                            className='max-h-[100vh] min-h-64'
                            height={'100%'}
                            width={'100%'}
                            theme={darkMode.value === true ? 'vs-dark' : 'vs-light'}
                            language={`yaml`}
                            value={formData.YAML}
                            onChange={(e) => setFormData((prev) => ({ ...prev, YAML: e }))} />
                        {formData.YAML === '' && <p className='text-tiny text-danger'>{validate.YAMLError}</p>}
                    </Card>
                </div>
                }
                <div className='flex flex-col gap-2'>
                    <p className='text-sm'>Authorizations</p>
                    {reactFormJSON ? formCreater() : "Implementing..."}
                </div>
                <div className='flex justify-end gap-4'>
                    {!titleVisible && <Button onClick={onPrevious}>Previous</Button>}
                    <Button color='danger' size='md' isDisabled={params._id ? buttonDisabled : ""} onClick={submitTest}>Test tools</Button>
                </div>
            </div>
            <div className='w-full'>
                <p className='text-lg font-medium items-center flex justify-center'>Test Bench</p>
                <div className='flex flex-row gap-2 pt-4'>
                    <Input
                        type='text'
                        placeholder='Query...'
                        onChange={(e) => setFormData((prev) => ({ ...prev, queryText: e.target.value }))} />
                    <Button color='danger' isDisabled={formData.queryText === ''} onClick={submitQuery}>RUN</Button>
                </div>
                {runAll && <>
                    <div className='pt-8 flex flex-col items-center gap-4 justify-center'>
                        <p>STEPS</p>
                        <Card className='h-[250px] w-full border border-divider' shadow='none' >
                        </Card>
                    </div>
                    <div className='pt-8 flex flex-col items-center gap-4 justify-center'>
                        <p>OUTPUT</p>
                        <Card className='h-[250px] w-full border border-divider' shadow='none' >
                        </Card>
                    </div>
                </>}
            </div>
        </div>
    )
}

export default ToolsCreateForm;