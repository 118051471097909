import { Card, CardBody, CardFooter, CardHeader, Divider, Image } from '@nextui-org/react'
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const KnowledgeTree = () => {

  return (
    <div>
      <div className='text-3xl font-semibold'>Knowledge Tree</div>
      <div className='mt-8'>
        <Card className="max-w-1/2 h-[800px] border border-divider" shadow='none'>
          <CardHeader className="flex gap-3">
            <Image
              alt="nextui logo"
              height={30}
              radius="full"
              src="/assets/haya-logo.png"
              width={30} />
            <div className="flex flex-col">
              <p className="text-md">Haya Knowledge Tree</p>
              <p className="text-small text-default-500">View graph of your overview</p>
            </div>
          </CardHeader>
          <Divider />
          <CardBody>
            <Card isBlurred shadow='none' className='border z-10 blur-sm border-divider flex h-full'>
              {/* Knowledge Tree */}
            </Card>
            <div className='inset-x-0 inset-y-0 absolute flex flex-col justify-center z-[100]  items-center'>
              <FontAwesomeIcon icon={faLock} />
              <p>Coming Soon</p>
            </div>
          </CardBody>
          <Divider />
          <CardFooter>
            Built with Haya.
          </CardFooter>
        </Card>
      </div>
    </div>
  )
}

export default KnowledgeTree;