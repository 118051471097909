import React, { useState, useEffect } from 'react';
import { CommonCollectionFilterApi, UpdateOneUserData, getOneData } from "../../controllers/strapiController";
import { useNavigate } from "react-router-dom";
import { avatarName, colorPicker } from "../../controllers/basicControllers";
import SearchIcon from '../../Images/Search';
import Atom from "../../Images/Atom.jsx"
import { Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Input, ScrollShadow, Card, CardHeader, Button, CardBody, Avatar, Tooltip, Chip } from "@nextui-org/react";
import Beaker from '../../Images/Beaker.jsx';
import Loading from "../../components/Loader/Loading";
import { ChevronDown, Lock, Flash, Server, TagUser, Scale } from "./Icons.jsx";
import { Activity } from '@geist-ui/icons'

const Home = ({ darkMode }) => {
    const icons = {
        chevron: <ChevronDown fill="currentColor" size={16} />,
        scale: <Scale className="text-warning" fill="currentColor" size={30} />,
        lock: <Lock className="text-success" fill="currentColor" size={30} />,
        activity: <Activity className="text-secondary" fill="currentColor" size={30} />,
        flash: <Flash className="text-primary" fill="currentColor" size={30} />,
        server: <Server className="text-success" fill="currentColor" size={30} />,
        user: <TagUser className="text-danger" fill="currentColor" size={30} />,
    };

    const option = [
        { id: 1, path: '/create-space', title: 'Create', description: "Create an application", icon: <Beaker /> },
        { id: 2, path: '/join-space', title: 'Join', description: "Join an existing application", icon: <Atom /> }
    ]

    const dropdownOptions = [
        { title: 'Create an application', description: 'Create a data mesh to interact with your data points', path: '/create-space', icon: icons?.flash },
        { title: ' Collaborate on an existing application', description: 'Join an existing application to interact with your resources', path: '/join-space', icon: icons?.lock }
    ]
    const [spaceData, setSpaceData] = useState();
    const [searchData, setSearchData] = useState('');
    const [loading, setLoading] = useState(true);
    const userDetails = JSON.parse(sessionStorage.getItem("userData"))
    const userID = userDetails && userDetails.user && userDetails.user.id;
    const navigate = useNavigate();
    const [userData, setUserData] = useState();

    useEffect(() => {
        if (userDetails === null) {
            navigate('/sign-in');
        }
    }, [])

    useEffect(() => {
        setLoading(true);
        const url = `filters[$or][0][admin][id][$eq]=${userID}&filters[$or][1][members][id][$eq]=${userID}&[populate][admin][on]&[populate][collections][on]&[populate][connectors][on]&[populate][tools][on]`
        CommonCollectionFilterApi("spaces", url)
            .then((data) => { setLoading(false); setSpaceData(data.data) })
            .catch((error) => console.log("error", error))

        getOneData("users", userID).then((data) => setUserData(data))
    }, [userID])

    const handleSelected = async (data) => {
        if (userData && (userData.LastSpace === data.attributes.SpaceName)) {
            if (spaceData && spaceData.length !== 1) {
                const UpdateUser = spaceData && spaceData.find((item) => item.attributes.Name !== data.attributes.Name)
                const payload = {
                    "LastSpace": UpdateUser && UpdateUser.attributes.SpaceName
                }
                await UpdateOneUserData("users", userID, payload);
            }
        }
        navigate(`/${data.attributes.Name}/`);
    }

    const renderOptions = (data) => {
        return <div className='cursor-pointer group' onClick={() => navigate(data?.path)}>
            <Card isFooterBlurred className="w-[350px] h-[400px] border border-transparent bg-white hover:border border-black transition-colors duration-[600ms] relative overflow-hidden">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                    <p className="text-tiny text-black uppercase font-bold px-4">{data?.title}</p>
                    <h4 className="text-black font-medium text-2xl px-4 mt-4">{data?.description}</h4>
                    <div className='flex justify-center w-full mt-24'>
                        {data?.icon}
                    </div>
                </CardHeader>
            </Card>
        </div>
    }
    const filteredData = spaceData && spaceData.filter((data) => data.attributes.Name.includes(searchData));

    if (loading) {
        return <div className={`absolute z-[100]  inset-0 h-screen w-screen ${darkMode.value === true ? 'bg-black' : 'bg-slate-50'}`}>
            <Loading darkMode={darkMode} />
        </div>
    }

    if (spaceData) {
        return (
            <div className="w-full mx-auto h-[calc(100vh-87px)] pt-16 flex flex-col">
                <div className='h-full w-full flex flex-col pt-8 justify-center items-center'>
                    {spaceData && spaceData.length > 3 &&
                        <div className='w-[450px] pb-4'>
                            <Input
                                placeholder="Search your space name here..."
                                variant="bordered"
                                radius="sm"
                                value={searchData}
                                onChange={(e) => setSearchData(e.target.value)}
                                startContent={<SearchIcon darkMode={darkMode} className={`text-large text- pointer-events-none flex-shrink-0 `} />} />
                        </div>}
                    <div className="flex flex-col gap-4 w-full items-center ">
                        {filteredData && filteredData.length > 0 ?
                            <>
                                <div className='flex flex-col w-full pb-8'>
                                    <div className="flex flex-row w-full items-center justify-between pt-4 ">
                                        <h1 className='text-xl font-medium'>Choose an application</h1>
                                        <Dropdown placement="bottom-end">
                                            <DropdownTrigger>
                                                <Button className={`${darkMode.value === true ? 'text-black hover:text-black bg-white' : 'text-white bg-black hover:bg-gray-700 hover:text-white'}`}>Add New</Button>
                                            </DropdownTrigger>
                                            <DropdownMenu aria-label="Link Actions" className='w-[340px]'>
                                                {dropdownOptions?.map((data, index) => (
                                                    <DropdownItem key={`${index}`} href={data?.path} variant='flat' description={data?.description}
                                                        startContent={data?.icon}>
                                                        {data?.title}
                                                    </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div>
                                        Select a data mesh space from the options below. You also have the option to create a new application or join an existing ones.
                                    </div>
                                </div>
                                <ScrollShadow className="h-[440px] w-full pb-4" size='5px' hideScrollBar='false'>
                                    <div className='grid md:grid-cols-2 gap-12 sm:grid-cols-1 lg:grid-cols-3'>
                                        {filteredData && filteredData.map((data, index) => {
                                            const collectionCount = data?.attributes?.collections?.data?.length || 0;
                                            const connectorsCount = data?.attributes?.connectors?.data?.length || 0;
                                            const toolsCount = data?.attributes?.tools?.data?.length || 0;
                                            const count = collectionCount + connectorsCount + toolsCount;
                                            const truncatedSpaceName = data?.attributes?.Name?.length > 17 ? `${data?.attributes?.Name?.slice(0, 17)}...` : data?.attributes?.Name;
                                            return <div key={`${index}`} onClick={() => handleSelected(data)} className='w-full cursor-pointer'>
                                                <Card className="max-w-[400px] h-[200px] border border-divider" shadow='none'>
                                                    <CardHeader className="flex gap-3">
                                                        <Avatar
                                                            alt="nextui logo"
                                                            height={40}
                                                            radius="full"
                                                            name={avatarName(data?.attributes?.SpaceName)}
                                                            color={colorPicker(data?.id)}
                                                            width={40}
                                                        />
                                                        <div className="flex flex-col">
                                                            <p className="text-md">{data?.attributes?.SpaceName}</p>
                                                            {data?.attributes?.Name?.length > 17 ? <Tooltip
                                                                showArrow
                                                                placement="bottom"
                                                                content={`${process.env.REACT_APP_IP_ADDRESS}/${data?.attributes?.Name}`}
                                                                classNames={{
                                                                    content: ["py-1 px-4",],
                                                                }}>
                                                                <p className="text-small text">{`${process.env.REACT_APP_IP_ADDRESS}/${truncatedSpaceName}`}</p>
                                                            </Tooltip>
                                                                : <p className="text-small text-default-500 font-light">{`${truncatedSpaceName}.haya.cloud`}</p>
                                                            }
                                                        </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div>
                                                            <Chip
                                                                className='bg-[#f5f5f5] text-xs font-light'>
                                                                sreevatsansridh
                                                            </Chip>
                                                        </div>
                                                        <div className='flex items-end justify-end gap-2'>
                                                            <Tooltip content="Data products">
                                                                <div className='flex items-center'>
                                                                    <lord-icon
                                                                        src="https://cdn.lordicon.com/hqymfzvj.json"
                                                                        trigger="hover"
                                                                        state="morph-book"
                                                                        target="#DatabaseProducts"
                                                                        colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                                        style={{ width: "20px", height: "20px" }}>
                                                                    </lord-icon>
                                                                    <p className="text-xs text-foreground font-medium">
                                                                        {count}
                                                                    </p>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        })}
                                    </div>
                                </ScrollShadow>
                            </>
                            : <>
                                <h1 className='mb-1.5 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
                                    Create or Join a Knowledge base
                                </h1>
                                <div className='flex gap-8 mt-8'>
                                    {option?.map((data, index) => {
                                        return renderOptions(data)
                                    })}
                                </div>
                            </>}
                    </div>
                </div >
            </div >
        )
    }
}

export default Home;