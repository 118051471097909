import React, { useEffect, useState } from 'react';
import { Table, TableHeader, TableBody, TableColumn, TableRow, TableCell, Avatar } from "@nextui-org/react";
import { CommonCollectionFilterApi } from "../../controllers/strapiController";
import { NameCreater, titleCreater } from "../../controllers/basicControllers";
import { useParams, useNavigate } from "react-router-dom";
import { colorPicker, displayName } from '../../controllers/basicControllers';

const RecentDataProductTable = () => {
    const [dataProducts, setDataProducts] = useState([]);
    const collectionName = 'spaces';
    const params = useParams();
    const navigate = useNavigate();

    // API FOR COLLECTIONS RESPONSE
    useEffect(() => {
        const url = `filters[Name][$eq]=${params._spaceid}&[populate][collections][on]&populate[collections][populate][author][populate][Picture][populate][fields][3]=url&populate[connectors][populate][author][populate][Picture][populate][fields][3]=url&populate[tools][populate][author][populate][Picture][populate][fields][3]=url&[populate][tools][on]&[populate][connectors][on]`
        CommonCollectionFilterApi(collectionName, url)
            .then((data) => setDataProducts(data.data))
            .catch((err) => err)
    }, [collectionName])


    const MurgedData = () => {
        const collectionArray = dataProducts?.map((data) =>
            data?.attributes?.collections?.data?.map((item) => ({ ...item, collectionName: "collections" })) || []
        ) || [];

        const connectorArray = dataProducts?.map((data) =>
            data?.attributes?.connectors?.data?.map((item) => ({ ...item, collectionName: "connectors" })) || []
        ) || [];

        const toolsArray = dataProducts?.map((data) =>
            data?.attributes?.tools?.data?.map((item) => ({ ...item, collectionName: "tools" })) || []
        ) || [];

        // Flatten the arrays and merge them
        const mergedArray = [].concat(...collectionArray, ...connectorArray, ...toolsArray);

        return mergedArray;
    }

    const navigateHandler = (data) => {
        navigate(`${data?.collectionName}/${data.id}`)
    }

    const sortedData = MurgedData()?.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))

    return (
        <div className='p-0 w-full'>

            <Table removeWrapper className='px-4 pt-4'>
                <TableHeader>
                    <TableColumn>Name</TableColumn>
                    <TableColumn>Type</TableColumn>
                    <TableColumn>Created by</TableColumn>
                </TableHeader>
                <TableBody emptyContent={"No data products present."}>
                    {sortedData?.slice(0, 4)?.map((data, index) => {
                        const userName = data?.attributes?.author?.data?.attributes
                        return <TableRow key="1" onClick={() => navigateHandler(data)} className='cursor-pointer'>
                            <TableCell>{titleCreater(data?.attributes?.Name)}</TableCell>
                            <TableCell>{data?.collectionName?.charAt(0).toUpperCase() + data?.collectionName?.slice(1)}</TableCell>
                            <TableCell>
                                <div className='flex gap-4 items-center'>
                                    <Avatar
                                        color={colorPicker(data?.attributes?.author?.data?.id)}
                                        src={userName?.Picture?.data !== null && `${process.env.REACT_APP_STAGE === "dev" ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${userName?.Picture?.data?.attributes?.url}` : `${userName?.Picture?.data?.attributes?.url}`}`} name={NameCreater(userName?.firstname, userName?.email)} className="w-8 h-8 text-tiny" />
                                    <div className='text-tiny text-default-500 font-bold'>
                                        {displayName(userName?.firstname, userName?.lastName, userName?.email)}
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </div>
    )
}

export default RecentDataProductTable;