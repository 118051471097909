import React, { useState } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell } from '@nextui-org/react'

const TableProperty = ({ darkMode, connectorData }) => {
    const schema = connectorData?.attributes?.Additional_Context?.properties;

    return (
        <>
            <div className='flex flex-col gap-8'>
                {schema && Object.keys(schema).map((tableName) => {
                    console.log("tableName",schema[tableName])
                   return <div>
                    <div className='border-b border-divider pb-2 text-xl font-medium'>{tableName}</div>
                        <div className='py-4'>
                        {schema[tableName]?.table_context}
                        </div>
                        <div className='pt-4'>
                            <Table removeWrapper aria-label={`Table data for ${tableName}`}>
                                <TableHeader>
                                    <TableColumn>Column Name</TableColumn>
                                    <TableColumn>Type</TableColumn>
                                    <TableColumn>Context</TableColumn>
                                </TableHeader>
                                <TableBody>
                                    {schema?.[tableName]?.column_Schema.map((column, index) => {
                                            console.log("column",column)
                                        return <TableRow key={index + 1} value={index}>
                                            <TableCell>
                                                {/* <Checkbox isSelected={true}/> */}
                                                {column.name}
                                            </TableCell>
                                            <TableCell>{column.type}</TableCell>
                                            <TableCell>
                                                <div>
                                                   {column?.Context}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                })}
            </div>
        </>
    )
}

export default TableProperty;