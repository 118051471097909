import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter } from '@nextui-org/react';
import React, { useState } from 'react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';

const DeleteModal = ({ isOpen, onOpenChange, deleteData, setDeleteData, Collectionname, setLoader, DeleteHandler }) => {
    const [inputData, setInputData] = useState(null);

    return (
        <div className='h-full z-100 transform-null'>
            <NextThemesProvider >
                <Modal
                    size="lg"
                    isOpen={isOpen}
                    onOpenChange={onOpenChange}
                    placement="center"
                    radius='sm'
                    className='py-4'>
                    <ModalContent>
                        {(onClose) => (
                            <>
                                <ModalBody className='gap-0'>
                                    <span className='block text-sm font-normal mb-4 '>Are you sure you want to delete the {Collectionname}?{" "}
                                        <span className='text-sm text-danger font-bold'>This can not be undone.</span>
                                    </span>

                                    <Input
                                        key="outside"
                                        type="text"
                                        radius="sm"
                                        className='border rounded-lg border-divider mt-8'
                                        label={<p className=''>Type <strong>DELETE</strong> to confirm.</p>}
                                        labelPlacement="outside"
                                        placeholder=' '
                                        onChange={(e) => setInputData(e.target.value)}
                                    />
                                </ModalBody>
                                <ModalFooter >
                                    <Button className='text-bold text-white' isDisabled={inputData !== "DELETE"} color="danger" onClick={() => { DeleteHandler(); onClose(); setInputData('') }}>
                                        Delete {Collectionname}
                                    </Button>
                                    <Button onClick={() => { setDeleteData && setDeleteData(null); setInputData(null); onClose() }} variant="light" >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </>
                        )}
                    </ModalContent>
                </Modal>
            </NextThemesProvider>
        </div>
    )
}

export default DeleteModal