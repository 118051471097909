import React, { useState, useEffect } from 'react';
import { Select, SelectItem } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CreateData, UpdateData, CommonCollectionFilterApi, getAllData } from "../../controllers/strapiController";
import { toolsSkeleton } from '../../components/Skeleton';
import NotFound from "../../components/NotFound";
import ToolsCreateForm from './ToolsCreateForm';
import {toast} from "react-hot-toast";

const ToolsCreate = ({ darkMode, titleVisible, onPrevious }) => {
    // INITIAL DECLARATIONS
    const [formData, setFormData] = useState({ name: '', description: '', YAML: '', authorization: '', queryText: '' });
    const [validate, setValidate] = useState({ nameError: '', descriptionError: '', YAMLError: '', authorizationError: '', queryTextError: '' });
    const [validateCondition, setValidateCondition] = useState(false);
    const [toolsData, setToolsData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [runAll, setRunAll] = useState(false);
    const data = useSelector(state => state);
    const indexData = data && JSON.stringify(data, null, 2);
    const convertedData = JSON.parse(indexData);
    const navigate = useNavigate();
    const params = useParams();
    const type="tools";
    const [defaultTools, setDefaultTools] = useState([]);
    const [selectedTool, setSelectedTools] = useState();
    const [JsonData, setJsonData] = useState();
    const [reactFormJSON, setReactFormJSON] = useState(null);
    const [defaultValue, setDefaultValue] = useState();
    const [JsonDataError, setJsonDataError] = useState();
    // USER INFO
    const userDetails = JSON.parse(sessionStorage.getItem("userData"));
    const userID = userDetails?.user?.id;


    // TOOLS SINGLE DATA RENDERING
    useEffect(() => {
        if (params._id) {
            const url = `filters[space][Name][$eq]=${params._spaceid}&filters[id][$eq]=${params._id}&filters[Type][$eq]=${type}&[populate][data_product][on]`
            CommonCollectionFilterApi('collections', url)
                .then((data) => {
                    if (data.data.length === 0) {
                        setNotFound(true)
                    }
                    if (data?.error?.status === 404) {
                        setNotFound(true)
                        setLoader(false);
                    }
                    if (data.data.length > 0) {
                        setLoader(false);
                        setToolsData(data.data?.[0]);
                        setFormData({
                            name: data?.data?.[0]?.attributes?.Name,
                            description: data?.data?.[0]?.attributes?.Description,
                            YAML: data?.data?.[0]?.attributes?.YAML,
                        })
                        const defaultData=data?.data?.attributes?.data_product?.data===null?'Create a custom tool':data?.data?.attributes?.default_tool?.data?.id
                        setDefaultValue([defaultData])
                    }
                })
                .catch((error) => { toast.error("Oops!, something went wrong, please try after some time."); setLoader(false); })
        } else {
            setLoader(false);
        }
    }, [params._id])


    useEffect(() => {
        getAllData("default-tools")
            .then((data) => setDefaultTools(data.data))
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    useEffect(() => {
        if (defaultValue) {
            setSelectedTools([`${defaultValue?.[0]}`])
        } else {
            setSelectedTools('');
        }
        
    }, [defaultValue])


    useEffect(() => {
        const selctedItem=toolsData?selectedTool?.[0]:selectedTool
        const filteredData = defaultTools?.find(data => JSON.stringify(data.id) === selctedItem);
        if (filteredData) {
            setReactFormJSON(filteredData)
        } else {
            setReactFormJSON(false)
        }
    }, [selectedTool, defaultValue,toolsData])

    useEffect(() => {
        const initialValues = {};
        const Datarender = defaultTools?.find((data) => JSON.stringify(data.id) === (selectedTool?.[0]))
        Datarender?.attributes?.Connector_schema?.properties?.forEach((field) => {
            initialValues[field.name] = toolsData?.attributes?.Connector_schema?.properties[field.name]; // Set default value here (e.g., empty string or predefined value)
        });
        setJsonData(initialValues);
    }, [toolsData])


    const filteredJSONForm = defaultTools?.find((data) => JSON.stringify(data.id) === (toolsData?selectedTool?.[0]:selectedTool))
    // INITIAL VALUES SET STATE
    useEffect(() => {
        if(!toolsData){
        if (filteredJSONForm) {

            const initialValues = {};
            filteredJSONForm.attributes?.Connector_schema?.properties?.forEach((field) => {
                initialValues[field.name] = ''; // Set default value here (e.g., empty string or predefined value)
            });
            setJsonData(initialValues);
        }
    }
    }, [selectedTool]);


    // VALIDATING FORM
    const validateForm = () => {
        let valid = true;
        let errors = {}
        if (!formData.name) {
            valid = false;
            setValidate((prev) => ({ ...prev, nameError: "Name of tool is required." }))
        } else {
            setValidate((prev) => ({ ...prev, nameError: null }))
        }

        if (!formData.description) {
            valid = false;
            setValidate((prev) => ({ ...prev, descriptionError: "Context is required." }))
        } else {
            setValidate((prev) => ({ ...prev, descriptionError: null }))
        }

        if (!reactFormJSON && !formData.YAML) {
            valid = false;
            setValidate((prev) => ({ ...prev, YAMLError: "YAML is required." }))
        } else {
            setValidate((prev) => ({ ...prev, YAMLError: null }))
        }


        filteredJSONForm?.attributes?.Connector_schema?.properties?.forEach((data) => {
            if (!JsonData[data.name]) {
                valid = false;
                errors[data.name] = `${data.name} is required.`
            }
        })
        setJsonDataError(errors);
        return valid;
    }

    const defaultSelectData = () => {
        if (reactFormJSON) {
            return { disconnect: [], connect: [{ id: selectedTool, position: { end: true } }] }
        } else {
            return { disconnect: [], connect: [] };
        }
    }

    // TEST TOOL ONCLICK FUNCTION
    const submitTest = async () => {
        setLoader(true);

        const spaceId = convertedData?.space?.[0]?.id
        const payload = {
            Name: formData.name,
            Context: formData.description,
            YAML: formData.YAML,
            space: { disconnect: [], connect: [{ id: spaceId, position: { end: true } }] },
            author: { disconnect: [], connect: [{ id: userID, position: { end: true } }] },
            default_tool: defaultSelectData(),
            Connector_schema: { properties: JsonData }
        }

        const validData = validateForm();
        if (validData) {
            setValidateCondition(false);
            const response = params._id ? await UpdateData('tools', params._id, payload) : await CreateData("tools", payload)
            if (response) {
                setLoader(false);
                setFormData({ name: '', description: '', YAML: '' })
                navigate(`/${convertedData?.space?.[0]?.attributes?.Name}/tools`)
            }
        } else {
            setLoader(false);
            setValidateCondition(true);
        }
    }

    // QUERY RUN TIME FUNCTION
    const submitQuery = () => {
        setRunAll(!runAll);
    }


    // BUTTON DISABLED CONDITION FOR SINGLE TOOLS DATA
    const buttonDisabled = toolsData?.attributes?.Name === formData.name && toolsData?.attributes?.Description === formData.description && toolsData?.attributes?.YAML === formData.YAML

    const DispableDropdown = toolsData ? defaultTools?.map(data => JSON.stringify(data.id)) : '';
    if (notFound) {
        return <NotFound />
    }

    return (
        <div>
            {loader ? toolsSkeleton() : <>
                {titleVisible && <> <div className='text-3xl font-medium'>Create a new tools</div>
                    <div className='mt-8'>Add your applications to your overview</div></>}
                <div>
                    <Select
                        className="max-w-[50%] pr-4 pt-4"
                        label="Select tools"
                        labelPlacement='outside'
                        placeholder="Select a tool"
                        selectedKeys={selectedTool}
                        disabledKeys={DispableDropdown}
                        onChange={(e) => setSelectedTools(e.target.value)}
                    //   startContent={<Avatar src=''/>}
                    >
                        {defaultTools?.map((data) => (
                            <SelectItem key={data.id} value={data.id}>{data?.attributes?.Name}</SelectItem>
                        ))}
                        <SelectItem isDisabled={toolsData ? true : false} value={10} key="Create a custom tool" onClick={() => setSelectedTools(['Create a custom tool'])}>+ Create a custom tool</SelectItem>
                    </Select>
                </div>
                {selectedTool ?
                    <ToolsCreateForm
                        darkMode={darkMode}
                        formData={formData}
                        setFormData={setFormData}
                        validateCondition={validateCondition}
                        validate={validate}
                        titleVisible={titleVisible}
                        onPrevious={onPrevious}
                        buttonDisabled={buttonDisabled}
                        submitTest={submitTest}
                        submitQuery={submitQuery}
                        runAll={runAll}
                        reactFormJSON={reactFormJSON}
                        filteredJSONForm={filteredJSONForm}
                        setJsonData={setJsonData}
                        JsonData={JsonData}
                        JsonDataError={JsonDataError}
                        toolsData={toolsData}
                    /> : ''}
            </>}
        </div>
    )
}

export default ToolsCreate;