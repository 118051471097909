import React from 'react';
import { Button, Image, Input } from "@nextui-org/react"
import { useNavigate } from 'react-router-dom';


const Onboarding = () => {
    const navigate = useNavigate();

    const handleSubmit = () => {
        // Handle Update
        navigate('/')
    }
    const user_email = JSON.parse(sessionStorage.getItem("userData")).user.email;

    return (
        <div className="flex flex-col min-h-[590px] h-screen items-center justify-center">
            <main className='mx-auto  w-full max-w-[450px]'>
                <div className='flex items-center gap-4 '>
                    <Image
                        width={50}
                        alt="NextUI hero Image"
                        src="Assets/haya-logo.png"
                    />
                </div>
                <div className='mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold pb-8'>
                    Tell us about yourself
                </div>
                <div className='text-large'>
                    Enter your details for your team can spot you!
                </div>
                <div className='mt-16'>
                    <Input
                        isRequired
                        key="outside"
                        type="text"
                        label="Enter your first name"
                        labelPlacement="outside"
                        placeholder="First Name"
                    />
                </div>
                <div className='mt-16'>
                    <Input
                        isRequired
                        key="outside"
                        type="text"
                        label="Enter your last name"
                        labelPlacement="outside"
                        placeholder="Last Name"
                    />
                </div>
                <div className='mt-16 cursor-not-allowed'>
                    <Input
                        key="outside"
                        type="text"
                        label="Email"
                        labelPlacement="outside"
                        value={user_email && user_email}
                        isDisabled
                    />
                </div>
                <div className='mt-16'>
                    <Button color='danger' onClick={handleSubmit}>Next</Button>
                </div>
            </main>
            <div className=' pt-8 gap-8 mx-auto  w-full max-w-[900px]'>
            </div>
        </div>
    )
}

export default Onboarding