import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import { Avatar, Button, Divider, Image, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, useDisclosure, NextUIProvider, Chip } from '@nextui-org/react';
import Continue from '../Images/Continue';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import hayalogo from "../Images/haya-logo.png"
import { UpdateOneUserData, getOneData, CommonCollectionFilterApi } from '../controllers/strapiController';
import { getAllAppDocuments } from "../controllers/vectordbController";
import Tour from 'reactour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { NavbarItems, userDropDownItems } from "../components/NavbarItems";
import Loading from "../components/Loader/Loading";
import NotFound from "../components/NotFound";
import { TourSteps } from "../components/TourSteps";
import BreadcrumbsTabs from './BreadcrumbsTabs';
import { useDispatch, useSelector } from "react-redux";
import { documents, setData, spaceInfo } from '../redux/actions';
import Feedback from './Feedback';
import { colorPicker } from '../controllers/basicControllers';
import { toast } from "react-hot-toast";

const Navbar = ({ darkMode }) => {
  //STATE INITIALIZATION
  const [UserDetails, setUserDetails] = useState(null);
  const JWT_Testing = JSON.parse(sessionStorage.getItem("userData"));
  const email = JWT_Testing && JWT_Testing.user && JWT_Testing.user.email;
  const userId = JWT_Testing && JWT_Testing.user && JWT_Testing.user.id;
  const truncatedEmail = email && email.length > 17 ? `${email.slice(0, 17)}...` : email;
  const Location = useLocation();
  const Navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [documentsData, setDocuments] = useState();
  const URLPath = Location.pathname.split("/");
  const params = useParams();
  const [validate, setValidate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputText, setInputText] = useState('');
  const title = params._spaceid;
  const [reload, setReload] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(state => state);
  const indexData = data && JSON.stringify(data, null, 2);
  const convertedData = JSON.parse(indexData);
  const vectorName = convertedData?.space?.[0]?.attributes?.VerctorDBCollection
  const navbarItems = NavbarItems(darkMode, params);
  const TourListdata = TourSteps({ darkMode });
  const location = useLocation();

  // SPACE API CALL
  useEffect(() => {
    setLoading(true);
    if (JWT_Testing === null) {
      if (location.pathname !== '') {
        Navigate(`/sign-in/redirectTo?path=${location.pathname}`);
      } else {
        Navigate('/sign-in')
      }
    }

    const payload = { "Theme": darkMode.value === true ? "Dark" : "Light" }
    UpdateOneUserData("users", JWT_Testing && JWT_Testing.user.id, payload)

    const url = `filters[$or][0][admin][id][$eq]=${userId}&filters[$or][1][members][id][$eq]=${userId}&filters[Name][$in][1]=${title}&[populate][admin][on]`
    CommonCollectionFilterApi("spaces", url)
      .then((data) => { setLoading(false); dispatch(spaceInfo(data.data)); setValidate(data.data) })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))

    getAllAppDocuments(vectorName, params._spaceid)
      .then((data) => { setDocuments(data) })
      .catch((error) => error)
  }, [title, vectorName])


  // FILTERED DOCUMENTS
  useEffect(() => {
    const filterUniqueEntries = (array) => {
      const uniqueEntries = [];
      const uniqueIds = new Set();

      array?.forEach(item => {
        item?.forEach(value => {
          if (!uniqueIds.has(value.payload.file_document_id)) {
            uniqueIds.add(value.payload.file_document_id);
            uniqueEntries.push(value);
          }
        })
      });
      dispatch(documents(uniqueEntries))
    }
    filterUniqueEntries(documentsData)
  }, [documentsData])

  // USER API CALL
  useEffect(() => {
    getOneData("users", JWT_Testing && JWT_Testing.user.id)
      .then((data) => { setUserDetails(data); dispatch(setData(data)); })
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [reload])

  const spaceData = validate && validate[0];

  // LAST SPACE ACTIVE UPDATE
  const handleSpaces = async () => {
    if (UserDetails && UserDetails.spaces && UserDetails.spaces.length !== 1) {
      if (spaceData.attributes.SpaceName !== UserDetails.LastSpace) {
        const payload = {
          "LastSpace": spaceData && spaceData.attributes.SpaceName,
        }
        await UpdateOneUserData("users", JWT_Testing && JWT_Testing.user.id, payload);
      }
    }
    Navigate("/");
  }

  // STORING LAST SPACE NAME TO STRAPI FUNCTION
  const handleToLastSpace = async () => {
    const lowerCase = UserDetails && UserDetails.LastSpace.trim().toLowerCase();
    const replaceAll = lowerCase && lowerCase.replaceAll(' ', '-')

    if (UserDetails && UserDetails.LastSpace && spaceData && spaceData.attributes.SpaceName !== UserDetails.LastSpace) {
      const payload = {
        "LastSpace": spaceData && spaceData.attributes.SpaceName
      }
      const response = await UpdateOneUserData("users", JWT_Testing && JWT_Testing.user.id, payload);
      if (response) {
        Navigate(`/${replaceAll}`);
        setReload(!reload);
      }
    } else {
      Navigate(`/${replaceAll}`);
    }
  }

  // LOGOUT FUNCTION
  const logOutHandler = () => {
    sessionStorage.removeItem("userData");
    Navigate("/sign-in");
  }

  // NAVIAGTE TO USER
  const userNavigate = () => {
    Navigate("/profile")
  }

  // TOUR ONCHANGE HANDLER
  const tourHandler = () => {
    setIsTourOpen(true)
  }

  const handleChange = () => {
    onOpen();
    setInputText('')
  }

  const userItems = userDropDownItems(darkMode, userNavigate, tourHandler, handleChange, setIsTourOpen, logOutHandler);

  // FILTER CONDITION FOR USER AND TYPE
  const adminTesting = validate?.[0]?.attributes?.admin?.data?.id === userId;
  const filterAdmin = validate && validate[0] && validate[0] && validate[0].attributes.type === "Enterprise";

  // Filter tour steps based on conditions
  const filteredSteps = TourListdata?.filter(step => {
    if (!filterAdmin && step.selector === "#Teams") {
      return false;
    }
    if (!adminTesting && step.selector === "#members") {
      return false;
    }
    return true;
  });

  // NAVBAR ITEMS UI
  const navbarListItems = (data, Verified) => {
    return <li key={data?.title}>
      <NavLink className=' rounded-md' to={data.path} id={data.id}>
        <span className={`flex p-1 items-center gap-2 rounded-md px-2 text-sm hover:bg-grey-200 ${darkMode.value === false ? 'text-black hover:bg-grey-200 hover:text-foreground' : 'text-white bg-gray-800  hover:text-white'} ${Verified ? (darkMode.value === false ? 'text-foreground bg-grey-200' : 'text-foreground bg-grey-200') : ''}`}>
          {data.icon}
          {data.title}
        </span>
      </NavLink>
    </li>
  }

  // NOT FOUND PAGE RENDER
  if (!loading && validate?.length === 0) {
    return <NotFound />
  }

  // LOADER 
  if (loading) {
    return <div className={`absolute z-[100]  inset-0 h-screen w-screen ${darkMode.value === true ? 'bg-black' : 'bg-slate-50'}`}>
      <Loading darkMode={darkMode} />
    </div>
  }

  // COLOR FOR PROJECT TOUR BUTTON
  const accentColor = '#f31260';

  // RETURN UI
  return (
    <div className={`flex flex-row w-full`}>
      <Tour
        steps={filteredSteps}
        isOpen={isTourOpen}
        rounded={20}
        className='text-white'
        accentColor={accentColor}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <nav>
        <div className={`flex flex-col justify-between w-64 dark:bg-root h-screen flex-shrink-0 border-r border-divider bg-inherit px-4 dark:border-gray-600 md:flex`}>
          <div>
            <NextUIProvider>
              <Dropdown placement='bottom-start' className={`${darkMode.value === false ? 'text-black bg-white' : 'text-white'}`}>
                <DropdownTrigger>
                  <div className='flex items-center h-14 pt-4 gap-2 cursor-pointer'>
                    <Image
                      width={30}
                      alt="NextUI hero Image"
                      src={hayalogo} />
                    <a href className='font-bold'>Haya Knowledge</a>
                    <Chip size='sm' variant='flat' radius='sm'>Beta</Chip>
                  </div>
                </DropdownTrigger>
                <NextThemesProvider>
                  <DropdownMenu
                    aria-label="Single selection example"
                    variant="flat"
                    disallowEmptySelection
                    selectedKeys={[title]}>
                    <DropdownSection>
                      <DropdownItem isDisabled={true}>
                        <p className='cursor-default'>Switch to...</p>
                      </DropdownItem>
                      {UserDetails?.LastSpace && <DropdownItem variant='faded' onPress={handleToLastSpace}>
                        {UserDetails?.LastSpace}
                      </DropdownItem>}
                      <DropdownItem endContent={<FontAwesomeIcon icon={faCheck} />} isDisabled={true} key={title} variant='faded' className='mb-2'>
                        {spaceData?.attributes?.SpaceName}
                      </DropdownItem>
                    </DropdownSection>
                    <DropdownSection>
                      <DropdownItem key='button' className='px-0 py-0'>
                        <Button size='sm' className='bg-gray-dark text-slate-50 w-full' onClick={handleSpaces}>
                          View all applications
                        </Button>
                      </DropdownItem>
                    </DropdownSection>
                  </DropdownMenu>
                </NextThemesProvider>
              </Dropdown>
            </NextUIProvider>
            <nav className='mt-4 flex-1'>
              <ul className='flex flex-col gap-1'>
                {navbarItems && navbarItems.nav1.map((data, index) => {
                  const Verified = `/${URLPath[1]}/${URLPath[2]}` === data.path;
                  return navbarListItems(data, Verified)
                })}
              </ul>
            </nav>
          </div>
          <Divider className='mt-4' />
          <nav className='mt-4 flex-1'>
            <ul className='flex flex-col gap-1'>
              {navbarItems?.nav2?.map((data, index) => {
                const Verified = `/${URLPath[1]}/${URLPath[2]}` === data.path;
                return navbarListItems(data, Verified)
              })}
            </ul>
          </nav>
          <Divider className='mt-4' />
          <nav className='mt-4 mb-4 flex-1'>
            <ul className='flex flex-col gap-1'>
              {navbarItems?.nav3?.map((data, index) => {
                const Verified = `/${URLPath[1]}/${URLPath[2]}` === data.path;
                const adminTesting = validate?.[0]?.attributes?.admin?.data?.id === userId;
                const filterSpace = URLPath[1] === "haya";
                if (data.title === "Members" && adminTesting) {
                  return navbarListItems(data, Verified)
                }
                if (data.title === "Templates" && filterSpace) {
                  return navbarListItems(data, Verified);
                }
                if (data.title === "Playground" && filterSpace) {
                  return navbarListItems(data, Verified);
                }
                if (data.title !== "Members" && data.title !== "Templates" && data.title !== "Playground") {
                  return navbarListItems(data, Verified)
                }

                return null;
              })}
            </ul>
          </nav>
          {/* ..... */}
          <Dropdown
            // showArrow
            radius="sm"
            size='lg'
            classNames={{
              base: "before:bg-default-200", // change arrow background
              content: "p-0 border-small border-divider bg-background",
            }}
          >
            <DropdownTrigger>
              <div className='flex items-center pb-4 justify-evenly cursor-pointer'>
                <Avatar className='h-6 w-6' color={UserDetails && colorPicker(UserDetails.id)}
                  name={convertedData?.data?.email?.toUpperCase().slice(0, 1)}
                  src={convertedData?.data?.Picture !== null && `${process.env.REACT_APP_STAGE === "dev" ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${convertedData?.data?.Picture?.url}` : convertedData?.data?.Picture?.url}`} />
                <span className='text-xs font-bold'>{truncatedEmail}</span>
                <Continue />
              </div>
            </DropdownTrigger>
            <NextThemesProvider>
              <DropdownMenu
                aria-label="Custom item styles"
                className="p-2 -mb-1"
                itemClasses={{
                  base: [
                    "rounded-md",
                    "text-default-500",
                    "transition-opacity",
                    "data-[hover=true]:text-foreground",
                    "data-[hover=true]:bg-default-100",
                    "dark:data-[hover=true]:bg-default-50",
                    "data-[selectable=true]:focus:bg-default-50",
                    "data-[pressed=true]:opacity-70",
                    "data-[focus-visible=true]:ring-default-500",
                  ],
                }}>
                <DropdownSection aria-label="Profile & Actions">
                  {userItems && userItems.map((items) => {
                    return <DropdownItem
                      id={items.id}
                      onClick={items.Click}
                      onPress={() => items.Click}
                      startContent={items.icon}>
                      <span className='font-bold' >{items.title}</span>
                    </DropdownItem>
                  })}
                </DropdownSection>
              </DropdownMenu>
            </NextThemesProvider>
          </Dropdown>
        </div>
      </nav>
      <div className='w-full flex flex-col'>
        <Header darkMode={darkMode} params={params} />
        <div className='h-[calc(100vh-60px)] overflow-auto '>
          <div className={`flex flex-col gap-2  mx-auto ${URLPath[2] === "teams" ? " " : "max-w-6xl py-8 px-4"}`}>
            {URLPath[2] === "teams" ? "" : <BreadcrumbsTabs />}
            <Outlet />
          </div>
        </div>
      </div>
      <NextThemesProvider>
        <Feedback
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          inputText={inputText}
          setInputText={setInputText}
        />
      </NextThemesProvider>
    </div>
  )
}

export default Navbar;