export const createDataproduct = async (formData,convertedData,userId,finalJson) => {
    const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/dataproduct/create`
    const response = await fetch(URL, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({formData,convertedData,userId,finalJson}),
        redirect: "follow",
        referrerPolicy: "no-referrer",
    })
    let response_data = response.json();
    return response_data;
}


export const dataProductList = async (template,payload) => {
    const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/dataproduct/list`
    const response = await fetch(URL, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        redirect: "follow",
        referrerPolicy: "no-referrer",
    })
    let data = response.json();
    return data;
}