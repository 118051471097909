import React, { useState, useEffect } from 'react';
import { Table, TableHeader, TableColumn, TableBody, TableCell, TableRow, Dropdown, Avatar, DropdownTrigger, DropdownMenu, DropdownItem, useDisclosure } from '@nextui-org/react';
import { CommonCollectionFilterApi, DeleteSingleAttribute, userLoginDetails } from "../../controllers/strapiController";
import { StringLimit, titleCreater } from "../../controllers/basicControllers";
import { useParams, useNavigate } from "react-router-dom";
import { tableSkeleton } from "../../components/Skeleton";
import DeleteModal from "../../components/DeleteModal";
import EmptyArray from '../../components/EmptyArray';
import { toast } from "react-hot-toast";
import NotFound from "../../components/NotFound";
import ServerError from "../../components/ServerIssue";
import UnAuthorized from "../../components/UnAuthorized";
import Forbbiden from "../../components/Forbbiden";
import Paginate from "../../components/Pagination";

const ToolsTable = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [tools, setTools] = useState([]);
    const [loader, setLoader] = useState(true);
    const [reload, setReloader] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const params = useParams();
    const navigate = useNavigate();
    const type = "tools";
    const userID = userLoginDetails()?.userId;
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();

    useEffect(() => {
        const url = `filters[space][Name][$eq]=${params._spaceid}&filters[Type][$eq]=${type}&[populate][data_product][on]`
        CommonCollectionFilterApi('collections', url)
            .then((data) => {
                if (data?.error) {
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        toast.error("Something went wrong with collections, please try after some time.")
                    }
                }
                else if (data?.data?.length > 0) { setTools(data.data); setLoader(false) }
                else { setLoader(false) }
            })
            .catch((error) => {
                setTools([]); setLoader(false)
                toast.error("Something went wrong with collections, please try after some time.")
            })
    }, [reload])

    // SORTING THE DATA ACCORDING TO DATE
    const sortedData = tools && tools.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt));
    const recordsPerPage = 10;

    // Calculate indexes for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = sortedData && sortedData.slice(indexOfFirstRecord, indexOfLastRecord);


    // EDIT PAGE NAVIGATE
    const EditOpen = (data) => {
        navigate(`${data.id}`)
    }

    // DELETE MODAL OPEN
    const DeleteWarningHandle = (data) => {
        onDeleteOpen();
        setSelectedItem(data);
    }

    // DELETE TOOLS HANDLER
    const DeleteHandler = async () => {
        const response = await DeleteSingleAttribute('collections', selectedItem.id);
        if (response) {
            setReloader(response);
        }
    }

    // OTHER ERROR PAGES
    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerError />;
    if (authorized) return <UnAuthorized message='Authorization error.' />;
    if (forbidden) return <Forbbiden />;


    // RETURN UI
    return (
        <div>
            {loader ? tableSkeleton() : <>
                {sortedData?.length > 0 ?
                    <Table removeWrapper aria-label="Example static collection table">
                        <TableHeader>
                            <TableColumn>Name</TableColumn>
                            <TableColumn>Context</TableColumn>
                            <TableColumn></TableColumn>
                        </TableHeader>
                        <TableBody>
                            {currentRecords && currentRecords.map((data) => {
                                return <TableRow key={data?.is} className='cursor-pointer hover:bg-grey-200' onClick={()=>navigate(`${data.id}`)}>
                                    <TableCell>
                                        <div className='flex gap-4 items-center'>
                                            <Avatar size='sm' src={data?.attributes?.data_product?.data?.attributes?.Image } />
                                            <div>
                                                <div className='font-semibold'>
                                                    {titleCreater(data?.attributes?.Name)}
                                                </div>
                                                <div className='text-xs text-gray'>
                                                    {data?.attributes?.data_product?.data !== null ? data?.attributes?.data_product?.data?.attributes?.Name : '--'}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {StringLimit(data?.attributes?.Description, 150)}
                                    </TableCell>
                                    <TableCell>
                                        <Dropdown placement="bottom-end">
                                            <DropdownTrigger>
                                                <div className='flex items-center justify-evenly cursor-pointer'>
                                                    <span className='text-md font-bold text-slate-500'>...</span>
                                                </div>
                                            </DropdownTrigger>
                                            <DropdownMenu aria-label="Profile Actions" variant="flat">
                                                <DropdownItem key="edit" onClick={() => EditOpen(data)}>
                                                    <div className='flex items-center gap-2'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/pflszboa.json"
                                                            colors={`primary:${darkMode.value === true ? "#ffffff" : "#000000"}`}
                                                            style={{ width: "16px", height: "16px" }}>
                                                        </lord-icon>
                                                        Edit
                                                    </div>
                                                </DropdownItem>
                                                <DropdownItem onClick={() => DeleteWarningHandle(data)} key="configurations" className='text-danger' color="danger">
                                                    <div className='flex items-center gap-2'>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/wpyrrmcq.json"
                                                            colors="primary:#e1426c"
                                                            style={{ width: "16px", height: "16px" }}>
                                                        </lord-icon>
                                                        Delete
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                    : <EmptyArray message={`No Tools created yet. Click on "Create new tools" to create your Tool.`} />
                }
                {sortedData.length > 10 && <div className='flex justify-center pt-8'>
                    <Paginate
                        totalPage={sortedData}
                        initialPage={"1"}
                        id={userID}
                        currentRecords={currentRecords}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        recordsPerPage={recordsPerPage}
                    />
                </div>
                }
            </>}
            <DeleteModal
                isOpen={isDeleteOpen}
                onOpenChange={onDeleteOpenChange}
                deleteData={""}
                setDeleteData={""}
                Collectionname={"tools"}
                setLoader={setLoader}
                DeleteHandler={DeleteHandler}
            />
        </div>
    )
}

export default ToolsTable;