export const ErrorHandler = (error, setErrorHandlers) => {
    const { setNotFound, setForbidden, setServerIssue, setAuthorized } = setErrorHandlers;

    switch (error?.status) {
        case 404:
            setNotFound(true);
            break;
        case 403:
            setForbidden(true);
            break;
        case 500:
            setServerIssue(true);
            break;
        case 401:
            setAuthorized(true);
            break;
        default:
            console.error('Unhandled API error:', error);
            break;
    }
};
