import React from 'react';
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import { InfoIcon } from '../../components/InfoIcon';

const UserFeedback = ({darkMode}) => {
// ANALYTIC CARDS TITLE RENDERING
 const titleRendering = (darkMode,title, message) => {
    return <div className='flex flex-row gap-2 items-center'>
        <p>{title}</p>
        <InfoIcon darkMode={darkMode}
            message={message}
            placement={"right"}
            size={"sm"} />
    </div>
  }

  return (
    <div className='flex flex-col gap-2'>
            <h1 className='pt-8 pb-4 text-xl'>User Feedback</h1>
            <div className='flex flex-row gap-4 w-full'>
                <Card className='w-full border border-divider' shadow='none'>
                    <CardHeader className=' pb-0'>
                        {titleRendering(darkMode,"Total Upvotes", 'Use variables in a form to automatically replace the variables in the prompt')}
                    </CardHeader>
                    <CardBody className='text-xl pt-0 pb-2'>0</CardBody>
                </Card>
                <Card className='w-full border border-divider' shadow='none'>
                    <CardHeader className='pb-0'>
                        {titleRendering(darkMode,"Total Downvotes", 'Use variables in a form to automatically replace the variables in the prompt')}
                    </CardHeader>
                    <CardBody className='text-xl pt-0 pb-2'>0</CardBody>
                </Card>
            </div>
            <div>
                <Card className='w-full h-[400px] mt-2 border border-divider' shadow='none'>
                    <CardHeader className='pb-0'>
                        <div className='flex fle-row justify-between w-full'>
                            {titleRendering(darkMode,"Upvotes vs Downvotes", 'Use variables in a form to automatically replace the variables in the prompt')}
                        </div>
                    </CardHeader>
                    <CardBody></CardBody>
                </Card>
            </div>
        </div>
  )
}

export default UserFeedback;