import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Image, Button, Input } from "@nextui-org/react";
import hayalogo from "../../Images/haya-logo.png";
import { EyeSlashFilledIcon } from '../../Images/EyeSlashFilledIcon';
import { EyeFilledIcon } from '../../Images/EyeFilledIcon';
import LinkExpired from '../../components/LinkExpired';
import { CommonCollectionFilterApi, UpdateOneUserData } from "../../controllers/strapiController";
import { decriptedText } from '../../controllers/basicControllers';
import NotFound from "../../components/NotFound";
import CryptoJS from "crypto-js";
import {toast} from "react-hot-toast";

const PasswordReset = ({ darkMode }) => {
  // STATE INITIALIZATION
  const [isVisible, setIsVisible] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [limit, setLimit] = useState('');
  const [validationError, setValidationError] = useState(false);
  const [userData, setUserData] = useState();
  var PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const [passwordError, setPasswordError] = useState('');
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const navigate = useNavigate();

  const toggleVisibility = () => setIsVisible(!isVisible);

  useEffect(() => {
    const emailParam = searchParams.get('email');
    const limitParam = searchParams.get('limit');
    setEmail(emailParam);
    setLimit(limitParam);
  }, [searchParams]);

  const emaildata = decriptedText(email);

  // API RENDERING
  useEffect(() => {
    const url = `filters[email][$eqi]=${emaildata}`
    CommonCollectionFilterApi("users", url)
      .then((data) => setUserData(data))
      .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
  }, [email])


  // VALIDATION FORM
  const Validation = () => {
    var isValid = true;

    if (password !== "" && !PasswordRegex.test(password)) {
      isValid = false;
      setPasswordError("Enter a strong password.");
    } else {
      setPasswordError(null);
    }
    return isValid;
  }

  // PASSWORD CHANGE FUNCTION
  const HandlePasswordChange = async () => {
    const Validate = Validation()
    const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
    const inputString = password;
    const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
    const AESHash = encrypted.toString();
    const payload = {
      Password2: btoa(AESHash)
    }

    if (Validate) {
      setValidationError(false);
      const response = await UpdateOneUserData("users", userData?.[0]?.id, payload);
      if (response) {
        navigate("/sign-in")
      }
    } else {
      setValidationError(true);
    }
  }

  // LINK EXPIRED CONDITION
  if (currentTimestamp > limit) {
    return <LinkExpired darkMode={darkMode} email={email} />
  }

  // NOT FOUND CONDITION
  if (!emaildata) {
    return <NotFound />
  }

  // UI RENDERING
  return (
    <div className={`flex h-screen items-center justify-center ${darkMode.value === true ? 'bg-black' : 'bg-slate-50'}`}>
      <main className='mx-auto min-h-[590px] flex flex-col justify-center w-full max-w-[450px]'>
        <Image width={50} alt="NextUI hero Image" src={hayalogo} />
        <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
          Reset Password
        </h1>
        <p className='pb-8 mt-8 sm:text-left text-base text-slate-11 font-normal'>
          Include the email address associated with your account and we’ll send you an email with instructions to reset your password.
        </p>
        <form data-gtm-form-interact-id="0">
          <div className='mb-4 space-y-2'>
            <Input
              radius="sm"
              className='py-4'
              variant={"faded"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={password !== "" && (!PasswordRegex.test(password) || validationError) ? !PasswordRegex.test(password) || validationError : ""}
              errorMessage={validationError && passwordError}
              label="Password"
              labelPlacement={"outside"}
              placeholder="Enter your password"
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  ) : (
                    <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
            />
          </div>
          <div className='mb-4 space-y-2'>
          </div>
          <div className='pt-8'>
            <Button id='CreateAccount' onClick={HandlePasswordChange} isDisabled={email === ""} radius="sm" className={`w-full gap-1 lt-500 ${darkMode.value === true ? 'text-black hover:text-transporent bg-foreground' : 'text-white bg-black hover:bg-gray-700 hover:text-white'}`}>
              reset password
              <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#CreateAccount"
                colors={`primary:${darkMode.value === true ? "#000000" : "#ffffff"}`}
                style={{
                  width: "18px",
                  height: "20px"
                }}/>
            </Button>
          </div>
        </form>
      </main >
    </div >
  )
}

export default PasswordReset;