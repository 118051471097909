import React, { useState, useEffect } from 'react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { Card, NextUIProvider, CardBody, Select, SelectItem, Button, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UpdateData, getOneData, DeleteSingleAttribute } from "../../controllers/strapiController";
import { useSelector } from "react-redux";
import ModalPopup from "../Collections/ModalPopup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { spaceInfo } from "../../redux/actions";
import pluralize from "pluralize";
import DeleteModal from "../../components/DeleteModal";
import { HayaSettingSkeleton } from '../../components/Skeleton';
import { toast } from "react-hot-toast";

const HayaSettings = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [spaceData, setSpaceData] = useState();
    const [value, setValue] = React.useState();
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isOpen: isTrashOpen, onOpen: onTrashOpen, onOpenChange: onOpenTrashChange } = useDisclosure();
    const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onOpenDeleteChange } = useDisclosure();
    const data = useSelector(state => state);
    const indexData = data && JSON.stringify(data, null, 2);
    const convertedData = JSON.parse(indexData);
    const spaceId = convertedData?.space?.[0];
    const documentsCount = convertedData?.documents?.length;

    // API RENDER
    useEffect(() => {
        setLoader(true);
        getOneData("spaces", spaceId.id)
            .then((data) => { setLoader(false); setSpaceData(data.data) })
            .catch((error) => toast.error("Oops!, something went wrong, please try after some time."))
    }, [])

    //DATA PRODUCT COUNT
    const productCount = () => {
        const collections = spaceData?.attributes?.collections?.data?.length || 0;
        const connectors = spaceData?.attributes?.connectors?.data?.length || 0;
        const tools = spaceData?.attributes?.tools?.data?.length || 0;

        return collections + connectors + tools
    }

    // THEME CHANGE FUNCTION
    const HandleThemeChange = (e) => {
        setValue(e.target.value);
        onTrashOpen();
    }

    // TYPE OF SPACE CHANGE FUNCTION
    const typeUpdateHandler = async (onClose) => {
        const payload = {
            type: value
        }

        if (value === 'shared') {
            payload.VerctorDBCollection = 'pdf'
        } else {
            payload.VerctorDBCollection = 'spaceId.id'
        }

        const response = await UpdateData("spaces", spaceId.id, payload)
        if (response) {
            dispatch(spaceInfo([response.data]));
            onClose();
        }
    }

    // lIST OF RELATION WITH SPACE
    const UserWithRelations = (userData) => {
        const RelationType = [];
        for (const key in userData) {

            if (key === "collections" || key === "teams") {
                RelationType.push({ [key]: userData[key].data })
            }
            if (Array.isArray(userData[key])) {
                if (key === "collections" || key === "teams") {
                    RelationType.push({ [key]: userData[key].data })
                }
            }
        }
        return RelationType;
    }

    // ASSIGNING SPACE RELATIONS DATA
    const RelationData = spaceData && UserWithRelations(spaceData.attributes);

    // FUNCTION TO DELETE SPACE
    const deleteSpaceHandler = async () => {
        setLoader(true);
        try {
            const promises = RelationData?.map(async (data) => {
                for (const key in data) {
                    if (Array.isArray(data[key])) {
                        return Promise.all(data[key]?.map(async (newdata) => {
                            const PluralizeData = pluralize.plural(key)
                            await DeleteSingleAttribute(PluralizeData, newdata.id);
                        }));
                    }
                }
            });

            await Promise.all(promises);

            const response = await DeleteSingleAttribute("spaces", spaceId.id);
            if (response) {
                setTimeout(() => {
                    setLoader(false);
                    navigate("/");
                }, 500)
            }
        } catch (error) {
            console.error("Error occurred:", error);
            setLoader(false);
        }
    };

    // MODAL TO CHANGE TYPE OF SPACE
    const ModalBodyData = () => {
        return <>
            <ModalHeader className="flex flex-col gap-1">Space type</ModalHeader>
            <ModalBody className='gap-0'>
                Are you sure you want to change the space type?
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            <ModalFooter >
                <Button className='text-bold' color="danger" onClick={() => typeUpdateHandler(onClose)}>
                    Yes
                </Button>
                <Button onClick={() => { onClose() }} variant="light" >
                    No
                </Button>
            </ModalFooter>
        </>
    }

    // RETURN UI
    return (
        <div>
            <NextThemesProvider defaultTheme={darkMode.value === true ? 'dark' : 'light'}>
                <div className='max-w-2xl m-auto flex flex-col gap-6 px-4'>
                    {loader ? HayaSettingSkeleton() : <>
                        <NextUIProvider>
                            <Card className="shadow-none border border-divider rounded-2xl p-4">
                                <CardBody>
                                    <div className='flex flex-row w-full text-base font-normal'>
                                        <p className='w-1/3 text-slate-400'>Space Name</p>
                                        <p className='w-full '>{spaceData?.attributes?.SpaceName}</p>
                                    </div>
                                    <div className='flex flex-row w-full  text-base pt-3 font-normal'>
                                        <p className='w-1/3 text-slate-400'>Total Data Products </p>
                                        <p className='w-full'>{productCount() > 0 ? productCount() > 10 ? productCount() : "0" + productCount() : "00"}</p>
                                    </div>
                                    <div className='flex flex-row w-full  text-base pt-3 font-normal'>
                                        <p className='w-1/3 text-slate-400'>Total Documents</p>
                                        <p className='w-full'>{documentsCount > 0 ? documentsCount > 10 ? documentsCount : "0" + documentsCount : "00"}</p>
                                    </div>
                                    {spaceData?.attributes?.teams?.data?.length > 0 && <div className='flex flex-row w-full  text-base pt-3 font-normal'>
                                        <p className='w-1/3 text-slate-400'>Total Teams</p>
                                        <p className='w-full'>{spaceData?.attributes?.teams?.data?.length < 10 ? 0 + `${spaceData?.attributes?.teams?.data?.length}` : spaceData?.attributes?.teams?.data?.length}</p>
                                    </div>}
                                </CardBody>
                            </Card>
                        </NextUIProvider>
                        <NextUIProvider>
                            <Card className="shadow-none border border-divider rounded-2xl p-4">
                                <CardBody>
                                    <p className='text-lg font-normal'>Change your space type</p>
                                    <p className='text-sm pt-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, exercitationem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis, exercitationem.</p>
                                    {spaceData && <Select
                                        placeholder="Choose theme"
                                        className='mt-8'
                                        classNames={{
                                            base: "max-w-44"
                                        }}
                                        defaultSelectedKeys={[spaceData?.attributes?.type]}
                                        onChange={(e) => HandleThemeChange(e)}
                                    >
                                        <SelectItem className='cursor-not-allowed' isDisabled key="Shared" value="Shared" startContent={<FontAwesomeIcon icon="fa-solid fa-sun" />}>Shared</SelectItem>
                                        <SelectItem isDisabled key="Dedicated" value="Dedicated" startContent={<FontAwesomeIcon icon="fa-solid fa-sun" />}>Dedicated</SelectItem>
                                        <SelectItem isDisabled key="Enterprise" value="Enterprise" startContent={<FontAwesomeIcon icon="fa-solid fa-sun" />}>Enterprise</SelectItem>
                                    </Select>}
                                </CardBody>
                            </Card>
                        </NextUIProvider>
                        <NextUIProvider>
                            <Card className="shadow-none border border-danger rounded-2xl p-4">
                                <CardBody>
                                    <p className='text-lg font-normal'>Delete your space</p>
                                    <p className='text-sm pt-4'>This will permanently remove your account you will lose access to all your data inside your space.</p>
                                    <div className='pt-4'>
                                        <Button color='danger' onClick={onDeleteOpen}>Delete space</Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </NextUIProvider>
                    </>}
                </div>
            </NextThemesProvider>
            <ModalPopup
                size="xl"
                isOpen={isTrashOpen}
                onOpenChange={onOpenTrashChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
            />
            <DeleteModal
                isOpen={isDeleteOpen}
                onOpenChange={onOpenDeleteChange}
                deleteData={""}
                setDeleteData={""}
                Collectionname={"space"}
                setLoader={setLoader}
                DeleteHandler={deleteSpaceHandler} />
        </div>
    )
}

export default HayaSettings;