import React, { useState, useRef, useEffect } from 'react';
import { uploadFile, UpdateData } from "../../controllers/strapiController";
import { Button, Input, Textarea, Avatar, CircularProgress } from "@nextui-org/react";
import {toast} from "react-hot-toast";

const EditTeams = ({ onOpenChange, isOpen, selectedTeam, setLoader, setResponse }) => {
    const [fileUploaded, setFileUploaded] = useState();
    const [imageLoader, setImageLoader] = useState(false);
    const [FileUpload, setFileUpload] = useState(null);
    const [validationCondition, setValidationCondition] = useState(false);
    const fileInputRef = useRef();
    const [formData, setFormData] = useState({Name: '',Description: '',Picture: ''});
    const [formError, setFormError] = useState({NameError: '',DescriptionError: '',});

    const UserProfile = selectedTeam?.attributes?.Logo?.data !== null ? `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${selectedTeam?.attributes?.Logo?.data?.attributes?.url}` : `${selectedTeam?.attributes?.Logo?.data?.attributes?.url}`}` : "";
    const UploadedUser = fileUploaded && fileUploaded !== null && `${process.env.REACT_APP_STAGE === 'dev' ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${fileUploaded.url}` : `${fileUploaded?.url}`}`;

    useEffect(() => {
        setFormData({
            Name: selectedTeam && selectedTeam.attributes.Name,
            Description: selectedTeam && selectedTeam.attributes.Description,
            Picture: selectedTeam && UserProfile
        })
    }, [selectedTeam])

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = event.target.files;

        if (files && files[0]) {
            const file = files[0];
            setFileUpload(file);
            //   setSelectedFile(file.name);
        } else {
            setFileUpload(null);
            //   setSelectedFile(null);
        }
    }

    const Handleremove = async () => {
        setFileUploaded(null)
        setFormData({ ...formData, Picture: "" })
    }


    const UploadFileHandler = async () => {
        // setLoader(true);
        setImageLoader(true)
        let formData = new FormData();
        formData.append("files", FileUpload)
        const response = await uploadFile(formData)
        if (response) {
            setImageLoader(false);
            const URLresponse = response && response[0];
            URLresponse && setFileUploaded(URLresponse);
            setFormData((previous) => ({ ...previous, Picture: URLresponse }))
        }
    }

    useEffect(() => {
        UploadFileHandler();
    }, [FileUpload])

    const Validation = () => {
        let IsValid = true;

        if (!formData.Name) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, NameError: "Name is required." }));
        } else {
            setFormError((previous) => ({ ...previous, NameError: null }))
        }

        if (!formData.Description) {
            IsValid = false;
            setFormError((previous) => ({ ...previous, DescriptionError: "Description is required." }))
        } else {
            setFormError((previous) => ({ ...previous, DescriptionError: null }))
        }
        return IsValid;
    }

    const UpdateProfile = async () => {
        const Validate = Validation();
        if (Validate) {
            const payload = {
                Name: formData.Name,
                Description: formData.Description,
                Logo: fileUploaded,
            }
            setValidationCondition(false);
            const response = selectedTeam && await UpdateData("teams", selectedTeam.id, payload);
            if (response) {
                setResponse(response);
                toast.success("Teams updated successfully.");
            }
        } else {
            setValidationCondition(true)
        }
    }

    const cancelHandler =()=>{
        setFormData({
            Name: selectedTeam && selectedTeam.attributes.Name,
            Description: selectedTeam && selectedTeam.attributes.Description,
            Picture: selectedTeam && UserProfile
        })
    }

    return (
        <div>
            <h1 className='text-lg font-medium pt-4 pb-4'>Edit your Team</h1>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo saepe, delectus consectetur nisi impedit dolore exercitationem pariatur odit perspiciatis consequuntur.</p>
            <div className='flex flex-col gap-4 pt-8'>
                <div className='flex flex-row items-center gap-6'>
                    <Avatar radius="full" size='lg' src={UploadedUser ? UploadedUser : formData.Picture} name={selectedTeam && selectedTeam.attributes.Name !== null && selectedTeam && selectedTeam.attributes.Name && selectedTeam.attributes.Name.slice(0, 1).toUpperCase()} />
                    <input ref={fileInputRef} type='file' onChange={(event) => handleFileChange(event)} className='hidden' />
                    <Button onClick={handleButtonClick} size='sm' variant='bordered' >Select profile image</Button>
                    {formData.Picture ? <>{imageLoader ? <CircularProgress size="sm" aria-label="Loading..." /> : <p className='text-xs cursor-pointer' onClick={Handleremove}>Remove</p>}</> : ""}
                </div>
                <Input
                    isRequired
                    key="outside"
                    type="text"
                    value={formData && formData.Name}
                    onChange={(e) => setFormData((previous) => ({ ...previous, Name: e.target.value }))}
                    isInvalid={!formData.Name && validationCondition ? !formData.Name || validationCondition : ''}
                    errorMessage={!formData.Name && validationCondition ? formError.NameError : ""}
                    label="Collection Name"
                    labelPlacement={"outside"}
                    placeholder="Enter your collection folder name"
                />
                <Textarea
                    className="pt-4"
                    key={"outside"}
                    isRequired
                    type="text"
                    value={formData && formData.Description}
                    onChange={(e) => setFormData((previous) => ({ ...previous, Description: e.target.value }))}
                    label="Decription"
                    labelPlacement={"outside"}
                    placeholder="Enter your collection's Description"
                />
            </div>
            <div className='flex flex-row justify-end pt-8'>
                <Button color="none" onClick={cancelHandler}>Cancel</Button>
                <Button color="secondary" onClick={() => UpdateProfile()}>Update</Button>
            </div>
        </div>
    )
}

export default EditTeams;