import React, { useEffect, useState } from "react";
import { Button, Divider, Image, Input, Link } from "@nextui-org/react";
import Google from "../../Images/Google.jsx";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";

import {
  encryptedText,
  generateOtp,
} from "../../controllers/basicControllers.js";
import {
  CommonCollectionFilterApi,
  sendCreateAccountOTP,
} from "../../controllers/strapiController.js";

import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../../components/Loader/Loading.jsx";
import { GoogleAuth } from "../../controllers/social.js";
import CryptoJS from "crypto-js";
import toast, { Toaster } from "react-hot-toast";

const SignUp = ({ darkMode }) => {
  console.log(darkMode);

  const navigate = useNavigate();

  const handleGoogleAuth = async (e) => {
    e.stopPropagation();
    const data = await GoogleAuth();
    console.log("url", data.urlWithQueryParams);
    window.location.href = data.urlWithQueryParams;
  };

  const handlePasswordlessAuth = async (e) => {
    e.stopPropagation();
    navigate("/passwordless-sign");
  };

  const [isVisible, setIsVisible] = React.useState(false);
  const [Users, setUsers] = useState(null);
  const [ValidationConditions, setValidationConditions] = useState(false);

  const [Loader, setLoader] = useState(false);
  const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const PassWordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const Location = useLocation();
  const [LoginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const [LoginError, setLoginError] = useState({
    emailError: "",
    passwordError: "",
    FinalError: "",
  });

  const navigate_forgot = () => {
    navigate("/auth/reset-password");
  };

  const EmailExist =
    Users &&
    Users?.filter(
      (data) => data?.email === LoginDetails.email?.toLowerCase()?.trim()
    );

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100) + 1;
  };

  const [randomNumber, setRandomNumber] = useState(generateRandomNumber());

  const secretKey = "";

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
  };

  const Validation = () => {
    let IsValid = true;

    if (LoginDetails.email === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Email is required.",
      }));
    } else if (!EmailRegex.test(LoginDetails.email)) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Enter valid email format.",
      }));
    } else if (EmailExist && EmailExist.length > 0) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        emailError: "Email already exists, log in to your account",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, emailError: "" }));
    }

    if (LoginDetails.password === "") {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError: "Password is required.",
      }));
      console.log("Hello1");
    } else if (
      !PassWordRegex.test(LoginDetails.password) ||
      LoginDetails?.password?.trim()?.length < 8 ||
      LoginDetails?.password?.trim()?.length > 16
    ) {
      IsValid = false;
      setLoginError((previous) => ({
        ...previous,
        passwordError:
          "Password should contain lower-case, upper-case, digit, Special Character ,have minimum length 8 and maximum length 16.",
      }));
    } else {
      setLoginError((previous) => ({ ...previous, passwordError: null }));
    }

    return IsValid;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      SubmitHandler();
    }
  };

  const SubmitHandler = async () => {
    setLoader(true);
    const Validate = Validation();
    console.log(Validate);

    if (Validate) {
      try {
        setValidationConditions(false);
        const otpString = generateOtp();
        const limit = Date.now() + 5 * 60 * 1000;
        console.log(limit.toString());

        const encryptOtp = encryptedText(otpString);
        const encryptedEmail = encryptedText(LoginDetails.email?.trim());
        const encryptedPassword = encryptedText(LoginDetails.password?.trim());

        const encryptedLimit = encryptedText(limit.toString());
        const url = `${process.env.REACT_APP_IP_ADDRESS}/auth/verify-otp?email=${encryptedEmail}&limit=${encryptedLimit}&password=${encryptedPassword}`;

        const res = await sendCreateAccountOTP({
          to: LoginDetails.email?.trim(),
          url,
          otp: otpString,
        });

        localStorage.setItem("user-otp", encryptOtp);

        toast.success(
          "Email sent successfully, Check your email to verify the OTP!"
        );

        navigate(
          `/auth/verify-otp?email=${encryptedEmail}&limit=${encryptedLimit}&password=${encryptedPassword}`
        );

        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setValidationConditions(true);
      setLoader(false);
    }
  };

  // USER API
  useEffect(() => {
    const userEmail = LoginDetails.email.toLowerCase().trim();
    const url = `filters[email][$eq]=${userEmail}`;
    CommonCollectionFilterApi("users", url)
      .then((data) => {
        setLoader(false);
        if (data.error) {
        } else {
          setUsers(data);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
    if (Location.pathname === "/sign-in") {
      sessionStorage.removeItem("userData");
    }
  }, [LoginDetails.email]);

  const toggleVisibility = () => setIsVisible(!isVisible);

  console.log(Users);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      {Loader ? (
        <div
          className={`absolute z-[100]  inset-0 h-screen w-screen  bg-slate-50'`}
        >
          <Loading darkMode={darkMode} />
        </div>
      ) : (
        ""
      )}

      <div className={`flex h-screen items-center justify-center bg-slate-50`}>
        <main className="mx-auto min-h-[590px] w-full max-w-[450px]">
          <Image
            width={50}
            alt="NextUI hero Image"
            src="Assets/haya-logo.png"
          />
          <h1 className="mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold ">
            Create an new Haya Account
          </h1>
          <p className="pb-8 sm:text-left text-base text-slate-11 font-normal"></p>
          <form data-gtm-form-interact-id="0" onKeyDown={handleKeyPress}>
            <div className="mb-4 space-y-2">
              <Input
                radius="sm"
                className="border-slate-6"
                isInvalid={LoginError.emailError !== ""}
                errorMessage={LoginError.emailError}
                key={"outside"}
                type="email"
                label="Email"
                variant={"faded"}
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, email: e.target.value })
                }
                labelPlacement={"outside"}
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4 space-y-2">
              <Input
                radius="sm"
                className="py-4"
                variant={"faded"}
                onChange={(e) =>
                  setLoginDetails({ ...LoginDetails, password: e.target.value })
                }
                isInvalid={
                  ValidationConditions && LoginError?.passwordError !== ""
                }
                errorMessage={LoginError?.passwordError}
                label={
                  <div
                    className="flex justify-between "
                    style={{ width: "442px" }}
                  >
                    <span>Password</span>
                    <span className="cursor-pointer" onClick={navigate_forgot}>
                      Forgot your password?
                    </span>
                  </div>
                }
                labelPlacement={"outside"}
                placeholder="Enter your password"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
              />
            </div>
            <Button
              id="CreateAccount"
              radius="sm"
              onClick={SubmitHandler}
              className={`w-full gap-1 lt-500 text-white bg-black hover:bg-gray-700 hover:text-white`}
            >
              Sign Up
              <lord-icon
                src="https://cdn.lordicon.com/vduvxizq.json"
                trigger="hover"
                target="#CreateAccount"
                colors="primary:#ffffff"
                style={{
                  width: "18px",
                  height: "20px",
                }}
              />
            </Button>
            <div className="text-sm flex items-center justify-end mt-4">
              <Link
                onClick={() => navigate("/sign-in")}
                className="cursor-pointer"
                size="sm"
              >
                Already have an account? Login here
              </Link>
            </div>
            <div className="mb-6 mt-6 flex items-center justify-center">
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
              <span className="text-small text-default-400 px-4">OR</span>
              <Divider className="my-2" style={{ maxWidth: "44%" }} />
            </div>
          </form>

          <div className="flex w-full flex-row flex-wrap items-end md:flex-nowrap mb-6 md:mb-0 gap-4">
            <Button
              radius="sm"
              variant={"faded"}
              className="flex gap-1"
              style={{ width: "48%" }}
              onClick={handlePasswordlessAuth}
            >
              <div className="flex items-center gap-2">
                <i class="fas fa-key"></i>
                Sign in Passwordless
              </div>
            </Button>
            <Button
              radius="sm"
              variant={"faded"}
              className="flex w-1/2 gap-1 bg"
              style={{ width: "48%" }}
              onClick={handleGoogleAuth}
            >
              <Google />
              Sign in with Google
            </Button>
          </div>
          <p className="text-small text-default-400 mt-8">
            By signing in, you agree to our{" "}
            <Link href="#" size="sm">
              Terms of Service{" "}
            </Link>{" "}
            and{" "}
            <Link href="#" size="sm">
              Privacy Policy{" "}
            </Link>
            .
          </p>
        </main>
      </div>
    </>
  );
};

export default SignUp;
