import React from 'react';
import { Pagination } from "@nextui-org/react";

const Paginate = ({ totalPage, initialPage, id, currentRecords, currentPage, setCurrentPage, recordsPerPage }) => {

    return (
        <>
            {totalPage && totalPage.length > 10 &&
                <Pagination
                    loop showControls
                    color={"secondary"}
                    initialPage="1"
                    page={currentPage}
                    onChange={setCurrentPage}
                    total={totalPage && Math.ceil(totalPage.length / recordsPerPage)} />
            }
        </>
    )
}

export default Paginate;